import React, { Component } from "react";
import lineOfColours from "./images/line_of_colours.png";
import {
  Media,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import logoImage from "./images/logo-img.png";
import loaderImg from "./images/loader.gif";
import "./styles/verify2fa.css";
import OTPAuthService from "./../services/otpauth.service";
import { Helmet } from "react-helmet";
import { encrypt_url } from "../helpers/encrypt-url";
import MainServices from "../services/main.service";
import Header from "../components/Header";

class VerifyTwoFactorAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticationcode: "",
      validauthcode: "",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      statusCode: "",
      errorStatus: "",
      userType: localStorage.getItem("user_type"),
      encryptedString: encrypt_url(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    localStorage.removeItem('twofa')
    // localStorage.setItem("twofa", true);
    // localStorage.removeItem("select_package");
    // this.props.history.push(
    //   `/dashboard/${this.state.encryptedString}`
    // );
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    const { authenticationcode } = this.state;

    if (authenticationcode) {
      this.setState({ loading: true });
      let otp_method = "sms_email";
      console.log("data: ", authenticationcode);
      OTPAuthService.verifyOTP(authenticationcode, otp_method, this).then(
        (response) => {
 
          const accountWizard = response.data.account_wizard;
          let responseData = response.data;
          if (responseData.status === "success") {

            localStorage.setItem("twofa", true);
            localStorage.removeItem("select_package");
       
            if (this.state.userType === "bn" || responseData.user.user_types.user_type ==="beneficiary" || localStorage.getItem('user_type')==="bn") {
              this.props.history.push(
                `/beneficiaryentry/${this.state.encryptedString}`
              );
             
              return 1;
            }
            // write for contributor and primary
            if (this.state.userType === "contributor") {
              this.props.history.push(
                `/dashboard-contributors/${this.state.encryptedString}`
              );
              return 1;
            }
            if (this.state.userType === "primary") {
              this.props.history.push(
                `/dashboard/${this.state.encryptedString}`
              );
              return 1;
            }

            const step02 = this.checkWizard(accountWizard, "step-02");
            if (!step02.length) {
              MainServices.setBStore('onboarding', "step-02")
              this.props.history.push("/onboardsocial");
              return 1;
            }

            const step03 = this.checkWizard(accountWizard, "step-03");
            if (!step03.length) {
              MainServices.setBStore('onboarding', "step-03")
              this.props.history.push("/onboardpass_manage");
              return 1;
            }
  
            const step04 = this.checkWizard(accountWizard, "step-04");
            if (!step04.length) {
              MainServices.setBStore('onboarding', "step-04")
              this.props.history.push("/onboardlife_status");
              return 1;
            }
  
            const step05 = this.checkWizard(accountWizard, "step-05");
            if (!step05.length) {
              MainServices.setBStore('onboarding', "step-05")
              this.props.history.push("/onboardbenefi_select");
              return 1;
            }
  
            const step06 = this.checkWizard(accountWizard, "step-06");
            if (!step06.length) {
              MainServices.setBStore('onboarding', "step-06")
              this.props.history.push("/onboardhealth");
              return 1;
            }
           
            if (this.state.userType === "bn") {
              this.props.history.push(
                `/beneficiarylanding/${this.state.encryptedString}`
              );
              return 1;
            } else {
              // this.props.history.push("/accounts");
              this.props.history.push(
                `/dashboard/${this.state.encryptedString}`
              );
              return 1;
            }
          } else {
            this.setState({
              errorStatus: responseData.status,
              errorMessage: responseData.message,
              loading: false,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  checkWizard = (wizs, step, self) => {
    let wizard = wizs.filter(w => {
      if (w.steps === step && w.status === 1) {
        return true;
      }
    });
    return wizard;
  }


  authenticateValidation() {
    if (this.state.submitted && !this.state.authenticationcode) {
      return <div className="help-block">Authentication code is required </div>;
    } else if (
      this.state.submitted &&
      this.state.authenticationcode &&
      this.state.errorStatus === "error"
    ) {
      return <div className="help-block">{this.state.errorMessage}</div>;
    }

    return <div className="help-block" />;
  }

  render() {
    return (
      <div className="container-fluid verifytwofa-page p-4">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart -Verify Authentication</title>
        </Helmet>
        <Header {...this.props} />
        <Row className="mt-5 ml-1">
          <Col sm={{ offset: 2, size: 6 }} className="">
            <div className="enable-2fa-header">
              <h3 className="large-header">Two Factor Authentication Verify</h3>
              <h4 className="small-header">Two Factor Authentication Verify</h4>
              {/* <img src={lineOfColours} alt="line of colours" /> */}
            </div>
            <p>Two-factor authentication (2FA): Secure Your ThisHeart Account Because
              stronger passwords alone aren't enough to protect your account from password
              breaches. Two-factor authentication protects against phishing, social engineering,
              and password brute force. attacks and secures your account from attackers exploiting
              weak or stolen credentials.</p>
            <h5 style={{color:"#bda61b"}}>
              Secret Key is Generated, Please Verify the Secret Code to Enable
              Two Factor
            </h5>
            <p>
                Verify the OTP you already received
                <span style={{fontWeight:"600", color:"#bda61b"}}> SMS/EMAIL</span> Authenticator For
                the Application
            </p>
            <Form onSubmit={this.handleSubmit} className="mt-2">
              <FormGroup>
                <Label>Authenticate Code</Label>
                <Input
                  type="text"
                  className="input-register w-50 ml-0"
                  name="authenticationcode"
                  id="authenticationcode"
                  onChange={this.handleChange}
                />
              </FormGroup>
              {this.authenticateValidation()}

              <div>
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}
                <Button className="btn btn-success mt-2">Submit 2FA</Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default VerifyTwoFactorAuth;
