import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
import apiService from './api.service';
import * as Codebird from "../services/twitter.service";

const socialService = {
    statusChangeCallback,
    checkFacebookStatesTmp,
    getPhotoContent,
    viewPhotos,
    fetchInstagram,
    fetchFacebook,
    firstInitFB,
    getFbPhoto,
    fetchTwitter

};

const apiUrl = apiService.apiUrl;

function firstInitFB(self) {
    self.setState({ loadingSocialData: true });
    (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) { return; }
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));


    window.fbAsyncInit = function () {
        this.FB.init({
            appId: '349211774629753',//Shourov
            // appId            : '411427716243950',//daniel
            //appId            : '979913875684349',//shahin
            autoLogAppEvents: true,
            xfbml: true,
            version: 'v4.0',
            cookie: true
        });
        localStorage.setItem("fb_access_token", '');
        localStorage.setItem("fb_user_id", '');
        self.setState({ loadingSocialData: false });
        this.FB.getLoginStatus(response => {
        });

    };

}


function fetchFacebook(self) {
    localStorage.setItem("fb_access_token", '');
    localStorage.setItem("fb_user_id", '');
    this.FB.getLoginStatus(response => {

        if (response.status === 'connected') {
            let accessToken = response.authResponse.accessToken;
            let user_id = response.authResponse.userID;
            localStorage.setItem("fb_access_token", accessToken);
            localStorage.setItem("fb_user_id", user_id);
            getPhotoContent(self);
        } else {

            this.FB.login(response => {
                self.setState({ loadingSocialData: false });
                if (response.status === 'connected') {
                    let accessToken = response.authResponse.accessToken;
                    let user_id = response.authResponse.userID;
                    localStorage.setItem("fb_access_token", accessToken);
                    localStorage.setItem("fb_user_id", user_id);
                    getPhotoContent(self);
                }
            });
        }
    });
}

function statusChangeCallback(response) {
}

function checkFacebookStatesTmp() {
    this.FB.getLoginStatus(response => {
        if (response.status === 'connected') {
        } else {

        }
    });
}

function getPhotoContent(selfs, access_token) {
    let fb_access_token = access_token;
    if (fb_access_token) {
        axios.get("https://graph.facebook.com/me/albums")
            .then(response => {
                let photoList = [];
                if (response.data.albums) {
                    let albumList = response.data.albums ? response.data.albums.data : '';
                    console.log('albums data: ', albumList);
                    albumList.map((album) => {
                        if (album.photos) {
                            let imgList = album.photos.data;
                            imgList.map(oneImg => {
                                photoList.push(oneImg.images[4].source)
                                return oneImg.images[4].source;
                            });
                        }
                    });

                    let header = {
                        headers: {
                            'Content-Type': 'application/json',
                            Authorization: authHeader()
                        }
                    };
                    let data = JSON.stringify({
                        user_id: '',
                        title: '',
                        social_type: 'facebook',
                        file_type: 'image',
                        imageList: photoList
                    });

                    if (photoList.length > 0) {
                        axios
                            .post(`${apiUrl}/api/socialStorePhotos`, data, header)
                            .then(response => {
                                selfs.setState({ socialImageLists: photoList });
                                viewPhotos(selfs);
                            }, error => {
                                selfs.setState({ loadingSocialData: false });
                            })

                    } else {
                        viewPhotos(selfs);
                    }

                }//response.albums
            }, error => {
                console.log('facebook api error: ', error);
            });
    }

}


function getFbPhoto(selfs, response) {

    if (response) {
        let photoList = [];
        let photoTmpList = [];
        if (response.albums && response.albums.data) {
            let albumList = response.albums.data ? response.albums.data : '';

            //start hastag  ************************
            let hashtag = "thisheart";
            let albumTmp = albumList.filter(alb => {
                if (alb.count > 0) {
                    alb.photos.data[0].images.map(imgLine => {
                        let str = imgLine.source ? imgLine.source : '';
                        if (str.length > 0) {
                            photoTmpList.push(imgLine);
                        }
                    });
                    return alb.photos.data;
                }
            });

            console.log(albumTmp);
            // photoTmpList.forEach(oneImg => {
            //     if (oneImg && oneImg.source) {
            //         photoList.push(oneImg.source[1]);
            //     }
            // });

            photoList.push(photoTmpList[1].source);

            let header = {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: authHeader()
                }
            };
            let data = JSON.stringify({
                user_id: '',
                title: '',
                social_type: 'facebook',
                file_type: 'image',
                imageList: photoList
            });

            if (photoList.length > 0) {
                axios
                    .post(`${apiUrl}/api/socialStorePhotos`, data, header)
                    .then(response => {
                        console.log('socialStorePhotos: ', response);
                        viewPhotos(selfs);
                    }, error => {
                        console.log('Social photos stores err: ', error);
                        selfs.setState({ loadingSocialData: false });
                    })

            } else {
                viewPhotos(selfs);
            }

        }//response.albums

    }
}

function viewPhotos(self) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    self.setState({ loadingSocialData: true });
    let data = {};
    axios
        .post(`${apiUrl}/api/socialViewPhotos`, data, header)
        .then(response => {
            console.log("inside socialViewPhotos success", response.data.data.data);
            if (response.data.data.status == "success") {
                console.log("inside success socialViewPhotos", response.data.data.data);
                self.setState({ socialImageLists: response.data.data.data, loadingSocialData: false });
            }
            else {
                self.setState({ socialImageLists: [], loadingSocialData: false });
                console.log("inside else", response.data.data.data);
            }

        })
        .catch(function (error) {
            console.log("Inside error:", error.response);
            self.setState({ socialImageError: "Image not fetch from social!", loadingSocialData: false })

        });
}


function fetchInstagram(self) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    console.log("fetchInstagram", header);
    var left = (window.screen.width / 2) - (window.screen.width / 5);
    var width = window.screen.width / 2.5;
    var urlInsta = "https://api.instagram.com/oauth/authorize/?client_id=895231375405473&redirect_uri=https://thisheart.co/InstagramCallback&scope=user_profile,user_media&response_type=code";
    console.log("urlInsta", urlInsta);
    var windowInstagram = window.open(urlInsta, "_instagram", "height=500,centerscreen=yes,top=50,width=" + width + ",left=" + left);
    var responseUriCode = "";
    var instagramAuthCode = "";

    var timer = setInterval(function () {
        if (windowInstagram.closed) {
            try {
                console.log("windowInstagram", windowInstagram);
                let urlString = windowInstagram.document.URL
                responseUriCode = urlString.length > 0 ? urlString.split("?code=")[1] : '';
                var stringLen = responseUriCode ? responseUriCode.length - 2 : '';
                instagramAuthCode = stringLen > 0 ? responseUriCode.substr(0, stringLen) : '';
                console.log("instagramAuthCode", instagramAuthCode);
                    if (instagramAuthCode) {
                        windowInstagram.close();
                        var body = {
                            client_id: '895231375405473',
                            app_id: '895231375405473',
                            app_secret: '2553bbf0702fc511e29bba85622bd28b',
                            grant_type: 'authorization_code',
                            redirect_uri: 'https://thisheart.co/InstagramCallback',
                            code: instagramAuthCode
                        }
                        console.log("body", body);

                        axios
                            .post(`${apiUrl}/api/fetchInstagram`, body, header)
                            .then(response => {
                                console.log("fetchInstagram 2", response);
                                if (response.data.status === "success") {
                                    console.log("fetchInstagram 3", response.data);
                                    var instagram_access_token = response.data.result.access_token;
                                    var media_url = "https://graph.instagram.com/me/media?fields=id,caption,media_url&access_token=" + instagram_access_token;
                                    axios.get(media_url).then(
                                        response => {
                                            let imgInstagram = response.data.data ? response.data.data : '';
                                            let photoListInsta = []
                                            imgInstagram.map((img, i) => {
                                                photoListInsta.push(img.media_url)
                                            })

                                            let data = JSON.stringify({
                                                user_id: '',
                                                title: '',
                                                social_type: 'instagram',
                                                file_type: 'image',
                                                imageList: photoListInsta
                                            });
                                            console.log("photoListInsta", photoListInsta);
                                            if (photoListInsta.length > 0) {
                                                axios
                                                    .post(`${apiUrl}/api/socialStorePhotos`, data, header)
                                                    .then(response => {
                                                        console.log('Social photos stores: ', response);
                                                        viewPhotos(self);
                                                    }, error => {
                                                        console.log('Social photos stores: ', error);
                                                        self.setState({ loadingSocialData: false });
                                                    })
                                            } else {
                                                viewPhotos(self);
                                            }
                                            self.setState({
                                                loadingSocialData: false
                                            });
                                        }
                                    )
                                }
                                else {
                                    console.log("fetchInstagram 4", response.data);
                                    self.setState({
                                        loadingSocialData: false
                                    });
                                }
                                // self.setState({
                                //     loadingSocialData: false
                                // });
                            }, error => {
                                console.log(error)
                                self.setState({
                                    loadingSocialData: false
                                });
                            });
                    }
            } catch (error) {
                console.log("error", error);
                self.setState({
                    loadingSocialData: false
                });
            }
        } else {
            clearInterval(timer);
            // Handle the popup being blocked or closed early
            self.setState({
                loadingSocialData: false,
                socialImageError: "Failed to authenticate with Instagram."
            });
        }
    }, 1000);

}

function fetchTwitter(self) {
    let header = {
        headers: {
            'Content-Type': 'application/json',
            //Authorization: authHeader()
        }
    };
 
    let codebird = new Codebird();
    //codebird.setProxy("https://admin.thisheart.co/codebird-cors-proxy/");
    codebird.setConsumerKey('qIH4ZysqwBxEz1UOW7iKmN8TA', '1KiTbA9XwbIikFmGz9u0xIPbN9jZabTNNcUUqkFRJfmVnHwkI9');
    codebird.setToken('1752293980354887680-5BJMYNIQ9IvR35K9IyreurkLTarlB7', 'hlIaiGAcM6CqtMV7bh4UpVqkvu8vyakkrg4zhKfjzn2RY');
    
    //Bearer token = AAAAAAAAAAAAAAAAAAAAAFCesAEAAAAAJ3YFvn8TtWq3R89bHKd%2F%2Bz2WwAA%3D6JL6sE8vTw4WB1b51pWrG2eZjLn0Pd1V21vvQRBLrQ4XHSGK3V
    //client-id = aW1EeXdiaFM3dkEzY3VmTDBxa1k6MTpjaQ   
    //client secret = cVugwPDfT1xWydQOASYrZKe7UJT7d7tm6ESn2ep7Cp3Mi9QTHE
    //access token = 1752293980354887680-5BJMYNIQ9IvR35K9IyreurkLTarlB7
    //access token secret = hlIaiGAcM6CqtMV7bh4UpVqkvu8vyakkrg4zhKfjzn2RY
 
    // var params = {
    //   q : "#shahin",
    //   result_type : "recent",
    //   include_entities : "true"
    // }

 

    // gets a request token
    codebird.__call(
        "oauth_requestToken",
        {oauth_callback: "oob"},
        function (reply) {
            console.log('reply :::', reply);
            // stores it
            codebird.setToken(reply.oauth_token, reply.oauth_token_secret);
     
            // gets the authorize screen URL
            codebird.__call(
                "oauth_authorize",
                {},
                function (auth_url) {
                    window.codebird_auth = window.open(auth_url);
                }
            );
        }
    );

    

    
    
    //gets a request token
    codebird.__call("oauth/request_token", {}, function (
        reply,
        rate,
        err
    ) {
        if (err) {
            console.log("error response or timeout exceeded 394::" + err.error);
        }
        if (reply) {
            if (reply.errors && reply.errors["415"]) {
                console.log(reply.errors["415"]);
                return;
            }
            console.log("Reply-406::", reply);
            
            // stores the token
            codebird.setToken(reply.oauth_token, reply.oauth_token_secret);
            var left = (window.screen.width / 2) - (window.screen.width / 5);
            var width = window.screen.width / 2.5;
            
            var urlTwitter = "https://api.twitter.com/oauth/authorize?oauth_token=" + reply.oauth_token;
            console.log("url-twitter::-", urlTwitter);
            var windowTwitter = window.open(urlTwitter, "_twitter", "height=500,centerscreen=yes,top=50,width=" + width + ",left=" + left);
            var responseUriCode = "";
            var twitterVerifierCode = "";
 
            var timer = setInterval(function () {
                if (windowTwitter && windowTwitter.closed) {
                    clearInterval(timer);
                    console.log("windowTwitter", windowTwitter);
                    try {
                        let urlString = windowTwitter.document.URL
                        responseUriCode = urlString.length > 0 ? urlString.split("&oauth_verifier=")[1] : '';
                        var stringLen = responseUriCode ? responseUriCode.length : '';
                        twitterVerifierCode = stringLen > 0 ? responseUriCode.substr(0, stringLen) : '';
                        console.log("twitterVerifierCode", twitterVerifierCode);
                        if (twitterVerifierCode) {
                            windowTwitter.close();
                            codebird.__call(
                                "oauth/accessToken",
                                {
                                    oauth_verifier: twitterVerifierCode
                                },
                                console.log("twitterVerifierCode", twitterVerifierCode),
                                function (reply, rate, err) {
                                    if (err) {
                                        console.log("error response or timeout exceeded" + err.error);
                                    }
                                    if (reply) {
                                        codebird.setToken(reply.oauth_token, reply.oauth_token_secret);
                                        codebird.__call("statuses/user_timeline", {}, function (
                                            tweets,
                                            rate,
                                            err
                                        ) {
                                            if (err) {
                                                console.log("error response or timeout exceeded" + err.error);
                                            }
                                            if (tweets) {
                                                if (tweets.length > 0) {
                                                    let photoListTwitter = []
                                                    console.log("tweets", tweets);
                                                    tweets.map((twt) => {
                                                        let twtMedia = twt.entities.media ? twt.entities.media : ''
                                                        if (twtMedia.length > 0) {
                                                            photoListTwitter.push(twtMedia[0].media_url)
                                                        }
                                                    });//end map

                                                    let data = JSON.stringify({
                                                        user_id: '',
                                                        title: '',
                                                        social_type: 'twitter',
                                                        file_type: 'image',
                                                        imageList: photoListTwitter
                                                    });

                                                    if (photoListTwitter.length > 0) {
                                                        axios
                                                            .post(`${apiUrl}/api/socialStorePhotos`, data, header)
                                                            .then(response => {
                                                                viewPhotos(self);
                                                            }, error => {
                                                                self.setState({ loadingSocialData: false });
                                                            })
                                                    } else {
                                                        viewPhotos(self);
                                                    }
                                                    self.setState({
                                                        loadingSocialData: false
                                                    });
                                                }//end if(tweets.length>0)
                                            }
                                        });
                                    }
                                }
                            );

                        }
                    } catch (error) {
                        console.log(error)
                        self.setState({
                            loadingSocialData: false
                        });
                    }
                } else if (!windowTwitter) {
                    clearInterval(timer);
                    console.log("Twitter window was not opened or was blocked by a popup blocker.");
                    self.setState({
                        loadingSocialData: false,
                        socialImageError: "Failed to authenticate with Twitter."
                    });
                }
            }, 1000);

        }
        else {
            console.log("error connecting to twitter" + err);
        }
    });



}


export default socialService;
