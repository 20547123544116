import React, { Component } from "react";
import lineOfColours from "./images/line_of_colours.png";
import {
  Media,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import loaderImg from "./images/loader.gif";
import "./styles/verify2fa.css";
import OTPAuthService from "./../services/otpauth.service";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import { encrypt_url } from "../helpers/encrypt-url";
import LeftSidebar from "../components/LeftSidebar";

class VerifySettingsTwoFactorAuth extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");

    this.state = {
      authenticationcode: "",
      validauthcode: "",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      statusCode: "",
      errorStatus: "",
      encryptedString: encrypt_url("otp_verify"),
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    localStorage.removeItem('twofa')
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    const { authenticationcode } = this.state;

    if (authenticationcode) {
      this.setState({ loading: true });
      let otp_method = "sms_email";
      OTPAuthService.verifyOTP(authenticationcode, otp_method, this).then(
        (response) => {
          console.log(response.data);
          let responseData = response.data;
          // if (responseData.status === "success") {
          //   this.props.history.push(
          //     `/accountprogress/${this.state.encryptedString}`
          //   );
          // }
          if (responseData.status === "success") {
            localStorage.setItem("twofa", true);
            if (this.state.userType === "bn") {
              this.props.history.push(
                `/beneficiarylanding/${this.state.encryptedString}`
              );
            } else {
              // this.props.history.push("/accounts");
              this.props.history.push(
                `/dashboard/${this.state.encryptedString}`
              );
            }
          } else {
            this.setState({
              errorStatus: responseData.status,
              errorMessage: responseData.message,
              loading: false,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  authenticateValidation() {
    if (this.state.submitted && !this.state.authenticationcode) {
      return <div className="help-block">Authentication code is required </div>;
    } else if (
      this.state.submitted &&
      this.state.authenticationcode &&
      this.state.errorStatus === "error"
    ) {
      return <div className="help-block">{this.state.errorMessage}</div>;
    }
    return <div className="help-block" />;
  }

  render() {
    return (
      <div id="main-wrapper" className="" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-header-position="fixed" data-boxed-layout="full" >
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Verify Settings</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container mt-3">
            <div className="enable-2fa-header">
              <h3>Enable Two Factor Authentication</h3>
              {/* <img style={{height:"5px", margin:"auto 0", paddingLeft:"10px"}} src={lineOfColours} alt="line of colours" /> */}
              {/* <img src={lineOfColours} alt="line of colours" /> */}
            </div>
            <p>Two-factor authentication (2FA): Secure Your ThisHeart Account Because
              stronger passwords alone aren't enough to protect your account from password
              breaches. Two-factor authentication protects against phishing, social engineering,
              and password brute force. attacks and secures your account from attackers exploiting
              weak or stolen credentials.</p>
            <h5 style={{ color: "rgb(184 27 189)" }}>
              Secret Key is Generated, Please Verify the Secret Code to Enable
              Two Factor
            </h5>
            <p>
              Verify the OTP you already received
              <span style={{ fontWeight: "500", color: "rgb(184 27 189)" }}> SMS/EMAIL</span> Authenticator For
              the Application
            </p>
            <Form onSubmit={this.handleSubmit} className="mt-5">
              <FormGroup>
                <Label>Authenticate Code</Label>
                <Input
                style={{borderRadius: "7px", border: "1px solid #ffd139"}}
                  type="text"
                  className="input-register w-50 ml-0"
                  name="authenticationcode"
                  id="authenticationcode"
                  onChange={this.handleChange}
                />
              </FormGroup>
              {this.authenticateValidation()}

              <div>
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}
                <Button className="btn mt-2" style={{ background: "#ffd139", border: "0px", borderRadius: "5px" }}>Enable 2FA</Button>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default VerifySettingsTwoFactorAuth;
