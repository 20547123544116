import React, { Component } from "react";

import "./styles/homeIndex.css";
import "./styles/bnfront.css";
import loveIcon from "../pages/images/front-logo.png";

import "bootstrap/dist/css/bootstrap.min.css";
import { Button } from "reactstrap";

class BnFrontDoor extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="main-container">
        <div className="container bg-mix">
          <div className="row">
            <div className="col-md-12">
              <div className="txt-me">
                <h2 className="head-me">
                  re<span className="half">m</span>
                  <span className="half-e">e</span>mber <br />
                  me
                  <img src={loveIcon} className="img-bottom " alt="icon-love" />
                </h2>

                <Button className="start-btn">Start</Button>
              </div>
            </div>
          </div>
          <div className="row">
            <p className="what-txt">What is this?</p>
          </div>
        </div>
      </div>
    );
  }
}
export default BnFrontDoor;
