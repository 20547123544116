import React, { Component } from "react";

import "./styles/homeIndex.css";
import "./styles/security.css";
import "animate.css/animate.min.css";

import "bootstrap/dist/css/bootstrap.min.css";

import logoLight from "../pages/images/thisheartweb/logolight.png";

import IndexFooter from "./IndexFooter";

class Security extends Component {
  render() {
    return (
      <div id="landing-home">
        <div id="page-top" data-spy="scroll" data-target=".navbar">
          <div id="header" className="fixed-top ">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light main-nav ">
                <a className="navbar-brand" href="/">
                  <img src={logoLight} alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  id="navbarNavDropdown"
                  className="navbar-collapse collapse"
                >
                  <ul className="navbar-nav mr-auto" />
                  <ul className="navbar-nav list-collapse">
                    <li>
                      <a href="/login">
                        Log in
                        <span className="sr-only" />
                      </a>
                    </li>
                    <li>
                      <a href="/register">
                        Start for $0
                        <span className="sr-only" />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="gray-bg section">
          <div className="container ft-cards">
            <div className="row ">
              <div className="col-lg-4 col-md-6 mb-sm-50">
                <div className="ft-item">
                  <i className="fa fa-pencil" aria-hidden="true"></i>

                  <h4>SOC 2 certified</h4>
                  <p>
                    We are SOC2 certified. This SOC 2 compliance offers clients the assurance that their data is being securely and confidentially managed. This can result in enhanced customer relationships and higher levels of customer satisfaction.
                  </p>
                  {/* <a href="#">
                    Learn more{" "}
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </a> */}
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mb-sm-50">
                <div className="ft-item">
                  <i className="fa fa-laptop" aria-hidden="true"></i>
                  <h4>HIPAA  </h4>
                  <p>
                    Securing HIPAA (Health Insurance Portability and
                    Accountability Act) certification guarantees
                    adherence to the regulations set forth in the HIPAA
                    Privacy, Security, and Breach Notification Rules.
                    HIPAA is obligatory for covered entities
                    clearinghouses.
                  </p>
                  {/* <a href="#">
                    Learn more{" "}
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </a> */}
                </div>
              </div>

              <div className="col-lg-4 col-md-6 mb-sm-50">
                <div className="ft-item">
                  <i className="fa fa-shield" aria-hidden="true"></i>
                  <h4>McAfee Security</h4>
                  <p>
                    In addition to Service 1 and Service 2,
                    we've attained PCI DSS Level 4, McAfee TrustedSite,
                    and are obtaining Norton Secured by Verisign certification. These certifications are 
                    pivotal in our journey with valued customers and 
                    Ensuring more security. 
                  </p>
                  {/* <a href="#">
                    Learn more{" "}
                    <i
                      className="fa fa-long-arrow-right"
                      aria-hidden="true"
                    ></i>
                  </a> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="cta-link">
          <div className="bg-overlay">
            <div className="cta-wrapper">
              <h3
                className="h-alt wow fadeIn"
                data-wow-delay=".1s"
                data-wow-duration="1s"
              >
                Love to hear from you
              </h3>
              <a
                href="/contact"
                className="btn btn-light btn-large wow fadeIn"
                data-wow-delay=".3s"
                data-wow-duration="1s"
              >
                Get in touch
              </a>
            </div>
          </div>
        </div>

        <div className="container-fluid ft-layout-50">
          <div className="row ft-item">
            <div className="col-lg-6 ft-img-wrapper">
              <img src="/images/web-security-1.jpg" alt="Features" />
            </div>
            <div className="col-lg-6 ft-content-wrapper">
              <h4>SOC 2 certified</h4>
              <h6 className="h-alt">Proud to be SOC 2 certified</h6>
              <p>
              We are proud to be SOC 2 certified, ensuring that we securely and confidentially manage client data. This certification provides clients with confidence in our data handling practices, fostering stronger customer relationships and increased satisfaction.
              </p>
              {/* <button className="btn-ghost">Call to Action</button> */}
            </div>
          </div>

          <div className="row ft-item">
            <div className="col-lg-6 col-md-6 ft-content-wrapper">
              <h4>HIPAA (Health Insurance Portability and Accountability Act)</h4>
              <h6 className="h-alt">Achieving HIPAA Certification</h6>
              <p>
                Achieving HIPAA (Health Insurance Portability and Accountability Act) certification ensures compliance with the HIPAA Privacy, Security, and Breach Notification Rules. This certification is mandatory for covered entities and clearinghouses to safeguard sensitive health information.
              </p>
              {/* <button className="btn-ghost">Call to Action</button> */}
            </div>
            <div className="col-lg-6 col-md-6 ft-img-wrapper">
              <img src="/images/web-security-2.jpg" alt="Features " />
            </div>
          </div>
        </div>
        <IndexFooter />
      </div>
    );
  }
}
export default Security;
