import React, { Component } from "react";
import "./styles/homeIndex.css";
import logoLight from "../pages/images/thisheartweb/logolight.png";
import "./styles/homepage.css";
import IndexFooter from "./IndexFooter";

class DetailsHealth extends Component {
  // constructor(props) {
  //   super(props);
  // }

  render() {
    return (
      <div id="sub-memories">
        <div id="page-top" data-spy="scroll" data-target=".navbar">
          <div id="header" className="fixed-top ">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light main-nav mega">
                <a className="navbar-brand" href="/">
                  <img src={logoLight} alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  id="navbarNavDropdown"
                  className="navbar-collapse collapse"
                >
                  <ul className="navbar-nav mr-auto"></ul>
                  <ul className="nav navbar-nav list-collapse">
                    <li className="dropdown mega-fw">
                      <a
                        href="#health"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        data-hover="dropdown"
                        data-delay="350"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                        alt="Features"
                      >
                        Features <span className="caret"></span>
                      </a>
                      <ul className="dropdown-menu">
                        <div className="row">
                          <div className="col-lg-4 mb-sm-30">
                            <li className="dropdown-header">Memories</li>
                            <hr className="hr-text" />
                            <li>
                              <a href="/memimageinfo" target="_blank">
                                Images
                              </a>
                            </li>
                            <li>
                              <a href="/memvideoinfo" target="_blank">
                                Video
                              </a>
                            </li>
                            <li>
                              <a href="/memletterinfo" target="_blank">
                                Letters
                              </a>
                            </li>
                            <li>
                              <a href="/memaudioinfo" target="_blank">
                                Audio Recordings
                              </a>
                            </li>
                            <li>
                              <a href="/memsocinfo" target="_blank">
                                Social Media Posts
                              </a>
                            </li>
                          </div>

                          <div className="col-lg-4 mb-sm-30">
                            <li className="dropdown-header">Accounts</li>
                            <hr className="hr-text" />
                            <li>
                              <a href="/infofinance" target="_blank">
                                Financial
                              </a>
                            </li>
                            <li>
                              <a href="/infolegal" target="_blank">
                                Legal
                              </a>
                            </li>
                            <li>
                              <a href="/infosocacc" target="_blank">
                                Social Media
                              </a>
                            </li>
                            <li>
                              <a href="/infoinsurance" target="_blank">
                                Insurance
                              </a>
                            </li>
                          </div>

                          <div className="col-lg-4 mb-sm-30">
                            <li className="dropdown-header">Health</li>
                            <hr className="hr-text" />
                            <li>
                              <a href="/medicinfo" target="_blank">
                                Medical History
                              </a>
                            </li>
                          </div>
                        </div>
                      </ul>
                    </li>
                    <li>
                      <a href="/login">
                        Log in<span className="sr-only"></span>
                      </a>
                    </li>
                    <li>
                      <a href="/register">
                        Start for $0<span className="sr-only"></span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="container section-sub">
          <div className="row">
            <div className="col-md-12  sec-heading">
              <h4 className="head-mem"> Health</h4>
              <span className="subheading">
                YOUR MEDICAL HISTORY IS IMPORTANT TO THOSE YOU LEAVE BEHIND
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4">
              <p className="p-item">
                <b>
                  <i className="fa fa-plus-square"></i>&nbsp; Medical History
                </b>
              </p>
              <p className="p-item-para">
                Lorem ipsum dolor sit amet, consectetur adipisicing elit. Nam
                repudiandae, ex fugit vero, earum quibusdam exercitationem
                impedit voluptas officiis placeat, reiciendis totam dolore atque
                ipsam incidunt quasi. Fugiat, doloribus, quia!
              </p>
            </div>
            <div className="col-md-4"></div>
            <div className="col-md-4"></div>

            <div className="col-md-4"></div>
            <div className="col-md-4"></div>
          </div>
        </div>

        <IndexFooter />
      </div>
    );
  }
}

export default DetailsHealth;
