import React, { Component } from "react";
import "./styles/homeIndex.css";
import "./styles/contactus.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logoLight from "../pages/images/thisheartweb/logolight.png";
import { Form, FormGroup, Input, Label, Row, Col, Button } from "reactstrap";
import IndexFooter from "./IndexFooter";
import apiService from "../services/api.service";
import { authHeader } from "../helpers/auth-header";
import axios from "axios";

// const MarkAddressPoint = ({ text }) => (
//   <div
//     style={{
//       color: "#ef434e",
//       display: "inline",
//       textAlign: "center",
//       alignItems: "center",
//       justifyContent: "center",
//       fontSize: "18px",
//       fontWeight: "600",
//     }}
//   >
//     {text}
//   </div>
// );

class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      userName: "",
      message: "",
      sendErrors: "",
      submitted: false,
      btnActive: false,
      msgFailed: false,
      msgSpin: false,
      msgStatus: "",
    };
  }

  static defaultProps = {
    center: {
      lat: 33.769741,
      lng: 84.383401,
    },
    zoom: 11,
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });

    const { userEmail, userName, userMessage } = this.state;

    if (userEmail && userName && userMessage) {
      this.setState({
        btnActive: true,
      });
    } else {
      this.setState({
        btnActive: false,
      });
    }
  };

  onChangeEmail = (e) => {
    const { name, value } = e.target;
    if (this.validEmailFormat(e)) {
      this.setState({
        sendErrors: "",
      });
    } else {
      this.setState({
        sendErrors: "Please input valid email",
      });
    }
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log("error length:", this.state.sendErrors.length);

    if (this.state.sendErrors.length > 0) {
      console.log("error length:", this.state.sendErrors.length);
      return false;
    }
    let header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    };
    const apiUrl = apiService.apiUrl;
    this.setState({
      submitted: true,
      msgSpin: true,
    });

    const { userEmail, userName, userMessage } = this.state;
    // console.log(userEmail, userName);
    if (userEmail && userName && userMessage) {
      const data = {
        userEmail,
        userName,
        userMessage,
      };

      axios.post(`${apiUrl}/api/contactus/send_message`, data, header).then(
        (response) => {
          if (response.data.status === "success") {
            this.setState({
              msgStatus: "Your message have sent successfully, thank you!",
            });
          } else {
            this.setState({
              msgStatus: "Sorry, Failed to message",
            });
          }
          this.setState({ msgSpin: false });
          setTimeout(() => {
            this.setState({
              msgStatus: "",
            });
          }, 2500);
        },
        (error) => {
          this.setState({
            msgSpin: false,
            msgStatus: "Sorry, Failed to message",
          });
        }
      );
    }
  };

  validEmailFormat = (e) => {
    let emailFormat = e.target.value;
    console.log("Email value received:", emailFormat);
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(emailFormat)) {
      return false;
    } else {
      return true;
    }
  };

  emailValidation = () => {
    if (this.state.submitted && !this.state.userEmail) {
      return <div className="reg-help-block">Email is required</div>;
    } else if (this.state.submitted === true && this.state.msgFailed === true) {
      return <div className="reg-help-block">{this.state.sendErrors}</div>;
    }
  };

  messageValidation = () => {
    if (this.state.submitted && !this.state.userMessage) {
      return <div className="reg-help-block">Message is required</div>;
    } else if (this.state.submitted && this.state.userMessage.length < 1) {
      return <div className="reg-help-block">Please enter your message</div>;
    }

    return <div className="reg-help-block" />;
  };

  render() {
    return (
      <div id="landing-home">
        <div id="page-top" data-spy="scroll" data-target=".navbar">
          <div id="header" className="fixed-top ">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light main-nav ">
                <a className="navbar-brand" href="/">
                  <img src={logoLight} alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  id="navbarNavDropdown"
                  className="navbar-collapse collapse"
                >
                  <ul className="navbar-nav mr-auto" />
                  <ul className="navbar-nav list-collapse">
                    <li>
                      <a href="/login">
                        Log in
                        <span className="sr-only" />
                      </a>
                    </li>
                    <li>
                      <a href="/register">
                        Start for $0
                        <span className="sr-only" />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* <!-- ========== Contact ========== --> */}
        <div id="contact" className="section contact-1">
          <header className="sec-heading-contact">
            <h2>Say hello</h2>
            <span className="subheading">We love to discuss your idea</span>
          </header>

          <div className="contact-wrapper">
            <div id="map-canvas" className="gmap map-boxed"></div>
            <div>
              {/* <GoogleMapReact
                bootstrapURLKeys={{ key:"AIzaSyCzBm-27X5EiBXBVyScmUY62dhUV-2bKBw" }}
                defaultCenter={this.props.center}
                defaultZoom={this.props.zoom}
                yesIWantToUseGoogleMapApiInternals
              >
                <MarkAddressPoint
                  lat={33.769741}
                  lng={84.383401}
                  // text={
                  //   <img
                  //     src={mapMarker}
                  //     alt="thisheart"
                  //     width="50"
                  //     height="30"
                  //     className="img-fluid"
                  //   />
                  // }
                  text="ThisHeart"
                />
              </GoogleMapReact> */}

              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3316.648622653814!2d-84.3852414574179!3d33.76974473617464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x0!2zMzPCsDQ2JzExLjEiTiA4NMKwMjMnMDAuMiJX!5e0!3m2!1sbn!2sbd!4v1587469139072!5m2!1sbn!2sbd"
                width="100%"
                height="450"
                frameBorder="0"
                style={{ border: "0" }}
                allowFullScreen=""
                aria-hidden="false"
                tabIndex="0"
                title="ThisHeart"
              ></iframe>
            </div>

            <div className="container">
              <div className="row ">
                <div className="col-xs-offset-1 col-xs-11 col-md-offset-2 col-md-6 contact-info-wrapper">
                  <address>
                    <div className="row">
                      <div className="col-sm-6 address-group">
                        <span>Phone</span>
                        <a href="#">+ 123 4567 890</a>
                        <a href="#">+ 123 7654 098</a>
                      </div>

                      <div className="col-sm-6 address-group">
                        <span>Address</span>
                        <p>1200 some street, il, US</p>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 address-group">
                        <span>Email</span>
                        <a href="mailto:info@thisheart.co">info@thiseart.co</a>
                      </div>

                      <div className="col-sm-6 address-group">
                        <span>Open Hours</span>
                        <p>Mon-Fri: 9am-5pm</p>
                        <p>Sat: 10am-1pm</p>
                      </div>
                    </div>
                  </address>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-4"></div>
                <div className="col-lg-8 col-md-8">
                  <div className="message-form">
                    <Form onSubmit={this.handleSubmit}>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label className="view-label">Name</Label>
                            <Input
                              type="text"
                              id="userName"
                              name="userName"
                              onChange={this.handleChange}
                              placeholder="Enter your name"
                              required
                              className="input-contact"
                            />
                          </FormGroup>
                          {this.state.submitted && !this.state.userName && (
                            <div className="reg-help-block">
                              User name is required
                            </div>
                          )}
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label className="view-label">Email</Label>
                            <Input
                              type="email"
                              id="userEmail"
                              name="userEmail"
                              onChange={this.onChangeEmail}
                              placeholder="Enter your email"
                              required
                              className="input-contact"
                            />
                          </FormGroup>
                          {this.emailValidation()}
                          <div className="reg-help-block">
                            {this.state.sendErrors}
                          </div>
                        </Col>
                      </Row>

                      <Row form>
                        <Col md={6}>
                          <FormGroup>
                            <Label className="view-label">Message</Label>
                            <Input
                              name="userMessage"
                              type="textarea"
                              rows="5"
                              id="userMessage"
                              onChange={this.handleChange}
                              placeholder="Your Message"
                              required
                              className="input-contact"
                            ></Input>
                          </FormGroup>
                          {this.messageValidation()}
                          {this.state.msgStatus && (
                            <div className="alert alert-success">
                              {this.state.msgStatus}
                            </div>
                          )}
                          <div
                            className="pull-right"
                            style={{ marginTop: "10px" }}
                          >
                            <Button className="btn-sendMsg  ">
                              {this.state.msgSpin && (
                                <i className="fa fa-circle-o-notch fa-spin"></i>
                              )}
                              &nbsp;Send Message
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <IndexFooter />
      </div>
    );
  }
}
export default ContactUs;
