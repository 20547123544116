import React, { Component } from "react";
import "./styles/homeIndex.css";
import "animate.css/animate.min.css";
import "bootstrap/dist/css/bootstrap.min.css";
import logoLight from "../pages/images/thisheartweb/logolight.png";
import IndexFooter from "./IndexFooter";

class PricingPlan extends Component {
  render() {
    return (
      <div id="landing-home" className="priceplan-page">
        <div id="page-top" data-spy="scroll" data-target=".navbar">
          <div id="header" className="fixed-top ">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light main-nav ">
                <a className="navbar-brand" href="/">
                  <img src={logoLight} alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  id="navbarNavDropdown"
                  className="navbar-collapse collapse"
                >
                  <ul className="navbar-nav mr-auto" />
                  <ul className="navbar-nav list-collapse">
                    <li>
                      <a href="/login">
                        Log in
                        <span className="sr-only" />
                      </a>
                    </li>
                    <li>
                      <a href="/register">
                        Start for $0
                        <span className="sr-only" />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        {/* start pricing area  */}
        <div id="pricing" className="pricing-area area-padding mt-4">
          <div className="container">
            <div className="price-title-head">
              <h2 className="text-center">Start leaving things behind today</h2>
              <span>The first 30 days are free </span>
            </div>
            <div className="row">
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="pri_table_list">
                  <h3 className="mt-5 pt-5">Starter</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">9.99</span>
                    <span className="type">\mo</span>
                  </div>
                  <p className="title-desc">TEST THE WATERS</p>
                  <hr />

                  <ol>
                    <li>10Gb of Data Storage</li>
                    <li>Store 10 accounts</li>
                    <li>Access to 2 Beneficiaries</li>
                    <li>Email User Support </li>
                    <li>&nbsp;</li>
                  </ol>

                  <button type="submit" className="btn mb-4" style={{background:"#e1c720"}}>
                    <a href="/register">START FOR FREE</a>
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="pri_table_list ">
                  <div className="banner-pri">
                    <span className="star">
                      <i className="fa fa-heart" />
                    </span>
                  </div>
                  <h3 className="mt-5 pt-5">ACCESS</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">19.99</span>
                    <span className="type">\mo</span>
                  </div>

                  <p className="title-desc">ALL ACCESS</p>
                  <hr />
                  <ol>
                    <li>Unlimited Data Storage</li>
                    <li>Unlimited Account Storage</li>
                    <li>Unlimited Beneficiary Access</li>
                    <li>Email and Chat Support</li>
                    <li>&nbsp;</li>
                  </ol>
                  
                  <button type="submit" className="btn mb-4" style={{background:"#e1c720"}}>
                    <a href="/register">START FOR FREE</a>
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12">
                <div className="pri_table_list">
                  <h3 className="mt-5 pt-5">FAMILY</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">39.99</span>
                    <span className="type">\mo</span>
                  </div>

                  <p className="title-desc">
                    AN ACCOUNT FOR YOU AND 3 OF YOUR FAMILY MEMBERS
                  </p>
                  <hr />
                  <ol>
                    <li>All of the Access Plan Features</li>
                    <li>For up to 3 of your family members.</li>
                    <li>That's 4 total accounts including yours</li>
                    <li className="price-li">&nbsp;</li>
                  </ol>
                  
                  <button type="submit" className="btn mb-4" style={{background:"#e1c720"}}>
                    <a href="/register">START FOR FREE</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End pricing table area */}

        <IndexFooter />
      </div>
    );
  }
}
export default PricingPlan;
