
const  MainServices = {
    setStore,
    setBStore,
    getStore,
    getBStore,
    removeStore
};

 

function setStore(key,value){
   return localStorage.setItem(key,value)
}
function setBStore(key,value){
   return localStorage.setItem(key,btoa(value))
}

function getStore(key){
    return localStorage.getItem(key)
}
function getBStore(key){
    return atob(localStorage.getItem(key))
}

function removeStore(key){
    localStorage.removeItem(key)
}

 


export default MainServices;
