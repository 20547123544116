import React, { Component } from "react";
import { Link } from "react-router-dom";
import loaderImg from "./images/loader.gif";
// import $ from "jquery";

import { Form, Button, FormGroup, Input, Label, Row, Col } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/onboarding1verifysms.css";
import "react-responsive-ui/style.css";
import PhoneInput from "react-phone-number-input";
import { encrypt_url } from "../helpers/encrypt-url";

import { Helmet } from "react-helmet";
import OTPAuthService from "../services/otpauth.service";

class OnBoardingStepOneSmsEmail extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.state = {
      authenticationcode: "",
      mobile: "",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      errorMobile: "",
      statusCode: "",
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      selectedStep: "email",
      validauthcode: "",
      errorStatus: "",
      encryptedString: encrypt_url(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAuthCode = this.handleAuthCode.bind(this);
  }

  componentDidMount() {
    let user = localStorage.getItem("user");
    if (!user) {
      console.log("user didnt login yet......");
      this.props.history.push("/login");
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleAuthCode(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    const { authenticationcode } = this.state;

    console.log(this.state);
    if (authenticationcode) {
      this.setState({ loading: true });
      let otp_method = "sms_email";
      console.log("data: ", authenticationcode);
      OTPAuthService.verifyOTP(authenticationcode, otp_method, this).then(
        (response) => {
          console.log(response.data);
          let responseData = response.data;
          if (responseData.status === "success") {
            localStorage.setItem("twofa", true);
            localStorage.setItem("onboarding", true);
            if (this.state.userType === "bn") {
              this.props.history.push(`/beneficiarylanding/${this.state.encryptedString}`);
              //this.props.history.push("/onboardsocial");
            } else {
              // this.props.history.push("/accounts");
              //   this.props.history.push("/AccountProgress");
              this.props.history.push("/onboardsocial");
            }
          } else {
            this.setState({
              errorStatus: responseData.status,
              errorMessage: responseData.message,
              loading: false,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  authenticateValidation() {
    if (this.state.submitted && !this.state.authenticationcode) {
      return <div className="help-block">Authentication code is required </div>;
    } else if (
      this.state.submitted &&
      this.state.authenticationcode &&
      this.state.errorStatus === "error"
    ) {
      return <div className="help-block">{this.state.errorMessage}</div>;
    }

    return <div className="help-block" />;
  }

  twoFactorProcess = () => {
    let optionSelect = "";
    optionSelect = (
      <div>
        <div className="option-list">
          <FormGroup check>
            <Label check className="lbl-method ">
              <b>Email</b>
              <Input
                type="radio"
                name="radio"
                value="email"
                id="email"
                onChange={this.handleChange}
                defaultChecked
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check className="lbl-method ">
              <b>SMS</b>
              <Input
                type="radio"
                name="radio"
                value="sms"
                id="sms"
                onChange={this.handleChange}
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label check className="lbl-method ">
              <b>Google Authenticator</b>
              <Input
                type="radio"
                name="radio"
                value="googleauth"
                id="googleauth"
                onChange={this.handleChange}
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>
          <hr />
          {this.state.selectedStep === "sms" && (
            <FormGroup>
              <Label style={{ color: "#212529" }}>
                <b>Mobile</b>
              </Label>

              <PhoneInput
                name="mobile"
                id="mobile"
                placeholder="Enter mobile number"
                onChange={(mobile) => {
                  this.setState({ mobile });
                  this.setState({ errorMobile: "" });
                }}
              />
              <div
                className="help-block"
                style={{ width: "100%", marginTop: "15px" }}
              >
                {this.state.errorMobile}
              </div>
            </FormGroup>
          )}
        </div>
      </div>
    );
    return optionSelect;
  };
  
  pageRedirectPrevious = () => {
    this.props.history.push("/onboard2fa");
  };

  render() {
    return (
      <div className="container-fluid onboarding-one-verify-sms-email">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - OnBoarding SMS/Email Verify</title>
        </Helmet>
        <div className="row ">
          {/*<!--end row one-->*/}
          <div className="container image-step">
            <Row >
              <Col sm="6" className="mx-5">
                <h4 className="heading">Two Factor Authentication Verify</h4>
                <p className="parag responsive-text">
                  <b>
                    Two factor authentication (2FA) Secure Your ThisHeart
                    Account
                  </b >{" "}
                  Because stronger passwords alone aren't enough to protect your
                  account from password breaches. Two factor authentication
                  protects against phishing, social engineering and password
                  brute force attacks and secures your account from attackers
                  exploiting weak or stolen credentials.
                </p>

                <p className="fact-list-verify">
                  <strong>
                    {" "}
                    Please check your email and verify the OTP.{" "}
                    <span className="mark-text">SMS/EMAIL</span> Authenticator
                    For the Application
                  </strong>
                </p>
                <Form className="register-form" onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label className="" style={{ display: 'block', marginBottom: '5px', color: '#8A2BE2' }}>Authenticate Code</Label>
                    <div className="d-flex align-items-start" style={{marginBottom:'100px'}}>
                      <div >
                        <Input
                          type="text"
                          className="input-register"
                          name="authenticationcode"
                          id="authenticationcode"
                          onChange={this.handleAuthCode}
                          style={{ width: '150px', height: '40px' }}
                        />
                     </div>
                      <div>
                        <Button
                          className="btnSubmitEnable btn btn-secondary submit2FA "
                        >
                          Submit 2FA
                        </Button>
                      </div>
                    </div>
                  </FormGroup>
                  {this.authenticateValidation()}
                  <div>
                    {this.state.loading && (
                      <img
                        className="loader-img"
                        alt="loaderImg"
                        src={loaderImg}
                      />
                    )}
                  </div>
                </Form>


              </Col>
            </Row>

            <Row>
              <Col md={3}>
              <div className="prev-next ml-5">
                  <Button
                    className="mr-1"
                    onClick={this.pageRedirectPrevious}
                    style={{borderRadius:"7px"}}
                  >
                    <Link style={{color:"black"}} to="/?">&laquo; Prev</Link>
                  </Button>
                  
                </div>
              </Col>
            </Row>

            {/* <p className="skip-p">
                <Link to="/onboardsocial">Skip</Link>
              </p> */}
          </div>
          {/*** end image-step ****/}
        </div>
        {/*<!--end row one-->*/}
      </div>
    );
  }
}

export default OnBoardingStepOneSmsEmail;
