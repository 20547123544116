import React from 'react';
import { Route, Redirect } from 'react-router-dom';


export const StepRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('user') && 
			localStorage.getItem('twofa') && 
			localStorage.getItem('onboarding') &&
			(localStorage.getItem('user_type')!=="bn")
			? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
			)
		}
	/>
);
