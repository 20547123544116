import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
import apiService from './api.service';


 const StepServices = {
	getSteps,
    setSteps,
    getLifeStyle,
    filterStep
};

    const apiUrl = apiService.apiUrl;

function getSteps(self,data){
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/getSteps`, data, header);
}

function setSteps(self,data){
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/setSteps`, data, header);
}

function getLifeStyle(self){
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/getLifeStyle`, {},header);
}


function filterStep(arr,step){
    let tmp = arr.filter(stp=>{
        if(stp.steps===step && stp.status===1){
            return stp;
        }else{
            return false
        }
    })
    return tmp;
}


export default StepServices;
