import React, { Component } from "react";

import "./styles/homeIndex.css";
import "./styles/career.css";
import "animate.css/animate.min.css";

import "bootstrap/dist/css/bootstrap.min.css";

import logoLight from "../pages/images/thisheartweb/logolight.png";

import axios from "axios";
import apiService from "./../services/api.service";

import IndexFooter from "./IndexFooter";

const apiUrl = apiService.apiUrl;

class Career extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      emailSubc: "",
      successNewsLetter: "",
      errorNewsLetter: "",
    };
  }

  validEmailFormat = (e) => {
    let emailFormat = e.target.value;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(emailFormat)) {
      console.log("emailFormat error");
      return false;
    } else {
      console.log("emailFormat correct");
      return true;
    }
  };

  onchangeEmail = (e) => {
    this.setState({
      emailSubc: e.target.value,
    });
    if (this.validEmailFormat(e)) {
      this.setState({
        errorNewsLetter: "",
      });
    } else {
      this.setState({
        errorNewsLetter: "Please input valid email!",
      });
    }
  };

  submitEmail = (e) => {
    e.preventDefault();
    let emailId = this.state.emailSubc;
    if (this.state.errorNewsLetter.length > 0) {
      return false;
    }
    axios.get(`${apiUrl}/api/web/newsletter/` + emailId).then((response) => {
      if (response.data.status === "success") {
        this.setState({
          errorNewsLetter: response.data.message,
        });
      } else {
        this.setState({
          errorNewsLetter: response.data.message,
        });
      }
    });
  };
  render() {
    return (
      <div id="landing-home">
        <div id="page-top" data-spy="scroll" data-target=".navbar">
          <div id="header" className="fixed-top ">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light main-nav ">
                <a className="navbar-brand" href="/">
                  <img src={logoLight} alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  id="navbarNavDropdown"
                  className="navbar-collapse collapse"
                >
                  <ul className="navbar-nav mr-auto" />
                  <ul className="navbar-nav list-collapse">
                    <li>
                      <a href="/login">
                        Log in
                        <span className="sr-only" />
                      </a>
                    </li>
                    <li>
                      <a href="/register">
                        Start for $0
                        <span className="sr-only" />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div className="comingsoon-2">
          <div className="content-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-md-6">
                  <div>
                    <h1>Career</h1>
                    <p>
                      Our career is in the final stage of development, subscribe
                      <br /> bellow to get notified when is live
                    </p>
                  </div>
                  <form
                    className="mc-ajax mc-form subscribe-form"
                    _lpchecked="1"
                    method="post"
                    onSubmit={this.submitEmail}
                  >
                    <h5>Get notified when we are open</h5>

                    <div className="form-group form-inline">
                      <input
                        type="email"
                        name="w-newssletter"
                        placeholder="Enter your email"
                        id="mc-email"
                        onChange={this.onchangeEmail}
                        className="form-control"
                        required
                      />
                      <input
                        type="submit"
                        name="subscribe"
                        value="Subscribe"
                        className="btn-subc"
                      />
                    </div>

                    <div className="subscribe-result">
                      <span className="text-danger">
                        {this.state.errorNewsLetter}
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <IndexFooter />
      </div>
    );
  }
}
export default Career;
