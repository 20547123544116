import React, { Component } from "react";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import {
  Media,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import reset_password_img from "./images/forget-password.png";
import { Link } from "react-router-dom";
import "./styles/login.css";
import { userService } from "../services/user.service";
import logoImage from "./images/logo-img.png";
import logoThisHeart from "./images/thisheart_group.svg";
import loaderImg from "./images/loader.gif";
import { Helmet } from "react-helmet";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      submitted: false,
      loading: false,
      requestFailed: false,
      serverMessage: "",
      statusCode: "",
      helpOver: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      requestFailed: false,
      statusCode: "",
      serverMessage: "",
    });

    const { email } = this.state;
    if (email) {
      this.setState({ loading: true });
      userService.resetPasswordRequest(email, this);
    }
  }

  emailValidation() {
    if (this.state.submitted && !this.state.email) {
      return <div className="text-danger text-start">Email is required</div>;
    } else if (
      this.state.submitted &&
      this.state.requestFailed &&
      this.state.statusCode === 404
    ) {
      return <div className="help-block-login">{this.state.serverMessage}</div>;
    }
  }

  backToLogin = () => {
    this.props.history.push("/login");
  };
  renderBody() {
    if (this.state.submitted && this.state.statusCode === 200) {
      return (
        <div>
          <Row>
            <Col sm="12">
              <div className="logo-set">
                <Media object src={logoImage} alt="logo" />
              </div>
              <div className="send-password-link text-muted text-center">
                <h4 className="set-text">You’re All Set!</h4>
              </div>
            </Col>
          </Row>
          <div className="row">
            <div className="col-md-4 col-xs-4 col-sm-4"></div>
            <div className="col-md-6">
              <div className="row">
                <div className="col-md-2 col-xs-6 col-sm-12 midtxt">
                  <span className="icon-mark">
                    <i class="fa fa-check" aria-hidden="true"></i>
                  </span>
                </div>
                <div className="col-md-6 col-xs-6 col-sm-12 midtxt">
                  <p className=" text-muted ">
                    We’ve sent you an email with
                    <br /> steps to reset your password.
                  </p>
                </div>
                <div className="col-md-4 col-xs-6 col-sm-12"></div>
              </div>
            </div>
            <div className="col-md-2 col-xs-2 col-sm-2"></div>
          </div>
          <Link
            className="sign-in-from-here text-muted text-center"
            to="/login"
          >
            <Button className="reset-btn">Back to Login</Button>
          </Link>
        </div>
      );
    }

    return (
      <div className="row m-auto" style={{ maxHeight: '450px' }}>
        <div className="col-md-6 pr-0">
          <div className="d-flex justify-content-center">
            <img className="img-fluid" src={reset_password_img} style={{ maxHeight: '300px' }}></img>
          </div>
        </div>
        <div className="col-md-6 border p-0 overflow-hidden card-container">
          <div className="bg-thisheart text-dark p-2">
            <h3 className="text-center">Reset Your Password</h3>
            <div className="text-center">
              Forgot your password? Don't worry.<br /> We’ll email you instructions to reset it
            </div>
          </div>
          <div className="col-md-12">
            <Form className="login-form bnregister-form" onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label className="lbl-regbn control-label mb-1">Email Address<span className="text-danger">*</span> </Label>
                <Input
                  className="form-control"
                  type="email"
                  name="email"
                  id="exampleEmail"
                  placeholder="Enter your email"
                  value={this.state.email}
                  onChange={this.handleChange}
                />
                {this.emailValidation()}
              </FormGroup>

              <div className="d-flex justify-content-start">
                <div>
                  {this.state.loading && (
                    <img className="loader-img" alt="loaderImg" src={loaderImg} />
                  )}
                </div>
                <div className="align-items-center">
                  <Button className="btn btn-dark bg-dark text-warning my-4">Reset Password</Button>{" "}
                  <Button className="btn btn-secondary my-4" onClick={this.backToLogin}>
                    Nevermind
                  </Button>
                </div>
              </div>
            </Form>
          </div>
        </div>
      </div>
    );
  }

  redirectImgLink = () => {
    this.props.history.push("/");
  };
  render() {
    return (
      <div className="">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Forgot Password</title>
        </Helmet>
        <div className="">
          <div className="row">
            <div className="col-md-3 logo mt-1">
              <img
                src={logoThisHeart}
                onClick={this.redirectImgLink}
                alt="logo"
                className="img-responsive"
                style={{ cursor: "pointer" }}
              ></img>
            </div>
          </div>
          <div className="container mt-5">
          {this.renderBody()}
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
