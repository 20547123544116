import React, { Component } from "react";
import {
  Media,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import logoImage from "./images/logo-img.png";
import loaderImg from "./images/loader.gif";
import "./styles/forgotpasswordentry.css";
import { userService } from "../services/user.service";
import { Helmet } from "react-helmet";
import zxcvbn from "zxcvbn";

import { FaEyeSlash, FaEye } from "react-icons/fa";
import { ToastsContainer, ToastsStore } from "react-toasts";
import reset_password from "./images/reset-password.png";
import logoThisHeart from "./images/thisheart_group.svg";

class ForgotPasswordEntry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      accessToken: "",
      password: "",
      passwordVisible: "password",
      confirmPassword: "",
      primaryUserId: 0,
      beneficiaryId: 0,
      submitted: false,
      loading: false,
      resetFailed: false,
      errorMessage: "",
      statusCode: "",
      errorPassword: [],
      passwordScore: "",
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
    if (name === "password") {
      const passwordSuggent = zxcvbn(value);
      this.setState({
        passwordScore: passwordSuggent.score,
        errorPassword: passwordSuggent.feedback.suggestions,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.password.length < 8 || this.state.passwordScore < 3) {
      ToastsStore.warning("Password length isn't in correct format!");
      return;
    }

    this.setState({
      submitted: true,
      resetFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    if (this.state.password.length < 8) {
      return;
    }

    const { accessToken, password, email } = this.state;

    if (accessToken && password && email) {
      this.setState({
        loading: true,
        resetFailed: false,
      });
      userService.resetPasswordValidate(email, accessToken, password, this);
    }
  }

  emailValidation() {
    if (this.state.submitted && !this.state.email) {
      return <div className="help-block-login">Email is required</div>;
    } else if (
      this.state.submitted === true &&
      this.state.resetFailed === true &&
      this.state.statusCode === 406
    ) {
      return <div className="help-block-login">{this.state.errorMessage}</div>;
    }
  }

  tokenValidation = () => {
    if (this.state.submitted && !this.state.accessToken) {
      return <div className="help-block-login">Access Token is required</div>;
    } else if (
      this.state.submitted === true &&
      this.state.resetFailed === true &&
      this.state.statusCode === 404
    ) {
      return <div className="help-block-login">{this.state.errorMessage}</div>;
    }
  };

  passwordValidation = () => {
    if (this.state.submitted && !this.state.password) {
      return <div className="help-block">Password is required</div>;
    } else if (this.state.submitted && this.state.password.length < 8) {
      return (
        <div className="help-block">
          Password is too short. Minimum length is 8 characters.
        </div>
      );
    }

    return <div className="help-block" />;
  };

  passwordConfirmation = () => {
    if (
      this.state.submitted &&
      this.state.password !== this.state.confirmPassword
    ) {
      return <div className="help-block">Password does not match</div>;
    } else {
    }
    return <div className="help-block" />;
  };

  eyeIcon = (regPass) => {
    if (regPass) {
      if (this.state.passwordVisible === "password") {
        this.setState({ passwordVisible: "text" });
      } else {
        this.setState({ passwordVisible: "password" });
      }
    }
  };

  render() {
    return (
      <div className="register-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Forgot Password Entry</title>
        </Helmet>

        <div className="row mb-3">
          <div className="col-md-3 logo mt-1 pl-lg-5 pl-3">
            <img
              src={logoThisHeart}
              onClick={this.redirectImgLink}
              alt="logo"
              className="img-responsive"
              style={{ cursor: "pointer" }}
            ></img>
          </div>
        </div>
        <div className="row m-auto container">
          <div className="col-md-6 pr-0">
            <div className="d-flex justify-content-center align-items-center">
              <img className="img-fluid" src={reset_password}></img>
            </div>
          </div>
          <div className="col-md-6 border p-0 overflow-hidden card-container" style={{ maxHeight: '700px' }}>
            <div className="bg-thisheart text-dark p-2">
              <h3 className="text-center">Reset Password</h3>
              <div className="text-center">
                Now that you're here, create a new password so you can easily come
                back
              </div>
            </div>
            <div className="col-md-12">
              <Form className="register-form" onSubmit={this.handleSubmit}>
                <FormGroup>
                  <Label className="lbl-regbn control-label mb-1">Email Address</Label>
                  <Input
                    className="form-control"
                    type="email"
                    name="email"
                    id="userEmail"
                    placeholder="Your email address"
                    onChange={this.handleChange}
                  />
                </FormGroup>
                {this.emailValidation()}
                <FormGroup>
                  <Label className="lbl-regbn control-label mb-1">Access Token</Label>
                  <Input
                    className="form-control"
                    type="text"
                    name="accessToken"
                    id="accessToken"
                    onChange={this.handleChange}
                    placeholder="Your access token"
                  />
                </FormGroup>
                {this.tokenValidation()}
                <FormGroup>
                  <Label className="lbl-regbn control-label mb-1">New Password</Label>
                  <Input
                    className="form-control"
                    style={{ padding: '0.375rem 1.9rem 0.375rem 0.75rem' }}
                    type={this.state.passwordVisible}
                    name="password"
                    id="userPassword"
                    onChange={this.handleChange}
                    placeholder="Your password"
                  />
                  <span
                    className="icon-reset-pass"
                    onClick={this.eyeIcon.bind(this, "regPassword")}
                  >
                    {this.state.passwordVisible === "password" ? (
                      <FaEyeSlash />
                    ) : (
                      <FaEye />
                    )}
                    {/* : {this.state.passwordScore} */}
                  </span>
                </FormGroup>
                {this.passwordValidation()}
                <div className="text-danger w-75 mt-1">
                  <ul className="pl-2">
                    {this.state.errorPassword.map((err, index) => (
                      <li className="list-item" key={index}>{err}</li>
                    ))}
                  </ul>
                </div>
                <div className="mb-3">
                  {this.state.loading && (
                    <img className="loader-img" alt="loaderImg" src={loaderImg} />
                  )}
                  <Button className="btn mt-2 px-4 py-2 border-0" style={{background:"#f6dc20", color:"#000", borderRadius:"7px"}}>Save</Button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

export default ForgotPasswordEntry;
