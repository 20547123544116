import React, { Component } from "react";
import paymentImage from "../pages/images/payment-success.jpg";
import {
  FaCheckCircle,
} from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import "../components/asterisk.css";
import "./styles/PackageSubscription.css";
import { Helmet } from "react-helmet";

import Header from "./../components/Header";
import apiService from "./../services/api.service";
import { authHeader } from "../helpers/auth-header";
import MainServices from "../services/main.service";
import {
  Col,
  Row,
  Button,
} from "reactstrap";
import "./styles/accounts.css";
import { encrypt_url } from "./../helpers/encrypt-url";
// import PackagesServices from "./../services/packages.service";
// import loaderImg from "./images/loader.gif";
import { ToastsStore } from "react-toasts";
// import StripeCheckout from "react-stripe-checkout";
// import Stripe from "stripe";
import axios from "axios";
// import { loadStripe } from "@stripe/stripe-js";
//import Stripe from 'https://js.stripe.com/v3/';

const apiUrl = apiService.apiUrl;
class PaymentSuccessProfile extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.state = {
      userObj: JSON.parse(user),
      userPackage: "",
      allPackages: "",
      selectPackageView: false,
      selectedPackage: "",
      encryptedString: encrypt_url(),
      paymentSession: "",
      payment_success:'Payment Successfull'
    };
  }

  componentDidMount() {
    if(!localStorage.getItem('user')){
      //this.props.history.push('/login')
      //return false;
    }
    const query = new URLSearchParams(this.props.location.search);
    const session_id = query.get("session_id");

    let header = {
      headers: {
        "Content-Type": "application/json",
        Authorization: authHeader(),
      },
    };

    let payload = {
      id: session_id?session_id:this.props.match.params.url,
      session_token: this.state.encryptedString,
      session_type: "profile",
    };
    console.log('payload:', payload);
    axios
      .post(`${apiUrl}/api/package/payment/session-success-profile`, payload, header)
      .then(
        (response) => {
          console.log("success", response.data);
          if (response.data.status === "success") {
            console.log("success", response);
            localStorage.setItem("twofa", "yes");
            MainServices.removeStore('trial_process')
            localStorage.setItem("package_info", response.data.package_info);
          }
        },
        (error) => {
          console.log("error", error.response.data.message);
          this.setState({
            payment_success:"Sorry, payment request has problem!"
          })
          ToastsStore.error(error.response.data.message);
        //   localStorage.removeItem("twofa");
          //this.props.history.push(`/dashboard/${this.state.encryptedString}`);
          return false;
        }
      );
  }

  componentWillUnmount() {
    // this.mounted = false;
  }

  handleBackBtn = (e) => {
    this.props.history.push(`/dashboard/${this.state.encryptedString}`);
    return false
  };

  bodyPackageContent = () => {
    return (
      <div>
        <Row className="mr-3">
          <Col sm={12}>
            <div className="card">
              <div className="card-header">Package Payment</div>
              <div className="card-body">
            
                <Row>
                  <Col sm={6} className="text-center mt-4 text-success">
                    <h1 className="text-center mt-4 text-success">
                      <FaCheckCircle />
                    </h1>
                    <h3 className=" text-success mt-5">{this.state.payment_success}</h3>
                  
                    <Button
                      className="btn btn-warning mt-4"
                      onClick={this.handleBackBtn}
                    >
                      Back to Dashboard
                    </Button>
                  </Col>

                  <Col sm={6} className="text-center">
                    <img
                      src={paymentImage}
                      alt="Payment Success"
                      width="99%"
                      height="auto"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col sm={5}></Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="account-container package-subscription">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Payment Success</title>
        </Helmet>
        <Header {...this.props} />
        <div>
          <Row className=" mt-4">
            <Col sm="8" className="account-name">
              <h1 className="account-header mt-5 pt-1">Payment Status</h1>
              {/* <p>
                Nunc vel varius tortor. Quisque turpis enim, iaculis id nisi
                quis, consectetur cursus velit. Vivamus in tortor vehicula.
              </p> */}
            </Col>
          </Row>
        </div>

        <div className="contain-body">
          <Row>
            <Col sm={1}>{/*this.showSideMenu()*/}</Col>
            <Col sm={10}>{this.bodyPackageContent()}</Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PaymentSuccessProfile;
