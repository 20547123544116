import React, { Component } from "react";
import { FaMoneyBill} from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import "../components/asterisk.css";
import "./styles/PackageSubscription.css";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import { Col, Row, FormGroup, Button, Label } from "reactstrap";
import "./styles/accounts.css";
import { encrypt_url } from "./../helpers/encrypt-url";
import PackagesServices from "./../services/packages.service";
import { ToastsStore } from "react-toasts";
// import { authHeader } from "../helpers/auth-header";
// import StripeCheckout from "react-stripe-checkout";
// import Stripe from "stripe";
// import axios from "axios";
// import { loadStripe } from "@stripe/stripe-js";
// import MainServices from "../services/main.service";
// import CardForm from 'react-payment/dist/CardForm';
// import stripe from 'https://js.stripe.com/v3/';
// import apiService from "./../services/api.service";

//var stripe = Stripe('pk_test_9muBAZnjHeFvBwxhQO8k145F00GXiohNqD');
// const stripes = loadStripe('pk_test_9muBAZnjHeFvBwxhQO8k145F00GXiohNqD');
// var stripe = Stripe('pk_test_9muBAZnjHeFvBwxhQO8k145F00GXiohNqD');
// // const apiUrl = apiService.apiUrl;
class DemoPaymentPage extends Component {
  constructor(props) {
    super(props);

    //var stripe = Stripe('pk_test_9muBAZnjHeFvBwxhQO8k145F00GXiohNqD');
    let user = localStorage.getItem("user");
 

    this.state = {
      userObj: JSON.parse(user),
      userPackage: "",
      allPackages: "",
      selectPackageView: true,
      selectedPackage: "",
      encryptedString: encrypt_url(),
      paymentSession: "",
      package_info: "",
      paymentType:'yearly',
      billingType:true,
      paymentCardDetails:''
    };
  }

  componentDidMount() {
    this.getPackageByUser();
  }

  componentWillUnmount() {
    // this.mounted = false;
  }

 
  
 

  onChangeCard = e =>{
    let {name,value} = e.target;
    this.setState({
      paymentCardDetails:{
        ...this.state.paymentCardDetails,
        [name]:value
      }}
    )
  }

  onSubmit = (card) => {
    console.log('card details', this.state.paymentCardDetails)
    // const stripes = loadStripe("pk_test_bgDg00SDeZ5X4jctyhmpeNqV00SVlg42Ll");
    // const { number, exp_month, exp_year, cvc, name, zip } = card;
    // stripes.card.createToken({
    //   number,
    //   exp_month,
    //   exp_year,
    //   cvc,
    //   name,
    //   address_zip: zip
    // }, (status, response) => {
    //   if (response.error) {
    //     alert('Adding card failed with error: ' + response.error.message);
    //   } else {
    //     const cardToken = response.id;
    //     console.log('card token:......', cardToken)
    //     // send cardToken to server to be saved under the current user
    //     // show success message and navigate away from form
    //   }
    // });
  }

  

  handleStripePayment = () => {
        const session_id = this.props.match.params.url
        let {cardnumber, cvc, email, expires, fname} = this.state.paymentCardDetails
        console.log('card details', this.state.paymentCardDetails)
        if(cardnumber && cvc && email && expires && fname){
          this.props.history.push(`/payment-success-profile/${session_id}`)
        }else{
          ToastsStore.warning("Please confirm card information!")
        }
        
        //
  };



  bodyPackageContent = () => {
    if (this.state.selectPackageView) {
      return (
        <div>
          <Row className="mr-3">
            <Col sm={12}>
              <div className="card">
                <div className="card-header">Package & Payment</div>
                <div className="card-body">
                  <Row>
                    <Col sm={6}>
                     <img src="/images/package-payment.jpg" alt="Payment Details" style={{width:'100%'}}/>
                    </Col>

                    <Col>
                        <FormGroup>
                            <Label for="card-number">Email</Label>
                            <input type="email" onChange={this.onChangeCard} id="email" name="email" className="form-control"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="card-number">Card Number</Label>
                            <input type="number" onChange={this.onChangeCard}  id="cardnumber" name="cardnumber" className="form-control"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="card-number">Expires</Label>
                            <input type="date" onChange={this.onChangeCard}  id="expires" name="expires" className="form-control"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="card-number">CVC</Label>
                            <input type="number" onChange={this.onChangeCard}  id="cvc" name="cvc" className="form-control"/>
                        </FormGroup>
                        <FormGroup>
                            <Label for="card-number">Name</Label>
                            <input type="text" onChange={this.onChangeCard}  id="fname" name="fname" className="form-control"/>
                        </FormGroup>
                        <p className="text-center">
                            <Button
                            className="btn btn-success"
                            onClick={this.handleStripePayment}
                            >
                            <FaMoneyBill /> &nbsp; Process Payment
                            </Button>
                        </p>
                    </Col>

                  </Row>
                  <Row>
                    <Col sm={6}>
                     
                    </Col>

                    <Col sm={6} className="text-center">
                    
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col sm={5}></Col>
          </Row>
        </div>
      );
    } else {
      return (
        <div>
          <Row className="mr-3">
          </Row>
        </div>
      );
    }
  };

  selectPackage = () => {
    this.setState({ selectPackageView: true });
  };

  getPackages = () => {
    PackagesServices.getPackages(this).then(
      (response) => {
        // console.log(response)
        if (response.data.status === "success") {
          this.setState({
            packageList: response.data.data,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  getPackageByUser = () => {
    PackagesServices.getPackageByUser(this).then(
      (response) => {
        if (response.data.status === "success") {
          let allPackage = response.data.all_package_list;
          // allPackage.shift();
          let pkgList = allPackage.filter((pkg) => {
            if (
              pkg.package.toLowerCase() !== "admin" &&
              pkg.package.toLowerCase() !== "trial package" && 
              pkg.package.toLowerCase() !== "free account" 
            ) {
              return true;
            }
          });

          this.setState({
            userPackage: response.data.data,
            allPackages: pkgList,
            selectedPackage: {
                pkgID: response.data.data.id,
                pkgName: response.data.data.package,
                pkgPrice: response.data.data.price,
                pkgYearPrice: response.data.data.year_price,
                pkgDescription: response.data.data.description,
              },
          });
        }
      },
      (error) => {
        console.log(error);
        ToastsStore.error(
          "Sorry, package info error, "  
        );
      }
    );
  };

  render() {
    return (
      <div className="account-container package-subscription">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Package Payment</title>
        </Helmet>
        <Header {...this.props} />
        <div>
          <Row className=" mt-4">
            <Col sm="8" className="account-name">
              <h1 className="account-header mt-5 pt-1">Payment Details</h1>
              {/* <p>
                Nunc vel varius tortor. Quisque turpis enim, iaculis id nisi
                quis, consectetur cursus velit. Vivamus in tortor vehicula.
              </p> */}
            </Col>
          </Row>
        </div>

        <div className="contain-body">
          <Row>
            <Col sm={1}>{/*this.showSideMenu()*/}</Col>
            <Col sm={10}>{this.bodyPackageContent()}</Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default DemoPaymentPage;
