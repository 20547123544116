import React, { Component } from "react";
import { Media, Form, FormGroup, Label, Input, Button } from "reactstrap";
import { Link } from "react-router-dom";
import loaderImg from "./images/loader.gif";
import regLogo from "./images/logo-img.png";
import registerPhoto from "./images/regphoto.jpg";
import logoImage from "./images/thisheart_group.svg";
import signInImage from "./images/signin.jpg";
import arrowRight from "./images/arrow-right.svg";
import arrowUpRight from "./images/arrow-up-right.svg";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import "./styles/register.css";
import { userService } from "../services/user.service";
import { Helmet } from "react-helmet";
import "react-responsive-ui/style.css";
import "react-phone-number-input/style.css";
import { encrypt_url } from "../helpers/encrypt-url";
import "./styles/homepage.css";
import "../components/asterisk.css";
import zxcvbn from "zxcvbn";
// import Cryptr from "cryptr";
import MainServices from "../services/main.service";


class RegisterNew extends Component {
  constructor(props) {
    super(props);

    this.state = {
      email: "",
      userName: "",
      password: "",
      passwordVisible: "password",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      hasError: "",
      errorPassword: [],
      passwordScore: "",
      regErrors: "",
      statusCode: "",
      btnActive: false,
      encryptedString: encrypt_url(),
      errorEmail: '',
      errorUsername: '',
      errorPass: ''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    const { password } = this.state;
  }

  componentDidMount() {
    if (localStorage.getItem("user") && localStorage.getItem("twofa") &&
      localStorage.getItem("select_package") !== "yes") {
      this.props.history.push(`/accounts/${this.state.encryptedString}`);
    }
  }

  handleChange(e) {
    const { name, value } = e.target;
    const email = document.getElementById('userEmail').value
    const userName = document.getElementById('userName').value
    const password = document.getElementById('userPassword').value

    let hasError;
    this.setState({ [name]: value });
    this.setState({ submitted: false });

    if (!email) {
      this.setState({ errorEmail: 'Email is required' })
    } else {
      this.setState({ errorEmail: '' })
    }
    if (!userName) {
      this.setState({ errorUsername: 'Name is required' })
    } else {
      this.setState({ errorUsername: '' })
    }
    if (!password) {
      this.setState({ errorPass: 'Password is required' })
    } else {
      this.setState({ errorPass: '' })
    }

    if (name === "password") {
      const passwordSuggent = zxcvbn(value);

      if (passwordSuggent.score < 3) {
        hasError = true
        this.setState({
          passwordScore: passwordSuggent.score,
          errorPassword: passwordSuggent.feedback.suggestions,
          hasError
        });
      } else {
        console.log('error score:::-', passwordSuggent.score, this.state.errorPass)
        console.log('error score:::-', this.state.errorPass)
        hasError = false;
        this.setState({
          passwordScore: '',
          errorPassword: '',
          errorPass: '',
          hasError
        });
      }
    }



    if (email && userName && password && password.length > 6) {

      this.setState({
        btnActive: true,
      });
    } else {

      this.setState({
        btnActive: false,
        hasError: true
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();

    const { email, userName, password } = this.state;
    const passwordSuggents = zxcvbn(password);

    if (email.length < 1) {
      this.setState({
        errorEmail: 'Email is required'
      })
    }

    if (userName.length < 1) {
      this.setState({
        errorUsername: 'User full name is required'
      })
    }

    const getValid = this.passwordValidation();

    if (this.state.password.length < 8 || passwordSuggents.score < 2 || getValid.props.children) {
      this.setState({ loading: false });
      this.setState({
        errorMessage: "Registration fail: password is weak",
        hasError: true
      });
      return false;
    }



    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });


    // if(this.state.hasError){
    //   this.setState({ loading: false });
    //       this.setState({
    //         errorMessage: "Registration fail: password is weak",
    //       });
    //   return false
    // }

    if (email && userName && password) {
      this.setState({ loading: true });
      userService.register(email, userName, password, this).then(
        (response) => {
          if (response.data.status === "success") {
            let now = new Date(),
              expires = new Date();
            expires.setMinutes(now.getMinutes() + 30);
            localStorage.setItem("auth_expires", expires);
            localStorage.setItem("user", JSON.stringify(response));
            // MainServices.setBStore('user_type', "primary")
            localStorage.setItem("user_type", "primary");
            MainServices.setBStore('reg_process', email)
            MainServices.setBStore('reg_email', email)
            const user_type = response.data.user_type;
            const user_package = response.data.sub_plan;
            // const cryptrs = new Cryptr("thisheart");

            if (user_type === "primary" && user_package === "NA") {
              this.props.history.push('/selectpackage')
              return false;
            }


          } else if (response.data.status === "fail") {
            this.setState({
              regFailed: true,
              errorMessage: response.data.message,
              loading: false,
              statusCode: response.status,
              regErrors: response.data.data,
              hasError: true
            });
          }
        },
        (error) => {
          this.setState({ loading: false });
          this.setState({
            errorMessage: "Registration fail: email/mobile is already exists",
            hasError: true
          });
        }
      );
    }
  }

  emailValidation() {
    if (this.state.submitted && !this.state.email) {
      this.setState({
        hasError: true
      });
      return <div className="reg-help-block mt-2">Email is required</div>;
    } else if (this.state.submitted === true && this.state.regFailed === true) {

      return (
        <div className="reg-help-block mt-2"> {this.state.regErrors.email}</div>
      );
    }
  }

  passwordValidation() {
    const { submitted, password } = this.state;

    if (submitted && !password) {
      this.setState({
        hasError: true
      });
      return <div className="reg-help-block mt-3">Password is required</div>;
    }

    if (submitted && password.length < 8) {
      this.setState({
        hasError: true
      });
      return <div className="reg-help-block  mt-2">Password is too short. Minimum length is 8 characters.</div>;
    }

    const hasDigit = /[0-9]/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);
    const hasUppercase = /[A-Z]/.test(password);
    const hasLowercase = /[a-z]/.test(password);

    if (password && !hasDigit) {
      //return <div className="reg-help-block mt-2">Password must contain at least one digit.</div>;
    }

    if (password && !hasSpecialChar) {
      //return <div className="reg-help-block mt-2">Password must contain at least one special character.</div>;
    }

    if (password && !hasUppercase) {
      //return <div className="reg-help-block mt-2">Password must contain at least one uppercase letter.</div>;
    }

    if (password && !hasLowercase) {
      //return <div className="reg-help-block mt-2">Password must contain at least one lowercase letter.</div>;
    }

    return (<div className="reg-help-block" ></div>);
  }

  eyeIcon = (regPass) => {
    if (regPass) {
      if (this.state.passwordVisible === "password") {
        this.setState({ passwordVisible: "text" });
      } else {
        this.setState({ passwordVisible: "password" });
      }
    }
  };

  redirectImgLink = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="reg-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart- Registration</title>
          <link rel="registration" href="/registration" />
        </Helmet>
        <div id="header" className="fixed-top mb-3">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-3 logo " style={{ marginTop: '-15px' }}>
                <img
                  src={logoImage}
                  onClick={this.redirectImgLink}
                  alt="logo"
                  className="img-responsive"
                  style={{ cursor: "pointer" }}
                ></img>
              </div>
            </div>
          </div>
        </div>


        {/**** signin part ****/}
        <div className="row align-items-center mt-5 mb-3" >
          <div className="col-md-2"></div>
          <div className="col-md-8 col-md-pull-3">
            <div className="row overflow-hidden" style={{ borderRadius: '15px', overFlow: 'hidden' }}>
              <div className="col-md-6 p-0 bg-signinImage">
                <img
                  src={registerPhoto}
                  className="img-fluid mx-auto d-block v-100 sm-hide"
                  alt="signin"
                  style={{ height: '100%' }}
                />
              </div>
              <div className="col-md-6 bg-thisheart p-4">
                <Form onSubmit={this.handleSubmit} method="post">
                  <h3 className="mt-3"><strong>GET STARTED NOW</strong></h3>
                  <h4 className="mt-2">The first 30 days are free. We've got you!</h4>
                  <div className="row mt-4">

                    <div className="col-md-12">
                      <label>Email Address</label>
                    </div>
                    <div className="col">
                      <Input
                        className="form-control border border-dark rounded-lg"
                        type="email"
                        name="email"
                        id="userEmail"
                        placeholder="Email"
                        onChange={this.handleChange}
                        autoComplete="off"
                      />

                      <div className="text-danger">
                        <div className="help-block-signin">
                          {this.state.errorMessage &&
                            this.state.errorMessage.user_type
                            ? this.state.errorMessage.user_type
                            : ""}
                        </div>
                        {this.emailValidation()}
                        {this.state.errorEmail}
                      </div>



                    </div> {/** end col **/}

                  </div>

                  <div className="row mt-4">

                    <div className="col-md-12">
                      <label>Full Name</label>
                    </div>
                    <div className="col">
                      <Input className="form-control border border-dark rounded-lg" type="text" name="userName" id="userName" onChange={this.handleChange} placeholder="Full Name" autoComplete="off" />

                      <div className="text-danger">
                        {this.state.errorUsername}
                      </div>
                    </div> {/** end col **/}

                  </div>

                  <div className="row">
                    <div className="col-md-12 mt-3">
                      <label>Password</label>
                    </div>
                    <div className="col">
                      <Input
                        type={this.state.passwordVisible}
                        className="form-control border border-dark rounded-lg"
                        name="password"
                        id="userPassword"
                        onChange={this.handleChange}
                        placeholder="Password"
                        autoComplete="off"
                      />
                      <span
                        className="icon-reg"
                        onClick={this.eyeIcon.bind(this, "regPassword")}
                      >
                        {this.state.passwordVisible === "password" ? (
                          <FaEyeSlash />
                        ) : (
                          <FaEye />
                        )}
                        {/* : {this.state.passwordScore} */}
                      </span>
                      <div className="mt-3">
                        {this.passwordValidation()}
                      </div>
                      <div className="text-danger ml-0 mt-0">
                        <ul className="ml-0 pl-3">
                          {this.state.errorPassword && this.state.errorPassword.map((err, index) => {
                            return (<li key={index}>{err}</li>)
                          }

                          )}
                          {this.state.errorMessage.length ? (<li>{this.state.errorMessage}</li>) : ""}
                        </ul>
                      </div>

                      <div>

                        {this.state.loading && (
                          <img
                            className="loader-img"
                            alt="loaderImg"
                            src={loaderImg}
                          />
                        )}
                        <button className="btn btn-darked btn-block mt-5" type="submit"  >
                          Register
                          {this.state.loading && (
                            <i className="fa fa-spinner fa-pulse">&nbsp;</i>
                          )}
                          {!this.state.loading && (
                            <img src={arrowUpRight} className="ml-2"></img>
                          )}

                        </button>
                      </div>

                    </div>
                  </div>{/** end row**/}
                  <div className="row">
                    <div className="col-md-6 mt-3">
                      <p className="text-dark">Already have an account?</p>
                    </div>
                    <div className="col-md-6 mt-3 text-right">
                      <Link to="/login#top" className="text-dark">Sign in here <img src={arrowRight} className="ml-2"></img></Link>
                    </div>
                  </div>{/** end row**/}

                </Form>
              </div>{/** end col-md-6 **/}
            </div>
          </div>
          <div className="col-md-2"></div>
        </div>




      </div>
    );
  }
}

export default RegisterNew;
