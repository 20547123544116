import React, { Component } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { PrivateRoute } from "../components/PrivateRoute";
import { StepRoute } from "../components/StepRoute";
import { RegRoute } from "../components/RegRoute";
import { TrialRoute } from "../components/TrialRoute";
import Accounts from "./Accounts";
import Memories from "./Memories";
import MedicalHistory from "./MedicalHistory";
import Contributors from "./Contributors";
import AddContributors from "./AddContributors";
import Beneficiaries from "./Beneficiaries";
import AddBenefeciary from "./AddBenefeciary";
import RegisterNew from "./RegisterNew";
import ForgotPassword from "./ForgotPassword";
import AccountProgress from "./AccountProgress";
import BeneficiaryAccess from "./BeneficiaryAccess";
import ContributorsAccess from "./ContributorsAccess";
import BeneficiaryRegistration from "./BeneficiaryRegistration";
import ContributorsRegistration from "./ContributorsRegistration";
import ForgotPasswordEntry from "./ForgotPasswordEntry";
import EnrollTwoFactorAuth from "./EnrollTwoFactorAuth";
import ResetTwoFactorAuth from "./ResetTwoFactorAuth";
import ResetTwoFactorAuthAskPassword from "./ResetTwoFactorAuthAskPassword";
import VerifyTwoFactorAuth from "./VerifyTwoFactorAuth";
import VerifySettingsTwoFactorAuth from "./VerifySettingsTwoFactorAuth";
import DisableTwoFactorAuth from "./DisableTwoFactorAuth";
import VerifyGoogleAuthQRcode from "./VerifyGoogleAuthQRcode";
import BeneficiaryLanding from "./BeneficiaryLanding";
import BeneficiaryEntry from "./BeneficiaryEntry";
import RegisterEmailConfirmation from "./RegisterEmailConfirmation";
import VerifyEmailRegistration from "./VerifyEmailRegistration";
import InstagramCallback from "./InstagramCallback";
import EditProfile from "./EditProfile";
import TwitterCallback from "./TwitterCallback";
import OnBoardingLifeStatus from "./OnBoardingLifeStatus";
import OnBoardingBenefi from "./OnBoardingBenefi";
import OnBoardingPasswordManagement from "./OnBoardingPasswordManagement";
import HashTagGenerate from "./HashTagGenerate";
import OnBoardingStepOne from "./OnBoardingStepOne";
import OnBoardingStepTwo from "./OnBoardingStepTwo";
import OnBoardingStepOneVerifySmsEmail from "./OnBoardingStepOneVerifySmsEmail";
import OnBoardingStepOneVerifyGoogle from "./OnBoardingStepOneVerifyGoogle";
import Home from "./IndexHome";

import DetailsMemories from "./DetailsMemories";
import DetailsAccount from "./DetailsAccount";
import DetailsHealth from "./DetailsHealth";
import AboutUs from "./AboutUs";
import PrivacyPolicy from "./PrivacyPolicy";
import TermsCondition from "./TermsCondition";
import PricingPlan from "./PricingPlan";
import MemoriesImageInfo from "./MemoriesImageInfo";
import MemoriesVideoInfo from "./MemoriesVideoInfo";
import MemoriesLetterInfo from "./MemoriesLetterInfo";
import MemoriesAudioInfo from "./MemoriesAudioInfo";
import MemSocInfo from "./MemSocInfo";
import MedicInfo from "./MedicInfo";
import InfoAccFinance from "./InfoAccFinance";
import InfoAccLegal from "./InfoAccLegal";
import InfoAccSocial from "./InfoAccSocial";
import InfoAccInsurance from "./InfoAccInsurance";
import ContactUs from "./ContactUs";
import Career from "./Careers";
import Security from "./Security";
import OnBoardingHealth from "./OnBoardingHealth";

import SignIn from "./SignIn";
import MainDashboard from "./MainDashBoard";
import MainDashboardContributors from "./MainDashBoardContributors";
import SearchResult from "./SearchResult";
import PackageSubscriptionProfile from "./PackageSubscriptionProfile";
import PackageSubscriptionPayment from "./PackageSubscriptionPayment";
import PackagePayment from "./PackagePayment";
import PaymentSucces from "./PaymentSuccess";
import PaymentSuccessProfile from "./PaymentSuccessProfile";
import PaymentSuccessPayment from "./PaymentSuccessPayment";
import PaymentCancel from "./PaymentCancel";
import PaymentCancelProfile from "./PaymentCancelProfile";
import BnFrontDoor from "./BnFrontDoor";
import PricingPlanTrial from "./EndTrialPackage";
import UnsubscribedPackage from "./UnsubscribedPackage";
import PricingPlanTrialBeneficiary from "./EndTrialPackageBeneficiary";
import UnsubscribeBeneficiary from "./UnsubscribeBeneficiary";
import SelectPackagePlan from "./SelectPackagePlan";
import ErrorPage from "./ErrorPage";
import FreeAccLogin from "./FreeAccLogin";
import ApproveReject from "./ApproveReject";
import DemoPaymentPage from "./DemoPaymentPage";
import apiService from "../services/api.service";
import MemoriesImages from "./MemoriesImages";
import MemoriesVideos from "./MemoriesVideos";
import MemoriesAudios from "./MemoriesAudios";
import MemoriesLetters from "./MemoriesLetters";
import FileAttachmentViewer from "./FileAttachmentViewer";

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      components: [],
    };

    // const urlPath = window.location.pathname
    // const pathLength = urlPath.length
    //const realPath = "/"+window.atob(urlPath.substr(1,pathLength))
    // swtich.history.location.pathname = "/memories"
  }

  componentDidMount() {}

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/home" component={Home} />
          <Route exact path="/aboutus" component={AboutUs} />
          <Route exact path="/privacypolicy" component={PrivacyPolicy} />
          <Route exact path="/termscondition" component={TermsCondition} />
          <Route exact path="/pricingplan" component={PricingPlan} />
          <Route path="/submemories" component={DetailsMemories} />
          <Route path="/subaccount" component={DetailsAccount} />
          <Route path="/subhealth" component={DetailsHealth} />
          <Route path="/memimageinfo" component={MemoriesImageInfo} />
          <Route path="/memvideoinfo" component={MemoriesVideoInfo} />
          <Route path="/memletterinfo" component={MemoriesLetterInfo} />
          <Route path="/memaudioinfo" component={MemoriesAudioInfo} />
          <Route path="/memsocinfo" component={MemSocInfo} />
          <Route path="/medicinfo" component={MedicInfo} />
          <Route path="/infofinance" component={InfoAccFinance} />
          <Route path="/infolegal" component={InfoAccLegal} />

          <Route path="/infosocacc" component={InfoAccSocial} />
          <Route path="/infoinsurance" component={InfoAccInsurance} />
          <Route path="/contact" component={ContactUs} />
          <Route path="/career" component={Career} />
          <Route path="/security" component={Security} />
          <Route path="/bnfront" component={BnFrontDoor} />

          <Route
            exact
            path="/admin"
            component={() => {
              window.location.href = apiService.apiUrl; 
              return null;
            }}
          />

          <PrivateRoute path="/dashboard/:url" component={MainDashboard} />
          <PrivateRoute path="/dashboard-contributors/:url" component={MainDashboardContributors} />
          <PrivateRoute path="/search" component={SearchResult} />
          <PrivateRoute exact path="/accounts/:url" component={Accounts} />

          {/* Memories Page */}
          <PrivateRoute exact path="/memories/:url" component={Memories} />
          <PrivateRoute exact path="/memories-images" component={MemoriesImages} />
          <PrivateRoute exact path="/memories-videos" component={MemoriesVideos} />
          <PrivateRoute exact path="/memories-audios" component={MemoriesAudios} />
          <PrivateRoute exact path="/memories-letters" component={MemoriesLetters} />
          <PrivateRoute exact path="/medicalhistory/:url" component={MedicalHistory} />
          <PrivateRoute  exact path="/accountprogress/:url"  component={AccountProgress} />
          <PrivateRoute  exact  path="/contributors/:url" component={Contributors} />
          <PrivateRoute  exact path="/addcontributors/:url" component={AddContributors} />
          <PrivateRoute exact path="/beneficiaries/:url" component={Beneficiaries} />
          <PrivateRoute  exact  path="/addbenefeciary/:url" component={AddBenefeciary} />
          <PrivateRoute  exact path="/beneficiarylanding/:url" component={BeneficiaryLanding} />
          <PrivateRoute exact path="/beneficiaryentry/:url" component={BeneficiaryEntry} />
          <PrivateRoute exact path="/resettwofa" component={ResetTwoFactorAuth} />
          <PrivateRoute exact  path="/resettwofaaskpass/:url" component={ResetTwoFactorAuthAskPassword} />
          <PrivateRoute exact path="/verifysettingstwofa" component={VerifySettingsTwoFactorAuth} />
          <PrivateRoute exact path="/disabletwofa" component={DisableTwoFactorAuth} />
          <PrivateRoute exact path="/InstagramCallback" component={InstagramCallback} />
          <PrivateRoute exact path="/editProfile/:url" component={EditProfile} />
          <PrivateRoute exact path="/twitterCallback" component={TwitterCallback} />
          <PrivateRoute exact path="/packagesubscriptionprofile/:url" component={PackageSubscriptionProfile} />
          <PrivateRoute exact path="/packagesubscriptionpayment/:url" component={PackageSubscriptionPayment} />
          <PrivateRoute exact path="/demopaymentpage/:url" component={DemoPaymentPage} />
          <TrialRoute exact path="/packagepayment/:url" component={PackagePayment} />
          <TrialRoute exact path="/payment-success/:url" component={PaymentSucces} />
          <PrivateRoute exact path="/payment-success-profile/:url" component={PaymentSuccessProfile} />
          <PrivateRoute exact path="/payment-success-payment/:url" component={PaymentSuccessPayment}/>
          <TrialRoute exact path="/payment-cancel/:url" component={PaymentCancel}/>
          <PrivateRoute exact path="/payment-cancel-profile/:url" component={PaymentCancelProfile}/>
          <PrivateRoute exact path="/file-attachment-viewer/:url" component={FileAttachmentViewer}/>
          {/* <Route path="/home" component={HomePage} /> */}
          {/* <Route path="/login" component={LogIn} /> */}
          <Route path="/login" component={SignIn} />
          <Route path="/freeadminlogin/:activation_code" component={FreeAccLogin} />
          <Route path="/reqadmin/:activation_code" component={ApproveReject} />
          <Route path="/register" component={RegisterNew} />
          <Route path="/reset/pass" component={ForgotPassword} />
          <TrialRoute path="/trialpriceplan" component={PricingPlanTrial} />
          <TrialRoute path="/unsubscribedpackage" component={UnsubscribedPackage} />
          <TrialRoute path="/trialpriceplanbeneficiary" component={PricingPlanTrialBeneficiary} />
          <TrialRoute path="/unsubscribedbeneficiary" component={UnsubscribeBeneficiary} />
          <RegRoute path="/selectpackage" component={SelectPackagePlan} />
          <Route path="/errorpage" component={ErrorPage} />
          <Route exact path="/verifytwofa" component={VerifyTwoFactorAuth} />
          <Route exact path="/enrolltwofa" component={EnrollTwoFactorAuth} />
          <Route  exact  path="/verifyqrcode" component={VerifyGoogleAuthQRcode} />
          <Route path="/beneficiary/access/:code" component={BeneficiaryAccess} />
          <Route  path="/contributors/access/:code" component={ContributorsAccess} />
          <Route  path="/beneficiary/register" component={BeneficiaryRegistration}  />
          <Route path="/contributor-user/registration/:full_name_url/:email_url/:primary_user_id"  component={ContributorsRegistration} />
          <Route path="/new/password" component={ForgotPasswordEntry} />
          <RegRoute  path="/RegisterEmailConfirmation/:code"  component={RegisterEmailConfirmation} />
          <Route path="/email_verification/:token/:email"  component={VerifyEmailRegistration} />
          <Route path="/onboard2fa" component={OnBoardingStepOne} />
          <Route  path="/onboard2faverifysmsemail" component={OnBoardingStepOneVerifySmsEmail} />
          <Route path="/onboard2faverifygoogle" component={OnBoardingStepOneVerifyGoogle} />
          <StepRoute path="/onboardsocial" component={OnBoardingStepTwo} />
          <StepRoute path="/hashtag_generate" component={HashTagGenerate} />
          <StepRoute path="/onboardpass_manage" component={OnBoardingPasswordManagement}/>
          <StepRoute path="/onboardlife_status" component={OnBoardingLifeStatus}/>
          <StepRoute path="/onboardbenefi_select" component={OnBoardingBenefi}/>
          <StepRoute path="/onboardhealth" component={OnBoardingHealth} />
          <Route path="/*" component={ErrorPage} />
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
