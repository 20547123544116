import { authHeader } from "../helpers/auth-header";
import axios from "axios";
import apiService from "./api.service";
import { ToastsStore } from "react-toasts";

const apiUrl = apiService.apiUrl;

export const contributorsService = {
  storeContributorsUser,
  getBeneficiaryData,
  updateContributorData,
  deleteBeneficiaryData,
  resetBeneficiaryCode,
  sendNewBeneficiaryCode,
  saveBeneficiaryOnBoard
};

function storeContributorsUser(
  userId,
  last_4_beneficiary,
  firstname,
  lastname,
  mailaddress,
  mailaddress2,
  city,
  state,
  zip,
  email,
  full_name_encrypted,
  email_encrypted
) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  let data = JSON.stringify({
    user_id: userId,
    first_name: firstname,
    last_name: lastname,

    mail_address: mailaddress,
    mail_address2: mailaddress2,

    city: city,
    state: state,
    last_4_beneficiary: last_4_beneficiary,
    zip: zip,
    email: email,
    full_name_encrypted,
    email_encrypted
  });

  let newId = 0;

  axios
    .post(`${apiUrl}/api/contributors/store`, data, header)
    .then(response => {
      newId = response.data.data.id;
      ToastsStore.success("Contributor has been created!");
    })
    .catch(function(error) {
      console.log(error.response.data)
      if(error.response.data && error.response.data.code==="exceeds-beneficiaries")
      {
        ToastsStore.error("Sorry, "+ error.response.data.message);
      }else{
        ToastsStore.error(error.response.data.message);
      }
      
    });

  return newId;
}

function getBeneficiaryData(userId, self) {
  let header = {
    headers: {"Content-Type": "application/json", Authorization: authHeader() }
  };

  self.setState({ dataLoading: true });
  var beneficiaryData = [];
  axios
    .get(`${apiUrl}/api/beneficiary/getById/${userId}`, header)
    .then(response => {
      response.data.map(item =>
        beneficiaryData.push({
          id: item.id,
          first_name: item.first_name,
          last_name: item.last_name,
          mail_address: item.mail_address,
          mail_address2: item.mail_address2,
          city: item.city,
          state: item.state,
          last_4_beneficiary: item.last_4_beneficiary,
          zip: item.zip,
          email: item.email
        })
      );
      console.log('response.data:::', response.data)
      self.setState({ BenefeciaryList: beneficiaryData });
      self.setState({ dataLoading: false });
    })
    .catch(function(error) {
      self.setState({ dataLoading: false });
    });
}

function updateContributorData(
  userId,
  selectedItemId,
  firstname,
  lastname,
  mailaddress,
  mailaddress2,
  city,
  state,
  last_4_beneficiary,
  zip,
  email
) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  let data = JSON.stringify({
    user_id: userId,
    first_name: firstname,
    last_name: lastname,
    mail_address: mailaddress,
    mail_address2: mailaddress2,
    city: city,
    state: state,
    last_4_beneficiary: last_4_beneficiary,
    zip: zip,
    email: email
  });

  axios
    .post(`${apiUrl}/api/contributors/updateBy/${selectedItemId}`, data, header)
    .then(response => {
      ToastsStore.success("Data has been updated successfully!");
      return true;
    })
    .catch(function(error) {
      // handle error
    });
}

function deleteBeneficiaryData(selectedItemId) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  let data = JSON.stringify({
    id: selectedItemId
  });

  axios
    .post(`${apiUrl}/api/beneficiary/deleteBy/${selectedItemId}`, data, header)
    .then(response => {
      console.log(response.data);
      ToastsStore.success("Beneficiary has been deleted successfully!");
      console.log("Success delete toast");
      return true;
    })
    .catch(function(error) {
      // handle error
      return false;
    });

  return true;
}

function resetBeneficiaryCode(itemId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  let data = JSON.stringify({
    id: itemId
  });

  axios
    .post(`${apiUrl}/api/beneficiary/resetCode/${itemId}`, data, header)
    .then(response => {
      console.log(response.data);
      self.setState({ codeSentDone: true });
      self.setState({
        waitMessage: "Reset beneficiary code done."
      });
      return true;
    })
    .catch(function(error) {
      self.setState({ dataLoading: false });
      self.setState({ codeSentDone: true });
      self.setState({ errorMSG: "Reset code has not sent!" });
      return false;
    });

  return true;
}

function sendNewBeneficiaryCode(itemId, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };

  let data = JSON.stringify({
    id: itemId
  });

  axios
    .post(`${apiUrl}/api/beneficiary/sendNewCode/${itemId}`, data, header)
    .then(response => {
      self.setState({ codeSentDone: true });
      self.setState({
        waitMessage: "New beneficiary code sent successfully."
      });
      return true;
    })
    .catch(function(error) {
      // handle error
      return false;
    });

  return true;
}

function saveBeneficiaryOnBoard(self, data) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  return axios.post(
    `${apiUrl}/api/beneficiary/saveBeneficiaryOnBoard`,
    data,
    header
  );
}
