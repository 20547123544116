import { authHeader } from '../helpers/auth-header';
import axios from 'axios';
import apiService from './api.service';


 const  LifeStyleServices = {
    getLifeStyle,
    setLifeStyle
};

const apiUrl = apiService.apiUrl;

function getLifeStyle(self){
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/getLifeStyle`, {},header);
}

function setLifeStyle(self,data){
    let header = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: authHeader()
        }
    };
    return axios
        .post(`${apiUrl}/api/setLifeStyle`, data, header);
}


export default LifeStyleServices;
