exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UploadProgress_wrapper__3NTbt {\n  position: fixed;\n  bottom: 0;\n  right: 0;\n  background-color: white;\n  width: 400px;\n  overflow: scroll;\n  max-height: 400px;\n  border-top-left-radius: 8px;\n  border-top-right-radius: 8px;\n  z-index: 9999;\n  margin-top:auto;\n  margin-bottom:0px;\n}\n\n.UploadProgress_wrapper__3NTbt > h4 {\n  background-color: beige;\n  margin: 0;\n  padding: 20px;\n}\n\n.UploadProgress_closeButton__7vTyr {\n  position: absolute;\n  top: 18px;\n  right: 12px;\n  background: transparent;\n  border: unset;\n  font-size: 18px;\n  cursor: pointer;\n}\n\n.UploadProgress_closeButton__7vTyr:hover {\n  opacity: 0.5;\n}\n", ""]);

// exports
exports.locals = {
	"wrapper": "UploadProgress_wrapper__3NTbt",
	"closeButton": "UploadProgress_closeButton__7vTyr"
};