import axios, {CancelToken} from 'axios'
import uploadFileTypes from './uploadFile.types'
import apiService from "../../services/api.service";
import { authHeader } from "../../helpers/auth-header";
import { ToastsStore } from "react-toasts";

const apiUrl = apiService.apiUrl;
export const source = axios.CancelToken.source(); 

export const setUploadFile = (data, filesType) => ({
  type: uploadFileTypes.SET_UPLOAD_FILE,
  payload: data,
  filesType: filesType,
})

export const setUploadProgress = (id, progress) => ({
  type: uploadFileTypes.SET_UPLOAD_PROGRESS,
  payload: {
    id,
    progress,
  },
})

export const successUploadFile = id => ({
  type: uploadFileTypes.SUCCESS_UPLOAD_FILE,
  payload: id,
})

export const failureUploadFile = id => ({
  type: uploadFileTypes.FAILURE_UPLOAD_FILE,
  payload: id,
})

export const uploadFile = (files, self) => dispatch => {
  if (files.length) {
    const filesType = self.state.filesType
    const fileTitle = self.state.imageTitle
    const fileDescription = self.state.imageDescription
   
    files.forEach(async file => {

      try {
        if (filesType === "image") {
          const formPayload = new FormData()
          formPayload.append("filesType", "image");
          formPayload.append('fileTitle', fileTitle)
          formPayload.append('fileDescription', fileDescription)
          formPayload.append('imagesFiles[0]', file.file)

         
          // let newId = 0;
          await axios({
            baseURL: `${apiUrl}/api/image`,
            url: '/upload',
            method: 'post',
            data: formPayload,
            headers: {
              "Content-Type": "application/json",
              Authorization: authHeader(),
            },
            cancelToken: source.token,
            onUploadProgress: response => {
              const { loaded, total } = response

              const percentageProgress = Math.floor((loaded / total) * 100)
              dispatch(setUploadProgress(file.id, percentageProgress))

            }
          }).then(response => {
            let imageDataInfo = response.data.data;

            let prevImageList = self.state.imageLists;
            let newImageList = prevImageList.concat(imageDataInfo);
            self.setState({
              imageLists: newImageList,
            });
            self.setState({ uploadingData: true });
            self.setState({ modalphoto: false });

            ToastsStore.success("Image has been added successfully!");
            setTimeout(function () {
              //window.location.reload()
            }, 1000)
          }, error => {
            self.setState({ uploadingData: false });
          })
        } else if (filesType === "video") {
          
          const formPayload = new FormData()
          formPayload.append("videoType", "videoFile");
          formPayload.append('fileTitle', self.state.videoTitle)
          formPayload.append('fileDescription', self.state.videoDescription)
          formPayload.append('videos[0]', file.file)

          await axios({
            baseURL: `${apiUrl}/api/video`,
            url: '/upload',
            method: 'post',
            data: formPayload,
            headers: {
              //"Content-Type": "multipart/form-data",
              Authorization: authHeader(),
            },
            onUploadProgress: response => {
              const { loaded, total } = response
              const percentageProgress = Math.floor((loaded / total) * 100)
              dispatch(setUploadProgress(file.id, percentageProgress))
              
            },
            cancelToken: source.token
          }).then(response => {
            let videoDataInfo = response.data.data;
            let prevVideoLists = self.state.videoLists;
            let newVideoLists = prevVideoLists.concat(videoDataInfo);
            self.setState({
              videoLists: newVideoLists,
            });
            self.setState({ uploadingData: true });
            self.setState({ modalvideo: false });
            ToastsStore.success("Video has been added successfully!");
          }, error => {
            if (axios.isCancel(error)) {
              console.log('Request was canceled for reason:', error.message);
            } else {
              console.error('An error occurred:', error);
            }
          })
        } else if (filesType === "audio") {
          const formPayload = new FormData()
          formPayload.append("audioType", "audioFile");
          formPayload.append('fileTitle', self.state.recordTitle)
          formPayload.append('fileDescription', self.state.recordTitle)
          formPayload.append('audios[0]', file.file)

          await axios({
            baseURL: `${apiUrl}/api/record`,
            url: '/upload',
            method: 'post',
            data: formPayload,
            headers: {
              "Content-Type": "application/json",
              Authorization: authHeader(),
            },
            cancelToken: source.token,
            onUploadProgress: response => {
              const { loaded, total } = response
              const percentageProgress = Math.floor((loaded / total) * 100)
              dispatch(setUploadProgress(file.id, percentageProgress))

            }
          }).then(response => {
            console.log(response.data);
            let audioDataInfo = response.data.data;
            let prevRecordLists = self.state.recordLists;
            let newRecordLists = prevRecordLists.concat(audioDataInfo);
            self.setState({ recordLists: newRecordLists });
            self.setState({ modalrecord: false });
            self.setState({ uploadingData: false });

            ToastsStore.success("Audio has been added successfully!");
            console.log("response: ", self.state.recordLists);

          }, error => {
            self.setState({ uploadingData: false });
          })
        }

        dispatch(successUploadFile(file.id))
      } catch (error) {
        dispatch(failureUploadFile(file.id))
      }
    })
  }
}
