import React, { Component } from "react";
import "./styles/homeIndex.css";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import "bootstrap/dist/css/bootstrap.min.css";
import logoLight from "../pages/images/thisheartweb/logolight.png";

import IndexFooter from "./IndexFooter";

class AboutUs extends Component {
  render() {
    return (
      <div id="landing-home">
        <div id="page-top" data-spy="scroll" data-target=".navbar">
          <div id="header" className="fixed-top ">
            <div className="container">
              <nav className="navbar navbar-expand-md navbar-light main-nav ">
                <a className="navbar-brand" href="/">
                  <img src={logoLight} alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  id="navbarNavDropdown"
                  className="navbar-collapse collapse"
                >
                  <ul className="navbar-nav mr-auto" />
                  <ul className="navbar-nav list-collapse">
                    <li>
                      <a href="#aboutus">
                        About Us
                        <span className="sr-only" />
                      </a>
                    </li>

                    <li>
                      <a href="/login">
                        Log in
                        <span className="sr-only" />
                      </a>
                    </li>
                    <li>
                      <a href="/register">
                        Start for $0
                        <span className="sr-only" />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div id="aboutus" className="container ft-centered">
          <div className="row section-about">
            <div id="about-sec-text">
              <ScrollAnimation
                animateIn="fadeIn"
                style={{ animationDuration: "3s", opacity: "1" }}
              >
                <h2 className="abt-txt">About Us</h2>
                <span className="details-txt-abt">
                  The people that depend on you most will always need you. Leave
                  behind the things that matter the most to the people who
                  matter the most. From the memories you’ve shared, to every day
                  accounts; Thisheart lets you securely store all of your stuff
                  in one beautiful pleace where only the right people can access
                  it. Sign up today. The first 30 days are on us.
                </span>
              </ScrollAnimation>
            </div>
          </div>
        </div>
        <IndexFooter />
      </div>
    );
  }
}
export default AboutUs;
