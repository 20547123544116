import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/accessbeneficiary.css";
import { Helmet } from "react-helmet";
import logoImage from "../pages/images/logo-img.png";
import bnAccessBg from "../pages/images/bnaccessbg.jpeg";
import {
  Media,
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";

import { beneficiaryUserService } from "../services/beneficiary.user.service";

class ContributorsAccess extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");
    this.state = {
      accessCode: "",
      primaryUserId: 0,
      beneficiaryId: 0,
      messages: "",
      nocodeMessage: false,
      userObj: JSON.parse(user),
    };
  }

  handleChange = (e) => {
    this.setState({ accessCode: e.target.value });
  };

  submitBenefiCode = (e) => {
    e.preventDefault();
    let urlWithCode = window.location.href;
    beneficiaryUserService.validateContributorsAccessCode(
      this.state.accessCode,
      urlWithCode,
      this
    );
    console.log("submitBenefiCode URL COde:", urlWithCode);
  };

  toggleNoCode = () => {
    this.setState((prevState) => ({
      nocodeMessage: !prevState.nocodeMessage,
    }));
  };

  render() {
    return (
      <div className="home-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-Contributors Access</title>
        </Helmet>
        <div className="header-body">
          <Row>
            <Media src={bnAccessBg} alt="image" object className="body-image" />
            <Col sm="3">
              <Link to="/login">
                <Media className="home-logo-acc" left>
                  <Media object src={logoImage} alt="image" />
                </Media>
              </Link>
            </Col>
          </Row>
          <Row>
            <Col sm="4" className="invite-line">
              <Form method="post" onSubmit={this.submitBenefiCode}>
                <FormGroup>
                  <Label className="text">
                    Enter Invited Access Code
                    <Input
                      type="text"
                      name="beneficode"
                      id="beneficode"
                      required
                      onChange={this.handleChange}
                    />
                  </Label>
                  <Button className="btn-go" name="socialCode">
                    Go
                  </Button>
                  <div className="errormesgbene">{this.state.messages}</div>
                </FormGroup>
              </Form>
            </Col>
          </Row>
          <Row>
            <Col sm="4">
              <div className="textcode">
                <p className="nocodetext" onClick={this.toggleNoCode}>
                  No Code?
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div>
          <Modal
            isOpen={this.state.nocodeMessage}
            toggle={this.toggleNoCode}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleNoCode}>
              Access Invitaion
            </ModalHeader>

            <ModalBody className="form-popup">
              <b>You must be invited to access this feature</b>
            </ModalBody>
            <ModalFooter>
              <Button color="danger" type="button" onClick={this.toggleNoCode}>
                Close
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* <Footer {...this.props} /> */}
      </div>
    );
  }
}
export default ContributorsAccess;
