import React, { Component } from "react";
import { Link } from "react-router-dom";
import loaderImg from "./images/loader.gif";
import "./styles/accountprogress.css";
import { userService } from "../services/user.service";
import { beneficiaryUserService } from "../services/beneficiary.user.service";
import { FaCheckCircle, FaAngleRight, FaBan } from "react-icons/fa";
import Header from "./../components/Header";
import { encrypt_url } from "../helpers/encrypt-url";
import { Helmet } from "react-helmet";
import { Media, Col, Row, Button } from "reactstrap";

class AccountProgress extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.state = {
      memoriesDataExist: false,
      accountDataExist: false,
      benefeciaryDataExist: false,
      medicalDataExist: false,
      memoriesLoading: true,
      accountLoading: true,
      benificiaryLoading: true,
      medicalLoading: true,
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
      encryptedString: encrypt_url(),
    };
  }
  componentWillMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    userService.checkMemoriesData(userId, this);
    userService.checkAccountData(userId, this);
    userService.checkBeneficiaryData(userId, this);
    userService.checkMedicalData(userId, this);
  }
  componentDidMount() {
    if (this.state.userType === "bn") {
      this.props.history.push("/beneficiarylanding");
    }

    if (this.state.userType == "contributor") {
      this.props.history.push("/beneficiarylanding");
    }

    if (
      this.state.memoriesDataExist &&
      this.state.accountDataExist &&
      this.state.benefeciaryDataExist &&
      this.state.medicalDataExist
    ) {
      if (!this.state.userType === "bn") {
        this.props.history.push(`/dashboard/${this.state.encryptedString}`);
        return false
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  checkAllData = () => {
    let accProgressItem = [];
    if (
      this.state.memoriesDataExist &&
      this.state.accountDataExist &&
      this.state.benefeciaryDataExist &&
      this.state.medicalDataExist
    ) {
      this.props.history.push(`/dashboard/${this.state.encryptedString}`);
      return false
    } else {
      accProgressItem = (
        <div>
          <div className="progress-tab">
            <Row>
              <Col sm="12">
                <div className="head-text">
                  <h1>You're making progress!</h1>
                  <p>
                    You're off to a great start. Just a few more things and
                    You'll be in great shape.
                  </p>
                </div>
              </Col>
            </Row>

            <div className="container-fluid">
              <div className="left-item-pad">
                {this.showSectionStatus()}
                {this.showAddStatus()}
              </div>
              <div className="col-md-12 col-sm-12 d-block">
                <a
                  href={`/dashboard/${this.state.encryptedString}`}
                  className="bottom-text"
                >
                  Let's do this later <FaAngleRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return accProgressItem;
  };

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleLogout() {
    if (this.state.dropdownOpen) {
      if (this.state.userType === "bn") {
        beneficiaryUserService.logoutBeneficiaryUser(this.props);
      } else {
        userService.logout(this.props);
      }
    }
  }

  showSectionStatus() {
    let itemList = [];
    itemList = (
      <div className="row">
        <div className="col-md-3 col-lg-3 col-sm-6 ">
          {this.state.memoriesLoading ? (
            <Media src={loaderImg} alt="loaderImg" width="30" height="30" />
          ) : (
            <Button
              className={
                this.state.memoriesDataExist === false
                  ? "btn-sepred btn-memories-red"
                  : "btn-sepblue btn-memories-blue"
              }
            >
              {this.state.memoriesDataExist === false ? (
                <FaBan />
              ) : (
                <FaCheckCircle />
              )}{" "}
              Memories
            </Button>
          )}
        </div>
        <div className="col-md-3 col-lg-3 col-sm-6 ">
          {this.state.accountLoading ? (
            <Media src={loaderImg} alt="loaderImg" width="30" height="30" />
          ) : (
            <Button
              className={
                this.state.accountDataExist === false
                  ? "btn-sepred btn-account-red"
                  : "btn-sepblue btn-account-blue"
              }
            >
              {this.state.accountDataExist === false ? (
                <FaBan />
              ) : (
                <FaCheckCircle />
              )}{" "}
              Accounts
            </Button>
          )}
        </div>
        <div className="col-md-3 col-lg-3 col-sm-6 ">
          {this.state.benificiaryLoading ? (
            <Media src={loaderImg} alt="loaderImg" width="30" height="30" />
          ) : (
            <Button
              className={
                this.state.benefeciaryDataExist === false
                  ? "btn-sepred btn-benefi-red"
                  : "btn-sepblue btn-benefi-blue"
              }
            >
              {this.state.benefeciaryDataExist === false ? (
                <FaBan />
              ) : (
                <FaCheckCircle />
              )}{" "}
              Beneficiary
            </Button>
          )}
        </div>
        <div className="col-md-3 col-lg-3 col-sm-6 ">
          {this.state.medicalLoading ? (
            <Media src={loaderImg} alt="loaderImg" width="30" height="30" />
          ) : (
            <Button
              className={
                this.state.medicalDataExist === false
                  ? "btn-sepred btn-medical-red"
                  : "btn-sepblue btn-medical-blue"
              }
            >
              {this.state.medicalDataExist === false ? (
                <FaBan />
              ) : (
                <FaCheckCircle />
              )}{" "}
              MedicalHistory
            </Button>
          )}
        </div>
      </div>
    );
    return itemList;
  }

  showAddStatus() {
    let addItem = [];
    addItem = (
      <div className="row">
        <div className="col-md-3 col-sm-6 col-lg-3">
          {" "}
          {this.state.memoriesLoading === false ? (
            this.state.memoriesDataExist === false ? (
              <Link to={`/memories/${this.state.encryptedString}`}>
                <Button className="btn-style ">Add</Button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        <div className="col-md-3 col-sm-6 col-lg-3">
          {" "}
          {this.state.accountLoading === false ? (
            this.state.accountDataExist === false ? (
              <Link to={`/accounts/${this.state.encryptedString}`}>
                <Button className="btn-style">Add</Button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        <div className="col-md-3 col-sm-6 col-lg-3">
          {" "}
          {this.state.benificiaryLoading === false ? (
            this.state.benefeciaryDataExist === false ? (
              <Link to={`/beneficiaries/${this.state.encryptedString}`}>
                <Button className="btn-style">Add</Button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
        <div className="col-md-3 col-sm-6 col-lg-3">
          {" "}
          {this.state.medicalLoading === false ? (
            this.state.medicalDataExist === false ? (
              <Link to={`/medicalhistory/${this.state.encryptedString}`}>
                <Button className="btn-style">Add</Button>
              </Link>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </div>
      </div>
    );
    return addItem;
  }

  disableBeneficiaryLink = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Link
          className="link-style-beneficiaries"
          to={`/beneficiaries/${this.state.encryptedString}`}
        >
          Beneficiaries
        </Link>
      );
    }
  };
  render() {
    return (
      <div className="max-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-AccountProgress</title>
        </Helmet>
        <div className="header-bg">
          <Header {...this.props} />
        </div>

        {this.checkAllData()}
      </div>
    );
  }
}

export default AccountProgress;
