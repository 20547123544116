import React, { Component } from "react";
import loaderImg from "./images/loader.gif";
import { Link } from "react-router-dom";
import { Form, Button, FormGroup, Input,  Label, Row, Col, Media } from "reactstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/onboarding1verifysms.css";
import "react-responsive-ui/style.css";
import { decrypt_url, encrypt_url } from "../helpers/encrypt-url";
import { Helmet } from "react-helmet";
import OTPAuthService from "../services/otpauth.service";

class OnBoardingStepOneVerifyGoogle extends Component {
  constructor(props) {
    super(props);

    this.state = {
      authenticationcode: "",
      validauthcode: "1234567890",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      statusCode: "",
      userOBJ:"",
      encryptedString: encrypt_url('thisheart'),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    const qr_data = this.props.location.state;
  }

  componentDidMount() {
    const user = localStorage.getItem("user");
    this.setState({userOBJ:JSON.parse(user)})
  
    if (!user) {
      console.log("user didnt login yet......");
      this.props.history.push("/login");
      return false
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  }

    
  pageRedirectPrevious = () => {
    this.props.history.push("/onboard2fa");
    return false
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    const { authenticationcode } = this.state;

    if (authenticationcode) {
      this.setState({ loading: true });
      let otp_method = "googleauth";
     
      OTPAuthService.verifyOTP(authenticationcode, otp_method, this).then(
        (response) => {
          
          let responseData = response.data;
          if (responseData.status === "success") {
            localStorage.setItem("twofa", true);
            if (this.state.userOBJ.data.user_type === "beneficiary") {
              //this.props.history.push("/beneficiarylanding"+'/'+this.state.encryptedString);
              this.props.history.push("/onboardsocial");
              return 
            } else {
              // this.props.history.push("/accounts");
              // this.props.history.push("/AccountProgress");
              this.props.history.push("/onboardsocial");
              return 
            }
          } else {
           
            this.setState({
              errorStatus: responseData.status,
              errorMessage: responseData.message,
              loading: false,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  authenticateValidation() {
    if (this.state.submitted && !this.state.authenticationcode) {
      return <div className="help-block">Authentication code is required</div>;
    } else if (this.state.submitted) {
      return <div className="help-block">{this.state.errorMessage}</div>;
    }
    return <div className="help-block" />;
  }

  render() {
    return (
      <div className="container-fluid onboarding-one-verify-google">

        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - OnBoarding TWO FA Verify</title>
        </Helmet>
        <div className="row">
          {/*<!--end row one-->*/}
          <div className="container image-step">
            <Row>
              <Col sm="6" className="px-4 mx-3">
                <h4 className="heading"> Two Factor Authentication Verify</h4>
                <p className="parag">
                  <b>
                    Two factor authentication (2FA) Secure Your ThisHeart
                    Account
                  </b>{" "}
                  Because stronger passwords alone aren't enough to protect your
                  account from password breaches. Two factor authentication
                  protects against phishing and password brute force attacks.
                </p>
                <p className="secret-text">
                  Secret Key is Generated, Please Verify the Secret Code to
                  Enable Two Factor
                </p>
                <p className="fact-list-verify">
                  <strong>
                    1. Scan this barcode <span className="dontShow"> with your Google Authenticator
                    Application</span>
                  </strong>
                </p>
                <div className="item-container">
                  <Media
                    src={this.props.location.state?this.props.location.state.qr_url:''}
                    object
                    alt="QRCodeScan"
                    className="scan-image"
                  />
                </div>
                <p className="fact-list-verify">
                  <strong>
                    2. Please check your email <span className="dontShow"> and verify the OTP Google Authenticator
                      For the Application</span>
                  </strong>
                </p>
                <Form className="register-form" onSubmit={this.handleSubmit}>
                  <FormGroup>
                    <Label className="lbl-authcode">Authenticate Code</Label>
                    <Input
                      type="text"
                      className="input-register"
                      name="authenticationcode"
                      id="authenticationcode"
                      onChange={this.handleChange}
                    />
                  </FormGroup>
                  {this.authenticateValidation()}

                  <div>
                    {this.state.loading && (
                      <img
                        className="loader-img"
                        alt="loaderImg"
                        src={loaderImg}
                      />
                    )}
                    <Button className="btn-submit-verify ">Submit 2FA</Button>
                  </div>
                </Form>
              </Col>
            </Row>

            <Row>
              <Col md={2}>
                <div className="prev-next ml-5">
                  <Button  className="mr-1"  onClick={this.pageRedirectPrevious}  style={{borderRadius:"7px"}} >
                    <Link style={{color:"black"}} to="/?">&laquo; Prev</Link>
                  </Button>
                  
                </div>
              </Col>
            </Row>
            {/* <p className="skip-p">
				<Link to="/onboardsocial">Skip</Link>
			</p> */}
          </div>
          {/*** end image-step ****/}
        </div>
        {/*<!--end row one-->*/}
      </div>
    );
  }
}

export default OnBoardingStepOneVerifyGoogle;
