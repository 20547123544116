import React, { Component } from "react";
import { Media, Col, Row } from "reactstrap";

import { Link } from "react-router-dom";
import "./styles/login.css";
import logoImage from "./images/logo-img.png";
import Cryptr from "cryptr";
import regComplete from "./images/regcomplete.png";
import { Helmet } from "react-helmet";
import { encrypt_url, decrypt_url} from '../helpers/encrypt-url';

class RegisterEmailConfirmation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // email: "",
      // submitted: false,
      // loading: false,
      // requestFailed: false,
      // serverMessage: "",
      // statusCode: "",
    };

  
    
  }

  componentDidMount() {
    //const url_data =  decodeURIComponent(this.props.match.params.code);
    //console.log('decrypt_url-:-', decrypt_url(url_data))
    return false
    // if (url_data) {
    //   const reg_email = localStorage.getItem("reg_email");
      

    //   if (reg_email === url_data) {
         
    //   } else {
    //     this.props.history.push("/login");
         
    //   }
    // }
  }
 



  renderBody() {
    if (this.state.submitted && this.state.statusCode === 200) {
      return (
        <Row>
          <Col sm="12">
            <Media left className="logo-image-forgot-pass">
              <Media object src={logoImage} alt="image" />
            </Media>
            <div className="send-password-link text-muted text-center">
              <strong>{this.state.serverMessage}</strong>
            </div>
            <Link
              className="sign-in-from-here text-muted text-center"
              to="/login"
            >
              {"<< "} Back to sign-in page
            </Link>
          </Col>
        </Row>
      );
    }

    return (
      <div className="container-fluid">
        <div className="row  margin-all">
          <div className="col-md-6 col-sm-12 col-lg-4  justify-content-center align-items-center">
            <div className="logo-image-beneficiary">
              <Media object src={logoImage} alt="image" className="img-fluid" />
            </div>
            <div className=" ">
              <h3 className="head-reg">
                Kinda like the first<br />
                day of school, huh?
              </h3>

              <p className="p-reg">
                <b>Everything’s new; you don't know where to go, and you don't know anyone’s name.
                </b>{" "}
                <br />
              </p>
              <p>
                Well, we’re ThisHeart, and we’re going to help you <br /> find your
                way around. <br />
              </p>
              <p>
                To start, <b>head over to your email and verify your email.</b>
                We’ll take it from there. <br />

              </p>
              <p> Welcome!</p>
              <h4 className="confirmation">Confirmation Email</h4>
            </div>

          </div>
          <div className="col-md-6  col-lg-8 justify-content-center align-items-center mt-4 display-image">
            <img src={regComplete} alt="regimage" className="img-fluid "></img>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="login-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Reg. Email Confirm</title>
        </Helmet>
        {this.renderBody()}
      </div>
    );
  }
}

export default RegisterEmailConfirmation;
