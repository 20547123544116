import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { size, toArray } from 'lodash'
import $ from 'jquery';
import axios, {CancelToken} from 'axios'
import { uploadFile, source } from '../../redux/uploadFile/uploadFile.actions'
import UploadItem from '../UploadItem/UploadItem'
import Styles from './UploadProgress.module.css'

 
 
const UploadProgress = props => {
  let { fileProgress, uploadFile } = props
  const uploadedFileAmount = size(fileProgress)
  //let stateCall = props.memoriesState 
  const dvProgress = document.getElementById('divProgressBar');
  if(dvProgress){
    document.getElementById('divProgressBar').style.display="block";
  }  

  useEffect(() => {
    const fileToUpload = toArray(fileProgress).filter(file => file.progress === 0)
    uploadFile(fileToUpload)
  }, [uploadedFileAmount])

  // Use a flag to manage the visibility of the progress bar
  let showProgressbar = true;

  // This function hides the progress bar
  const hideProgress = () => {
    showProgressbar = false;
    // Use jQuery to remove the progress bar element, if you're using jQuery
    $(".UploadItem_wrapperItem__1reyw, .UploadItem_progressBar__22V_I").remove();
    document.getElementById('divProgressBar').style.display = "none";
  };


    $('#divProgressBar').on('click',function(){
      hideProgressUpload();
       
      setTimeout(function(){
        window.location.reload();
      },1500)
    })

    // This function hides the progress bar
    const hideProgressUpload = () => {
      showProgressbar = false;
      source.cancel('Operation canceled by the user.');
      $(".UploadItem_wrapperItem__1reyw, .UploadItem_progressBar__22V_I").remove();
      document.getElementById('divProgressBar').style.display = "none";
    };

 
  const showProgress = (immediate = false) => {
    const delay = immediate ? 0 : Math.random() * (3000 - 1000) + 1000; // Choose a random delay between 10 to 15 seconds
    setTimeout(hideProgress, delay);
  };

  // This function checks the progress and decides whether to close the progress bar
  const checkAndCloseProgress = (fileProgress) => {
    const allFilesUploaded = toArray(fileProgress).every(file => file.progress === 100);
    if (allFilesUploaded) {
      showProgress(); // Call with no arguments for automatic delay
    }
  };

  // Close button now calls showProgress with true to hide immediately
  const closeButton = (
    <button type="button" className="close" aria-label="Close" onClick={() => showProgress(true)}>
      <span aria-hidden="true">×</span>
    </button>
  );
  if (uploadedFileAmount <= 0) {
    return null; // Return null if there's nothing to render
  }
  // Conditionally render the progress bar based on the `showProgressbar` flag
  return uploadedFileAmount > 0 ? (
    <div className={Styles.wrapper + ' divProgressBar'} style={{ display: showProgressbar ? '' : 'none' }} id="divProgressBar">
      <h4>
        Uploading File
        {closeButton}
      </h4>
      {size(fileProgress)
        ? toArray(fileProgress).map(file => {
          checkAndCloseProgress(fileProgress); // Check if all files are uploaded
          return <UploadItem key={file.id} file={file} />;
        })
        : null}
    </div>
  ) : null;
}

const mapStateToProps = state => ({
  fileProgress: state.UploadFile.fileProgress,
})

const mapDispatchToProps = (dispatch, props) => ({
  uploadFile: files => dispatch(uploadFile(files, props.memoriesState)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress)
