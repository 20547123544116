
export function authHeader() {
  // return authorization header with basic auth credentials

  
 
  const auth_expires = localStorage.getItem('auth_expires');
	const now = new Date();
	const expires = new Date(auth_expires);
	let expired = false;

	if(expires.valueOf()>=now.valueOf()){
    let new_expires = new Date();
    new_expires.setMinutes(now.getMinutes()+30);
    localStorage.setItem('auth_expires',new_expires);
		expired = false
	}else{
    localStorage.clear()
		expired = true
  }
  //console.log(expired)
  let user = JSON.parse(localStorage.getItem("user"));
  if (user && user.data) {

    return "Bearer " + user.data.access_token;
  } else {
    return {};
  }
}
