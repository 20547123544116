import React, { Component } from "react";
import lineOfColours from "./images/line_of_colours.png";
import {
  Media,
  Col,
  Row,
  Form,
  Button,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
// import { Link } from "react-router-dom";
import loaderImg from "./images/loader.gif";
import OTPAuthService from "./../services/otpauth.service";
import PhoneInput from "react-phone-number-input";

import "./styles/enable2fa.css";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import { encrypt_url } from "../helpers/encrypt-url";
import LeftSidebar from "../components/LeftSidebar";

class ResetTwoFactorAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedStep: "email",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      statusCode: "",
      errorMobile: "",
      mobile: "",
      encryptedString: encrypt_url(),
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.disableTwoFA = this.disableTwoFA.bind(this);
  }

  handleChange = (e) => {
    let { value } = e.target;
    this.setState({ selectedStep: value });
    this.setState({ submitted: false });
  };

  handleMobile = (e) => {
    let { value } = e.target;
    console.log("value select:", value);
    this.setState({ mobile: value });
    this.setState({ submitted: false });
  };

  handleSubmit(e) {
    e.preventDefault();

    let { selectedStep, mobile } = this.state;

    if (selectedStep === "sms" && this.state.mobile === "") {
      this.setState({ errorMobile: "Please input mobile number!" });
      return false;
    }

    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    if (selectedStep) {
      this.setState({ loading: true });
      OTPAuthService.resetGenerateOTP(selectedStep, mobile, this).then(
        (response) => {
          let responseData = response.data;
          if (responseData.status === "success") {
            if (
              responseData.method === "sms" ||
              responseData.method === "email"
            ) {
              this.props.history.push("/verifysettingstwofa");
            } else if (responseData.method === "googleauth") {
              this.props.history.push({
                pathname: "/verifyqrcode",
                state: { qr_url: responseData.data },
              });
            }
          } else {
            this.setState({
              loading: false,
            });
          }
        },
        (error) => {
          let errorMsgTmp = "";
          let errorMsg = error.response.data.message.split(":");
          if (
            errorMsg[2] ===
            " 1062 Duplicate entry '+8801905793801' for key 'users_mobile_unique' (SQL"
          ) {
            errorMsgTmp = "Mobile number is exists already!";
          } else if (
            errorMsg[1] ===
            " The 'To' number +88019057938012 is not a valid phone number."
          ) {
            errorMsgTmp = "Mobile number is invalid!";
          }

          this.setState({
            errorMessage: errorMsgTmp,
            loading: false,
          });
        }
      );
    }
  }

  disableTwoFA = () => {
    this.setState({ loading: true });
    OTPAuthService.disableOTP(this).then(
      (response) => {
        let responseData = response.data;
        if (responseData.status === "success") {
          const user_type = localStorage.getItem("user_type");
          if (user_type === "bn") {
            this.props.history.push(
              `/beneficiarylanding/${this.state.encryptedString}`
            );
          } else if (user_type === "primary") {
            this.props.history.push(`/dashboard/${this.state.encryptedString}`);
            return false
          }
          return false;
        } else {
          this.setState({
            loading: false,
            errorMessage: "Sorry, unable to disable Two Factor Auth.",
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  twoFactorProcess = () => {
    let optionSelect = "";
    optionSelect = (
      <div className="option-list">
        <div className="d-flex">
          <FormGroup check>
            <Label check className="lbl-method">
              <b>Email</b>
              <Input
                type="radio"
                name="radio"
                value="email"
                id="email"
                onChange={this.handleChange}
                defaultChecked
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check className="lbl-method">
              <b>Google Authenticator</b>
              <Input
                type="radio"
                name="radio"
                value="googleauth"
                id="googleauth"
                onChange={this.handleChange}
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check className="lbl-method">
              <b>SMS</b>
              <Input
                type="radio"
                name="radio"
                value="sms"
                id="sms"
                onChange={this.handleChange}
              />{" "}
              <span className="checkmark" />
            </Label>
          </FormGroup>
        </div>

        <hr />
        {this.state.selectedStep === "sms" && (
          <FormGroup>
            <Label for="mobile" className="mbl-pad" style={{ color: "#b81bbd" }}>
              <b>Mobile</b>
            </Label>
            <div className="input-group">
              <PhoneInput
                name="mobile"
                id="mobile"
                className="form-control react-phone-number-input"
                placeholder="Enter your mobile number"
                onChange={(mobile) => {
                  this.setState({ mobile });
                  this.setState({ errorMobile: "" });
                }}
              />
            </div>
            <div className="help-block" style={{ marginTop: "15px" }}>
              {this.state.errorMobile}
            </div>
          </FormGroup>

        )}
      </div>
    );
    return optionSelect;
  };

  handleSkipButton = () => {
    const user_type = localStorage.getItem("user_type");
    if (user_type === "bn") {
      this.props.history.push(
        `/beneficiarylanding/${this.state.encryptedString}`
      );
    } else if (user_type === "primary") {
      this.props.history.push(`/dashboard/${this.state.encryptedString}`);
      return false
    }
  };

  render() {
    return (
      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin1" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">

        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Enroll Authentication</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container mt-3">
            <div className="enable-2fa-card">
              <div className="mr-3 text-break" style={{ width: "100%" }}>
                <h3 className="mr-2 w-100 text-break" style={{ width: "100%" }}>Enable Two Factor Authentication</h3>
                {/* <img style={{height:"5px", margin:"auto 0", paddingLeft:"10px"}} src={lineOfColours} alt="line of colours" /> */}
                {/* <img src={lineOfColours} alt="line of colours" /> */}
              </div>
              <p>Two-factor authentication (2FA): Secure Your ThisHeart Account Because
                stronger passwords alone aren't enough to protect your account from password
                breaches. Two-factor authentication protects against phishing, social engineering,
                and password brute force. attacks and secures your account from attackers exploiting
                weak or stolen credentials.</p>
              <p>To <span style={{ fontWeight: "500", color: "#b81bbd" }}>Enable Two-Factor Authentication</span> on your account, login through any one of the following:</p>
              {/* More instructions or content as per your design */}
              <Form onSubmit={this.handleSubmit}>
                <FormGroup id="2faInput">
                  {this.twoFactorProcess()}
                </FormGroup>
                <div className="help-block-login ml-0">{this.state.errorMessage}</div>
                <div>
                  {this.state.loading && (
                    <img className="loader-img" alt="loaderImg" src={loaderImg} />
                  )}
                </div>
                <div>
                  <Button className="btn" style={{background:"#ffd139", border:"0px", borderRadius: "5px" }}>Enable 2FA</Button>
                  {/* <Button className="ml-2 btn btn-danger" onClick={this.disableTwoFA} > Disable 2FA</Button> */}
                  <Button className="btn-skip" onClick={this.handleSkipButton}>Skip</Button>
                </div>
              </Form>
            </div>

          </div>
        </div>
        {/* <Row>
          <Col sm={{ offset: 2, size: 8 }}>
            <Media left className="logo-image">
              <Media object src={logoImage} alt="image" />
            </Media>

            

            <Form className="register-form" onSubmit={this.handleSubmit}>
              {this.twoFactorProcess()}

              <div>
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}
                <div className="text-center help-block mt-4">
                  {this.state.errorMessage}
                </div>

                <Row className="mt-5">
                  <Col lg="6" className="text-center">
                    <Button
                      color="info"
                      type="button"
                      className="bt-disable"
                      onClick={this.disableTwoFA}
                    >
                      Disable 2FA
                    </Button>
                  </Col>
                  <Col lg="6" className="text-center">
                    <Button color="success" className="bt-enable">
                      Enable 2FA
                    </Button>
                  </Col>
                </Row>
              </div>
              <div>
                <Button
                  className="pull-right btn-skip-set"
                  onClick={this.handleSkipButton}
                >
                  Skip
                </Button>
              </div>
            </Form>
          </Col>
        </Row> */}
      </div>
    );
  }
}

export default ResetTwoFactorAuth;
