import React, { Component } from "react";
import {
  Media,
  Col,
  Row,
  Form,
  Button,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import { Link } from "react-router-dom";
import logoImage from "./images/logo-img.png";
import loaderImg from "./images/loader.gif";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import "./styles/disable2fa.css";
import { Helmet } from "react-helmet";

class DisableTwoFactorAuth extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      currentPassword: "1234567890",
      passwordVisible: "password",
      successMessage: "2FA is currently Enabled for your account.",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      statusCode: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  };

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    const { password } = this.state;

    if (password) {
      this.setState({ loading: true });
      alert("Disable 2 FA");
    }
  }

  passwordValidation() {
    if (this.state.submitted && !this.state.password) {
      return <div className="help-block">Password is required</div>;
    } else if (
      this.state.submitted &&
      this.state.password !== this.state.currentPassword
    ) {
      return <div className="help-block">Password does not match</div>;
    }

    return <div className="help-block" />;
  }

  eyeIcon = (regPass) => {
    if (regPass) {
      if (this.state.passwordVisible === "password") {
        this.setState({ passwordVisible: "text" });
      } else {
        this.setState({ passwordVisible: "password" });
      }
    }
  };

  render() {
    return (
      <div className="register-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-Disable Two Factor Auth</title>
        </Helmet>
        <Row>
          <Col sm={{ offset: 2, size: 8 }}>
            <Media left className="logo-image">
              <Media object src={logoImage} alt="image" />
            </Media>

            <h3 className="heading">Two Factor Authentication</h3>
            <p className="parag">
              <b>Two factor authentication (2FA)</b> strengthens access security
              by requiring two methods (also referred to as factors) to verify
              your identity. Two factor authentication protects against phising,
              social engineering and password brute force attacks and secures
              your logins from attackers exploiting weak or stolen credentials.
            </p>

            <div className="active-message">
              <p className="higlight">{this.state.successMessage}</p>
            </div>
            <p className="parag">
              If you are looking to{" "}
              <span className="disable-text">disable</span> Two Factor
              Authentication. Please confirm your current password and click
              Disable 2FA
            </p>

            <Form className="register-form" onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label className="lbl-register">Password</Label>
                <Input
                  type={this.state.passwordVisible}
                  className="input-register"
                  name="password"
                  id="userPassword"
                  onChange={this.handleChange}
                  placeholder="Password"
                />
                <span
                  className="field-icon-reg"
                  onClick={this.eyeIcon.bind(this, "regPassword")}
                >
                  {this.state.passwordVisible === "password" ? (
                    <FaEyeSlash />
                  ) : (
                    <FaEye />
                  )}
                </span>
              </FormGroup>
              {this.passwordValidation()}

              <div>
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}

                <Button className="btn-enable">Disable 2FA</Button>
              </div>
              <div>
                <Link to={`/dashboard/${this.state.encryptedString}`}>
                  <Button className="btn-skip">Skip</Button>
                </Link>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

export default DisableTwoFactorAuth;
