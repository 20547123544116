//import Cryptr from 'cryptr';
import CryptoJS from "crypto-js";

// export function encrypt_url(){
//     let cryptObj = new cryptr("thisheart");
//     let encryptedString = cryptObj.encrypt('thisheart')
//     return encryptedString;
  
// }

 
const crypto = require('crypto');
//const crypto = new Cryptr('thisheart');
const iv = crypto.randomBytes(16);
const algorithm = 'aes-256-cbc';
const secretKey = crypto.randomBytes(32);  
 

export function encrypt_url(url) {
    const encryptedURL = CryptoJS.AES.encrypt(url,"thisheart").toString();
    //console.log('encrypt encryptedURL key::', encryptedURL)
    return encodeURIComponent(encryptedURL);
    //const encodeString = crypto.encrypt(url);
//  let cipher = crypto.createCipheriv('aes-256-cbc', Buffer.from(key), iv);
//  let encrypted = cipher.update(url);
//  encrypted = Buffer.concat([encrypted, cipher.final()]);
//  const encodeString = iv.toString('hex') +'###'+ encrypted.toString('hex') ;

    const cipher = crypto.createCipheriv(algorithm, Buffer.from(secretKey), iv);
    let encrypted = cipher.update(url);
    encrypted = Buffer.concat([encrypted, cipher.final()]);
    // return { iv: iv.toString('hex'), encryptedData: encrypted.toString('hex') };
    return encrypted.toString('hex');
}

export function decrypt_url(text) {
 
    const decryptData = CryptoJS.AES.decrypt(decodeURIComponent(text) , "thisheart")
    const decryptedSTR = decryptData.toString(CryptoJS.enc.Utf8);
    //console.log('decrypt data key::', decryptedSTR)

    return decryptedSTR;
    let ivs = Buffer.from(iv.toString('hex'), 'hex');
    let encryptedText = Buffer.from(text, 'hex');
    const decipher = crypto.createDecipheriv(algorithm, Buffer.from(secretKey), ivs);
    let decrypted = decipher.update(encryptedText);
    decrypted = Buffer.concat([decrypted, decipher.final()]);
    return decrypted.toString();

    const decryptStr = crypto.decrypt(text);
    return decryptStr;
//  let encryptStr = text.split('###')
//  let iv = Buffer.from(encryptStr[0], 'hex');
//  let encryptedText = Buffer.from(encryptStr[1], 'hex');
//  let decipher = crypto.createDecipheriv('aes-256-cbc', Buffer.from(key), iv);
//  let decrypted = decipher.update(encryptedText);
//  decrypted = Buffer.concat([decrypted, decipher.final()]);
//  //console.log("decrypt string: ", decrypted.toString());
 //return decrypted.toString();
}


export function generateKey() {
    const key =  window.crypto.subtle.generateKey(
      {
        name: "AES-GCM",
        length: 256,
      },
      true,
      ["encrypt", "decrypt"]
    );
    return key;
  }

  
  export function encryptData(plainText, key) {
    const iv = window.crypto.getRandomValues(new Uint8Array(12)); // Initialization vector
    const encoder = new TextEncoder();
    const data = encoder.encode(plainText);
  
    const encrypted = window.crypto.subtle.encrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      data
    );
  
    return {
      encryptedData: encrypted,
      iv: iv,
    };
  }


  export  function decryptData(encryptedData, key, iv) {
    const decrypted = window.crypto.subtle.decrypt(
      {
        name: "AES-GCM",
        iv: iv,
      },
      key,
      encryptedData
    );
  
    const decoder = new TextDecoder();
    return decoder.decode(decrypted);
  }
  
  

