import React, { Component } from "react";
import paymentImage from "../pages/images/payment-success.jpg";
import {
  FaTimesCircle,
} from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import "../components/asterisk.css";
import "./styles/PackageSubscription.css";
import { Helmet } from "react-helmet";

// import Header from "./../components/Header";

import {
  Col,
  Row,
  Button,
} from "reactstrap";
import "./styles/accounts.css";

import { encrypt_url } from "./../helpers/encrypt-url";

class PaymentCancel extends Component {
  constructor(props) {
    super(props);

    //var stripe = Stripe('pk_test_9muBAZnjHeFvBwxhQO8k145F00GXiohNqD');
    let user = localStorage.getItem("user");

    this.state = {
      userObj: JSON.parse(user),
      userPackage: "",
      allPackages: "",
      selectPackageView: false,
      selectedPackage: "",
      encryptedString: encrypt_url(),
      paymentSession: "",
    };
  }

  componentDidMount() {}

  componentWillUnmount() {
    // this.mounted = false;
  }

  handleBackBtn = (e) => {
    this.props.history.push(
      `/packagepayment/${this.state.encryptedString}`
    );
  };

  bodyPackageContent = () => {
    return (
      <div>
        <Row className="mr-3">
          <Col sm={12}>
            <div className="card">
              <div className="card-header">Package Payment</div>
              <div className="card-body">
                {/* <h1 className="text-center text-success" style={{fontSize:'6rem'}}>
                  <FaCheckCircle />
                </h1> */}
                <Row>
                  <Col sm={6} className="text-center mt-4 text-danger">
                    <h1 className="text-center mt-4 text-danger">
                      <FaTimesCircle />
                    </h1>
                    <h3 className=" text-danger mt-5">Payment Canceled</h3>
                    {/* <p className=" text-muted mt-3">It is a long established fact that a reader will be distracted by 
                        the readable content of a page when looking at its layout. The point 
                        of using Lorem Ipsum is that it has a more-or-less normal distribution 
                        of letters, as opposed to using 'Content here, content here',</p> */}
                    <Button
                      className="btn btn-danger mt-4"
                      onClick={this.handleBackBtn}
                    >
                      Subscribe again
                    </Button>
                  </Col>

                  <Col sm={6} className="text-center">
                    <img
                      src={paymentImage}
                      alt="Payment Success"
                      width="99%"
                      height="auto"
                    />
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
          <Col sm={5}></Col>
        </Row>
      </div>
    );
  };

  render() {
    return (
      <div className="account-container package-subscription">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Payment Cancel</title>
        </Helmet>
        {/* <Header {...this.props} /> */}
        <div>
          <Row className=" mt-4">
            <Col sm="8" className="account-name">
              <h1 className="account-header mt-5 pt-1">Payment Status</h1>
              {/* <p>
                Nunc vel varius tortor. Quisque turpis enim, iaculis id nisi
                quis, consectetur cursus velit. Vivamus in tortor vehicula.
              </p> */}
            </Col>
          </Row>
        </div>

        <div className="contain-body">
          <Row>
            <Col sm={1}>{/*this.showSideMenu()*/}</Col>
            <Col sm={10}>{this.bodyPackageContent()}</Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default PaymentCancel;
