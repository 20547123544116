import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";

import "react-responsive-ui/style.css";
import "./styles/passwordmanage.css";
import "./styles/lifestatus.css";
import { Helmet } from "react-helmet";
import StepServices from "../services/steps.service";
import LifeStyleServices from "../services/lifeStyle.service";
import loaderImg from "./images/loader.gif";
import { encrypt_url } from "../helpers/encrypt-url";
import { ToastsStore, ToastsContainer } from "react-toasts";

class OnBoardingLifeStatus extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.state = {
      selectedStep: "Just Getting Started",
      selectedStep: true,
      selectedStepId: 1,
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      submitted: false,
      lifeStyle: [],
      loading: false,
      encryptedString: encrypt_url(),
      error: null,
    };
  }

  componentDidMount() {
    this.getLifeStyle();
  }

  handleChange = (e) => {
    let { value } = e.target;
    this.setState({ selectedStep: value });
    this.setState({ submitted: false });
  };

  handleSubmit = (e) => {
    console.log("submit working");
    e.preventDefault();
    this.setState({
      submitted: true,
      loading: true,
    });

    let { selectedStep } = this.state;
    if (selectedStep) {
      console.log("selected step name is:", selectedStep);
      LifeStyleServices.setLifeStyle(this, { lifeStyle: selectedStep }).then(
        (response) => {
          console.log(response);
          this.setState({
            loading: false,
          });
          ToastsStore.success("Successfully, Life status has been added!");
        },
        (error) => {
          this.setState({
            loading: false,
          });
          ToastsStore.error("Sorry, Life status hasn't been added !");
        }
      );
    }
  };

  getLifeStyle = () => {
    LifeStyleServices.getLifeStyle(this).then(
      (response) => {
        console.log(response);
        if (response.data.status === "success") {
          console.log('getLifeStyle:::-', response.data.data);
          this.setState({
            lifeStyle: response.data.data,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  pageRedirectPrevious = () => {
    this.props.history.push("/onboardpass_manage");
  };

  pageRedirectNext = () => {
    let data = {
      step: "step-04",
      info: "life status",
    };
    StepServices.setSteps(this, data).then(
      (response) => {
        console.log(response);
        this.setState({
          loading: false,
        });
        this.props.history.push("/onboardbenefi_select");
      },
      (error) => {
        console.log(error);
        this.setState({
          loading: false,
        });
      }
    );
    return false;
  };

  showLifeStatus = () => {
    return (
      <FormGroup>
        <Label for="lifeStatusSelect">Select Life Status</Label>
        <Input type="select" name="lifeStatus" id="lifeStatusSelect" onChange={this.handleChange} value={this.state.selectedStep}>
          {this.state.lifeStyle.map((style, index) => (
            <option key={index} value={style.id}>{style.life_style}</option>
          ))}
        </Input>
      </FormGroup>
    );
  };

  // showLifeStatus = () => {
  //   let optionSelect = "";
  //   optionSelect = (
  //     <div className="option-status">
  //       {this.state.lifeStyle.map((style, index) => {
  //         return (
  //           <FormGroup key={index} check>
  //             <Label check className="lbl-sts switch-off">
  //               <b>{style.life_style}</b>
  //               <Input
  //                 type="radio"
  //                 name="radio"
  //                 value={style.id}
  //                 id={style.id}
  //                 onClick={this.handleChange}
  //                 defaultChecked={index === 0 ? true : false}
  //               />
  //               <span className="checkmark" />
  //             </Label>
  //           </FormGroup>
  //         );
  //       })}
  //     </div>
  //   );
  //   return optionSelect;
  // };

  render() {
    return (
      <div className="container-fluid life-container ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - OnBoarding Life Status</title>
        </Helmet>
        <div className="row">
          <div className="container image-step-four">
            <div className="col-sm-12 col-md-5 col-xs-6 text-pass">
              <h4>LIFE STATUS</h4>
              <p>
                We’re all different and have different life statuses and family
                make ups. Let us know what yours is so we can help customize
                your experience.
              </p>
            </div>
            <div className="col-md-5 col-sm-6 col-xs-12 life-status">
              <Form className="status-form" onSubmit={this.handleSubmit}>
                {this.showLifeStatus()}
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}
                <div className="setblock">
                  <Button className="life-status-save">Save</Button>
                </div>
              </Form>
            </div>
            <div className="row">
            <div className="col-sm-1 col-md-1 col-xs-1"></div>
              <div className="col-sm-9 col-md-9 col-xs-9">
                <div className="prev-next-pass">
                  <Button
                    style={{ borderRadius: "7px", padding: "10px 20px", color: "#000", fontWeight: "500" }}
                    className=""
                    onClick={this.pageRedirectPrevious}
                  >
                    <i class="fa fa-solid fa-arrow-left mr-1"></i> Prev
                  </Button>
                  <Button className="ml-3" style={{ borderRadius: "7px", padding: "10px 20px", color: "#000", fontWeight: "500" }} onClick={this.pageRedirectNext}>
                    Next <i class="fa fa-solid fa-arrow-right mr-1"></i>
                  </Button>
                </div>
              </div>
            </div>

            <div className="pass-skip">
              <p className="mbn-5">
                <Link
                  style={{ borderRadius: "7px", padding: "10px 20px", color: "#000", fontWeight: "500" }}
                  to={`/dashboard/${this.state.encryptedString}`}
                  className="btn btn-secondary"
                >
                  {localStorage.setItem("twofa", true)}
                  Skip
                </Link>
              </p>
            </div>
          </div>
        </div>

        {/* <Footer {...this.props} /> */}
        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

export default OnBoardingLifeStatus;
