/* eslint-disable no-lone-blocks */
import React, { Component } from "react";
import loaderImg from "./images/loader.gif";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "./styles/memories.css";
import { Helmet } from "react-helmet";

import Header from "./../components/Header";
// import Footer from "./../components/Footer";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { Col, Row } from "reactstrap";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

class TwitterCallback extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "1",
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div className="home-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title> ThisHeart - Twitter </title>
          <link rel="memories" href="http://thisheart.com/memories" />
        </Helmet>
        <Header {...this.props} />
        <Row>
          <Col md="12" className="text-center">
            <img src={loaderImg} height="65" width="auto" alt="" />
          </Col>
        </Row>
        {/* <Footer {...this.props} /> */}
      </div>
    );
  }
}

export default TwitterCallback;
