import React, { Component } from "react";
import { Form } from "reactstrap";
import { ToastsContainer, ToastsStore } from "react-toasts";
import "./styles/signin.css";
import "./styles/freeaccountlogin.css";
import { userService } from "../services/user.service";
import logoImage from "./images/adminlogo.png";
import { Helmet } from "react-helmet";
import { encrypt_url } from "../helpers/encrypt-url";

class FreeAccLogin extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);

    this.state = {
      email: "",
      password: "",
      submitted: false,
      loading: false,
      loginFailed: false,
      errorMessage: "",
      statusCode: "",
      btnActive: false,
      encryptedString: encrypt_url(),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (this.state.email.length > 2 && this.state.password.length > 0) {
      this.setState({ btnActive: true });
    } else {
      this.setState({ btnActive: false });
    }
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      loginFailed: false,
      statusCode: "",
      errorMessage: "",
      btnActive: false,
    });

    const { email, password } = this.state;

    if (password.length < 8) {
      return;
    }

    if (email && password) {
      this.setState({ loading: true });
      userService.adminLogin(email, password, this);
      //this.props.history.push("/reqadmin");
    }
  }

  userValidation = () => {
    if (this.state.submitted && !this.state.email) {
      return <div className="errormessage-log ">Email is required</div>;
    }
  };

  passwordValidation = () => {
    if (this.state.submitted && !this.state.password) {
      return <div className="errormessage-log ">Password is required</div>;
    } else if (this.state.submitted && this.state.password.length < 8) {
      return (
        <div className="errormessage-log ">
          Sorry, that didn’t work. Please try again
        </div>
      );
    }
    return <div className="errormessage-log " />;
  };

  otherValidation = () => {
    if (
      this.state.submitted &&
      this.state.loginFailed &&
      this.state.statusCode === 401
    ) {
      return <div className="errormessage-log ">{this.state.errorMessage}</div>;
    } else if (
      this.state.submitted &&
      this.state.loginFailed &&
      this.state.statusCode === 422
    ) {
      return <div className="errormessage-log ">{this.state.errorMessage}</div>;
    }

    return <div className="errormessage-log " />;
  };

  redirectImgLink = () => {
    this.props.history.push("/");
  };

  render() {
    return (
      <div className="admin-container freeaccountlogin">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart- Admin Login</title>
          <link rel="login" href="/login" />
        </Helmet>
        <div className="container">
          <div className="card card-login mx-auto text-center bg-dark" style={{minHeight:'350px'}}>
            <div className="card-header mx-auto bg-dark">
              <span>
                {" "}
                <img
                  src={logoImage}
                  className="w-40"
                  alt="Logo"
                  onClick={this.redirectImgLink}
                />{" "}
              </span>
              <br />
              {/* <span className="logo_title mt-5"> Login Admin </span> */}
            </div>
            <div className="card-body" style={{minHeight:'250px'}}>
              <Form onSubmit={this.handleSubmit} method="post">
                <div className="input-group form-group mt-4">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-user"></i>
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="email"
                    name="email"
                    id="username"
                    required
                    placeholder="User Email"
                    onChange={this.handleChange}
                    autoComplete="on"
                  />
                </div>

                <div className="input-group form-group">
                  <div className="input-group-prepend">
                    <span className="input-group-text">
                      <i className="fa fa-key"></i>
                    </span>
                  </div>
                  <input
                    className="form-control"
                    type="password"
                    name="password"
                    id="password"
                    placeholder="Password"
                    onChange={this.handleChange}
                    required
                    autoComplete="on"
                  />
                </div>
                <div className="errormessage-log ">
                  <div className="errormessage-log ">
                    {this.state.errorMessage &&
                    this.state.errorMessage.user_type
                      ? this.state.errorMessage.user_type
                      : ""}
                  </div>
                  {this.userValidation()}
                  {this.passwordValidation()}
                  {this.otherValidation()}
                </div>
                <p></p>
                <div className="form-group">
                  {this.state.loading && (
                    <i className="fa fa-spinner fa-pulse">&nbsp;</i>
                  )}
                  <input
                    type="submit"
                    name="btn"
                    value="Login"
                    className="btn btn-outline-danger float-right login_btn"
                  />
                </div>
              </Form>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </div>
    );
  }
}

export default FreeAccLogin;
