import React, { Component } from "react";
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import sideImage from "./images/side-image.png";
import loaderImg from "./images/loader.gif";
import { FaEyeSlash, FaEye } from "react-icons/fa";
import "./styles/registerbeneficiary.css"; // Add your custom CSS here
import PhoneInput from "react-phone-number-input";
import { beneficiaryUserService } from "../services/beneficiary.user.service";
import { Helmet } from "react-helmet";
import zxcvbn from "zxcvbn";
import { ToastsStore } from "react-toasts";

class BeneficiaryRegistration extends Component {
  constructor(props) {
    super(props);
    const stateTmp = this.props.location.state.state;

    this.state = {
      user_id: stateTmp ? stateTmp.user_id : "",
      email: stateTmp ? stateTmp.email : "",
      password: "",
      phoneNumber: "",
      userName: stateTmp.first_name ? `${stateTmp.first_name} ${stateTmp.last_name}` : "",
      confirmPassword: "",
      passwordVisible: "password",
      lastFourSocial: stateTmp.last_4_beneficiary ? stateTmp.last_4_beneficiary : "",
      primaryUserId: 0,
      beneficiaryId: 0,
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: { email: "", mobile: "", other: "", social: "" },
      statusCode: "",
      regErrors: "",
      errorPassword: [],
      passwordScore: "",
      errorMSG:''
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount(){
    console.log(this.props.location.state.state)
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
    if (name === "password") {
      const passwordSuggent = zxcvbn(value);
      this.setState({
        passwordScore: passwordSuggent.score,
        errorPassword: passwordSuggent.feedback.suggestions,
      });
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    if (this.state.password.length < 8 || this.state.passwordScore < 3) {
      ToastsStore.warning("Password length isn't in correct format!");
      return;
    }

    const { email, password, lastFourSocial } = this.state;
    if (this.state.password.length < 8 || lastFourSocial.length !== 4) {
      ToastsStore.warning("Password isn't in correct format!");
      return;
    }
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    if (email && password && lastFourSocial) {
      this.setState({ loading: true });
      beneficiaryUserService.registerBeneficiaryUser(
        this.state.user_id,
        this.state.email,
        this.state.password,
        this.state.lastFourSocial,
        this.state.userName,
        this.state.phoneNumber,
        this
      );
    }
  }

  emailValidation() {
    if (this.state.submitted && !this.state.email) {
      return <div className="text-danger">Email is required</div>;
    } else if (
      this.state.submitted === true &&
      this.state.regFailed === true &&
      this.state.statusCode === 406
    ) {
      return (
        <div className="text-danger">{this.state.errorMessage}</div>
      );
    }
  }

  passwordValidation() {
    if (this.state.submitted && !this.state.password) {
      return <div className="text-danger">Password is required</div>;
    } else if (this.state.submitted && this.state.password.length < 8) {
      return (
        <div className="text-danger">
          Password is too short. Minimum length is 8 characters.
        </div>
      );
    }

    return <div className="text-danger" />;
  }

  passwordConfirmation = () => {
    if (
      this.state.submitted &&
      this.state.password !== this.state.confirmPassword
    ) {
      return <div className="text-danger">Password does not match</div>;
    } else {
    }
    return <div className="text-danger" />;
  };

  socialNumberValidity = () => {
    if (this.state && this.state.lastFourSocial.length !== 4) {
      return <div className="text-danger px-3">Fill the last 4 numbers</div>;
    } else if (
      this.state.submitted === true &&
      this.state.regFailed === true &&
      this.state.statusCode === 401
    ) {
      return (
        <div className="text-danger px-3">{this.state.errorMessage}</div>
      );
    } else if (
      this.state.submitted === true &&
      this.state.regFailed === true &&
      this.state.statusCode === 402
    ) {
      return (
        <div className="text-danger">{this.state.errorMessage}</div>
      );
    }
    return <div className="text-danger" />;
  };

  phoneNumberValidation() {
    if (this.state.submitted && !this.state.phoneNumber) {
      return (
        <div className="text-danger">
          Phone number is required. {this.state.regErrors.mobile}
        </div>
      );
    }
    return (
      <div className="text-danger">{this.state.regErrors.mobile}</div>
    );
  }

  phoneInputField = () => {
    return (
      <div className="d-flex align-items-center flex-wrap">
        <PhoneInput
          name="phonenumber"
          placeholder="Enter your phone number"
          value={this.state.phoneNumber}
          onChange={(phoneNumber) =>
            this.setState({ phoneNumber, errorMessage: { mobile: "" } })
          }
          className="phone-input-field"
        />
      </div>
    );
  };


  eyeIcon = (regPass) => {
    if (regPass) {
      this.setState((prevState) => ({
        passwordVisible:
          prevState.passwordVisible === "password" ? "text" : "password",
      }));
    }
  };

  render() {
  
    return (
      <div className="container-fluid row vh-100 w-100 d-flex ">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Beneficiary Registration</title>
        </Helmet>
        {/* <div className="w-100 d-flex"> */}
          
        <div className="container-fluid row  w-100 Overall-Content">
          <div className="row d-flex col-md-5 col-lg-5 col-sm-5 justify-content-end">
            <Form className="form-size" onSubmit={this.handleSubmit}>
              <div className="text-style w-100 justify-content-start px-2">
                <h4 className="form-title pt-3">Create Account</h4>
                <div className="pb-2 small">Now that you're here, create an account so you can easily come back.</div>
              </div>
               <div className="px-3 text-danger mt-2 mb-2 font-weight-bold">
                <div>{this.state.errorMessage && this.state.errorMessage['email']}</div>
                <div>{this.state.errorMessage && this.state.errorMessage['name']}</div>
                <div>{this.state.errorMessage && this.state.errorMessage['password']}</div>
                <div>{this.state.errorMessage && this.state.errorMessage['last4social_code']}</div>
               </div>
              <FormGroup className="px-3 formGroup-style">
                <Label for="userName" className="text-dark">Full Name</Label>
                <Input
                  type="text"
                  name="userName"
                  id="userName"
                  placeholder="Full Name"
                  value={this.state.userName || ""}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              {this.state.submitted && !this.state.userName && (
                <div className="text-danger">Full name is required</div>
              )}
              <FormGroup className="px-3 formGroup-style">
                <Label for="userEmail" className="text-dark">Email Address</Label>
                <Input
                  type="text"
                  name="email"
                  id="userEmail"
                  placeholder="Email"
                  value={this.state.email || ""}
                  onChange={this.handleChange}
                  required
                  // readOnly
                />
              </FormGroup>
              {this.emailValidation()}
              <FormGroup className="px-3 formGroup-style">
                <Label for="userPassword" className="text-dark">Password</Label>
                <div className="position-relative">
                  <Input
                    type={this.state.passwordVisible}
                    name="password"
                    id="userPassword"
                    placeholder="Password"
                    value={this.state.password}
                    onChange={this.handleChange}
                    required
                    className="form-control"
                    style={{ paddingRight: "2.5rem" }} // Added padding to make room for the icon inside the input
                  />
                  <span
                    className="password-eye-icon position-absolute"
                    style={{
                      top: "50%",
                      right: "1rem",
                      transform: "translateY(-50%)",
                      cursor: "pointer",
                    }}
                    onClick={this.eyeIcon.bind(this, "regPassword")}
                  >
                    {this.state.passwordVisible === "password" ? <FaEyeSlash /> : <FaEye />}
                  </span>
                </div>
              </FormGroup>
              {this.passwordValidation()}
              <div className="text-danger">
                <ul className="list-unstyled">
                  {this.state.errorPassword.map((err, index) => (
                    <li key={index}>{err}</li>
                  ))}
                </ul>
              </div>
              <FormGroup className="px-3 formGroup-style">
                <Label for="lastFourSocial" className="text-dark">Last 4 Social</Label>
                <Input
                  type="number"
                  name="lastFourSocial"
                  id="lastFourSocial"
                  placeholder="Last 4 Social"
                  value={this.state.lastFourSocial || ""}
                  onChange={this.handleChange}
                  required
                />
              </FormGroup>
              {this.socialNumberValidity()}
              <FormGroup className="px-3 formGroup-style">
                <Label for="phoneNumber" className="text-dark">Phone Number</Label>
                {this.phoneInputField()}
              </FormGroup>
              <div className="btn w-100 submitButton-style">
                <Button type="submit" className="btn w-100 font-weight-bold" style={{ backgroundColor: "#F6DA20", border: "0px" }}><strong>SAVE</strong></Button>
              </div>
            </Form>
          </div>
          <div className="row d-flex col-md-5 col-lg-5 col-sm-5 text-center align-items-center justify-content-start d-none d-md-flex h-65">
            <img src={sideImage} alt="Side Illustration" className="image-position mx-auto img-fluid" />
          </div>
        </div>

          
          {/* <Col md={7} className="d-none d-md-block">
            <img src={sideImage} alt="Side Illustration" className="img-fluid" />
          </Col> */}
        </div>
      // </div>
    );
  }
}

export default BeneficiaryRegistration;
