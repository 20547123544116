import React, { Component } from "react";
import { Link } from "react-router-dom";
import { userService } from "../services/user.service";
import { beneficiariesService } from "../services/beneficiaries.service";
import "../components/asterisk.css";
import "./styles/addbeneficiaryform.css";
import "../pages/styles/dashboardStyle.min.css";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import LeftSidebar from "../components/LeftSidebar";
import lineOfColours from "./images/line_of_colours.png";
import { ToastsContainer, ToastsStore } from "react-toasts";
import {
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { encrypt_url } from "../helpers/encrypt-url";

class AddBenefeciary extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);

    this.handleChange = this.handleChange.bind(this);
    this.submitBenefeciaryForm = this.submitBenefeciaryForm.bind(this);
    this.validateForm = this.validateForm.bind(this);

    this.handleAddBenefeciary = this.handleAddBenefeciary.bind(this);

    this.togglePopOver = this.togglePopOver.bind(this);

    this.state = {
      dropdownOpen: false,
      BenefeciaryList: [],
      helpOver: false,
      fields: [],
      errors: {},
      userObj: JSON.parse(user),
      encryptedString: encrypt_url(),
      divFormOne: 'flow',
      divFormTwo: 'none',
    };
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  togglePopOver() {
    this.setState({
      helpOver: !this.state.helpOver,
    });
  }

  handleLogout() {
    if (this.state.dropdownOpen) {
      console.log("Clicked logout");
      userService.logout(this.props);
    }
  }

  handleChange(e) {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields,
    });
  }

  submitBenefeciaryForm(e) {
    e.preventDefault();

    if (this.validateForm()) {
      let fields = [];
      fields["email"] = "";
      fields["last_4_beneficiary"] = "";
      fields["zip"] = "";
      this.setState({ fields: fields });
      this.handleAddBenefeciary();
    }
  }

  validateForm() {
    let fields = this.state.fields;
    let errors = {};
    let formIsValid = true;

    if (typeof fields["last_4_beneficiary"] !== "undefined") {
      if (!fields["last_4_beneficiary"].match(/^[0-9]{4}$/)) {
        formIsValid = false;
        errors["last_4_beneficiary"] = "*Please enter 4 numeric number only.";
      }
    }

    if (typeof fields["zip"] !== "undefined") {
      if (!fields["zip"].match(/^[0-9]{5}$/)) {
        formIsValid = false;
        errors["zip"] = "*Please enter valid zip no.";
      }
    }

    if (typeof fields["email"] !== "undefined") {
      if (
        !fields["email"].match(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
      ) {
        formIsValid = false;
        errors["email"] = "*Please enter valid email ";
      }
    }

    this.setState({
      errors: errors,
    });
    return formIsValid;
  }

  validEmailFormat = (e) => {
    let emailFormat = e.target.value;
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(emailFormat)) {
      console.log("emailFormat error");
      return false;
    } else {
      console.log("emailFormat correct");
      return true;
    }
  };

  handleAddBenefeciary() {
    const first_name = document.getElementById("firstname").value;
    const last_name = document.getElementById("lastname").value;
    const mail_address = document.getElementById("mail_address").value;
    const mail_address2 = document.getElementById("mail_address2").value;
    const email = document.getElementById("email").value;
    const city = document.getElementById("city").value;
    const last_4_beneficiary = document.getElementById("last_4_beneficiary")
      .value;
    const state = document.getElementById("cityState").value;
    const zip = document.getElementById("zip").value;

    if (
      last_4_beneficiary !== null &&
      first_name !== null &&
      last_name !== null &&
      mail_address !== null &&
      mail_address2 !== null &&
      city !== null &&
      state !== null &&
      zip !== null &&
      email !== null
    ) {
      let userId = this.state.userObj.data.user_id;
      let newId = beneficiariesService.storeBeneficiaryData(
        userId,
        last_4_beneficiary,
        first_name,
        last_name,
        mail_address,
        mail_address2,
        city,
        state,
        zip,
        email
      );

      console.log("newId :", newId);
    }

    this.props.history.push(`/beneficiaries/${this.state.encryptedString}`);
  }

  nextFormContributor = () => {

    const first_name = document.getElementById("firstname");
    const last_name = document.getElementById("lastname");
    const email = document.getElementById("email");
    const last_4_beneficiary = document.getElementById("last_4_beneficiary");
    if (!first_name.value || !last_name.value || !email.value || !last_4_beneficiary.value) {
      ToastsStore.warning("Please fill in all required fields before proceeding.");
      return false;
    }

    this.setState({
      divFormOne: 'none',
      divFormTwo: 'flow'
    })
  }

  prevFormContributor = () => {
    this.setState({
      divFormOne: 'flow',
      divFormTwo: 'none'
    })
  }


  render() {
    return (
      <div className="container-fluid" id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-AddBenefeciary</title>
          <link
            rel="addbenefeciary"
            href="http://thisheart.co/addbenefeciary"
          />
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <Row>
            <Col lg="8" md="10" sm="12" className="memories-name">
              <div className="row">
                <h1 className="memories-header">BENEFICIARIES </h1>
              </div>

              <p className="mt-2 pr-3">
                Here you’ll add your beneficiaries. Your beneficiaries are the
                people who will have access to your info when you go.
              </p>
            </Col>
          </Row>

          <div className="beneficiary-tab-add">
            <div className="container-fluid-bk">
              <div className="">
                <Form
                  method="post"
                  className="addbenefeForm"
                  onSubmit={this.submitBenefeciaryForm}
                >
                  <div id="divFormOne" style={{ display: this.state.divFormOne }}>
                    <Row form>
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">First Name</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="fname"
                            id="firstname"
                            required
                            placeholder="First Name"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">Last Name</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="lname"
                            id="lastname"
                            required
                            placeholder="Last Name"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row form className="mt-2">
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">Email Address</Label>
                          <Input
                            className="form-control-benefe"
                            type="email"
                            name="email"
                            id="email"
                            required
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleChange}
                          />
                          <div className="errormessage">
                            {this.state.errors.email}
                          </div>
                        </FormGroup>
                      </Col>

                      <Col md={6} sm={12} xs={12}>

                        <FormGroup className="required">
                          <Label className="control-label mb-lg-0 mb-1">
                            Last 4 of beneficiary's social
                          </Label>
                          <span id="Popover" className="d-block d-lg-inline-flex ml-lg-1 ml-0 mb-lg-0 mb-1">uh,why do you need this?</span>
                          <Popover
                            placement="top"
                            isOpen={this.state.helpOver}
                            target="Popover"
                            toggle={this.togglePopOver}
                          >
                            <PopoverHeader>
                              Do need this?
                              <span
                                className="dismissPop"
                                onClick={this.togglePopOver}
                              >
                                x
                              </span>
                            </PopoverHeader>
                            <PopoverBody>
                              It will show your social security number, you will
                              notified by email.
                            </PopoverBody>
                          </Popover>
                          <Input
                            className="form-control-benefe"
                            type="number"
                            name="last_4_beneficiary"
                            id="last_4_beneficiary"
                            required
                            placeholder="Last 4 social"
                            value={this.state.last_4_beneficiary}
                            onChange={this.handleChange}
                          />
                          <div className="errormessage">
                            {this.state.errors.last_4_beneficiary}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row className="pad-btnarea mt-1 p-0">
                      <Col>
                        <Button className="savebtn bg-thisheart" type="button" onClick={this.nextFormContributor}>
                          Next
                        </Button>
                        <Link to={`/beneficiaries/${this.state.encryptedString}`}>
                          <Button className="btnCancel">Cancel</Button>
                        </Link>
                      </Col>
                      {/* <Col /> */}
                    </Row>
                  </div>

                  <div id="divFormTwo" className="" style={{ display: this.state.divFormTwo }}>


                    <Row form>

                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">Mailing Address</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="mail_address"
                            id="mail_address"
                            required
                            placeholder="Mailing Address"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12} xs={12} className="mt-2">
                        <FormGroup>
                          <Label>Mailing Address 2</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="mail_address2"
                            id="mail_address2"
                            placeholder="Apartment or Floor"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">City</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="city"
                            id="city"
                            required
                            placeholder="City Name"
                          />
                        </FormGroup>
                      </Col>
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">State</Label>
                          <Input
                            className="form-control-benefe"
                            type="text"
                            name="statecity"
                            id="cityState"
                            required
                            placeholder="State Name"
                          />
                        </FormGroup>
                      </Col>
                    </Row>


                    <Row>
                      <Col md={6} sm={12} xs={12}>
                        <FormGroup className="required">
                          <Label className="control-label">Zip</Label>
                          <Input
                            className="form-control-benefe"
                            type="number"
                            name="zip"
                            id="zip"
                            required
                            placeholder="Zip No"
                            value={this.state.zip}
                            onChange={this.handleChange}
                          />
                          <div className="errormessage">
                            {this.state.errors.zip}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>


                    <Row className="pad-btnarea mt-1 pt-lg-3 p-0">
                      <Col>
                        <Button className="savebtn" type="button" onClick={this.prevFormContributor}>
                          Previous
                        </Button>

                        <Button className="btnCancel bg-thisheart" type="submit">
                          Save
                        </Button>

                        <Link to={`/beneficiaries/${this.state.encryptedString}`}>
                          <Button className="btnCancel">Cancel</Button>
                        </Link>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>

        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

export default AddBenefeciary;
