import { authHeader } from "../helpers/auth-header";

const apiService = {
  apiUrl: "https://admin.thisheart.co",
  //apiUrl: "http://127.0.0.1:8000",
  header: {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader(),
    },
  },
};

export default apiService;
