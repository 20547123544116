import React, { Component } from "react";
import { Link } from "react-router-dom";
import "./styles/medicalhistory.css";
import { userService } from "../services/user.service";
import { medicalService } from "../services/medicalhistory.service";
import apiUrl from "../services/api.service"
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import { ToastsStore } from "react-toasts";
// import { Worker, Viewer } from 'react-pdf-viewer';
 
 

import {
  Col,
  Row,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  Label,
} from "reactstrap";

import LeftSidebar from "../components/LeftSidebar";

var options = [];
var meText = "Me";
var momText = "Mom";
var dadText = "Dad";
var partnerText = "Partner";
class FileAttachmentViewer extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);


    this.ShowMeItem = this.ShowMeItem.bind(this);
    this.toggleDeleteMeItem = this.toggleDeleteMeItem.bind(this);
   
    this.handleItemChangeMe = this.handleItemChangeMe.bind(this);
    this.handleSelectedItemsMe = this.handleSelectedItemsMe.bind(this);

    this.ShowMomItem = this.ShowMomItem.bind(this);
    this.toggleDeleteMomItem = this.toggleDeleteMomItem.bind(this);

    this.handleSelectedItemsMom = this.handleSelectedItemsMom.bind(this);

    this.ShowDadItem = this.ShowDadItem.bind(this);
    this.toggleDeleteDadItem = this.toggleDeleteDadItem.bind(this);

    this.handleSelectedItemsDad = this.handleSelectedItemsDad.bind(this);

    this.ShowPartnerItem = this.ShowPartnerItem.bind(this);
    this.toggleDeletePartnerItem = this.toggleDeletePartnerItem.bind(this);
 
    this.handleSelectedItemsPart = this.handleSelectedItemsPart.bind(this);
    this.handleOTPSettings = this.handleOTPSettings.bind(this);

    this.state = {
      dataLoadingMe: false,
      dataLoadingMom: false,
      dataLoadingDad: false,
      dataLoadingPart: false,
      dropdownOpen: false,
      showme: false,
      itemselectedMe: [],
      itemselectedMom: [],
      itemselectedDad: [],
      itemselectedPartner: [],
      attachmentMe: [],
      attachmentPartner: [],
      attachmentDad: [],
      attachmentMom: [],
      inputText: "",

      meSaveItemBtn: false,
      momSaveItemBtn: false,
      dadSaveItemBtn: false,
      partSaveItemBtn: false,

      recordInfoMe: [],
      recordInfoMom: [],
      recordInfoDad: [],
      recordInfoPartner: [],

      uploadBtnSts: "false",
      deleteID: 0,
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
      selectedAttachedFileMe:'',
      selectedAttachedFilePartner:'',
      selectedAttachedFileMom:'',
      selectedAttachedFileDad:'',
    };
  }

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    options = medicalService.getAllDiagnosisList();

    medicalService.getMedicalData(meText, userId, this);
    medicalService.getMedicalData(momText, userId, this);
    medicalService.getMedicalData(dadText, userId, this);
    medicalService.getMedicalData(partnerText, userId, this);
  }

  handleOTPSettings() {
    this.props.history.push("/resettwofaaskpass");
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleLogout() {
    if (this.state.dropdownOpen) {
      userService.logout(this.props);
    }
  }

  ShowMeItem() {
    this.setState((prevState) => ({
      showme: !prevState.showme,
    }));
    this.setState({ meSaveItemBtn: false });
  }

  ShowMomItem() {
    this.setState((prevState) => ({
      showmom: !prevState.showmom,
    }));
    this.setState({ momSaveItemBtn: false });
  }

  ShowDadItem() {
    this.setState((prevState) => ({
      showdad: !prevState.showdad,
    }));
    this.setState({ dadSaveItemBtn: false });
  }

  ShowPartnerItem() {
    this.setState((prevState) => ({
      showpartner: !prevState.showpartner,
    }));

    this.setState({ partSaveItemBtn: false });
  }

  toggleOtherItemMe = () => {
    this.setState((prevState) => ({
      otherMeItem: !prevState.otherMeItem,
    }));
  };

  otherItemSaveMe = () => {
    let otherItem = document.getElementById("otherItem").value;
    const filterOtherItem = this.state.itemselectedMe.filter(
      (item) => item.label !== "Other"
    );
    if (otherItem.length < 1) {
      ToastsStore.warning("NO! Diagonosis has been added");
      return false;
    }
    medicalService.storeDiagonosisData(
      meText,
      otherItem,
      filterOtherItem,
      this
    );
    this.setState({ otherMeItem: false });
  };

  toggleOtherItemPartner = () => {
    this.setState((prevState) => ({
      otherPartnerItem: !prevState.otherPartnerItem,
    }));
  };

  otherItemSavePartner = () => {
    let otherPartner = document.getElementById("otherItemPart").value;
    
    const filterOtherItem = this.state.itemselectedPartner.filter(
      (item) => item.label !== "Other"
    );
    if (otherPartner.length < 1) {
      ToastsStore.warning("NO! Diagonosis has been added");
      return false;
    }
    medicalService.storeDiagonosisData(
      partnerText,
      otherPartner,
      filterOtherItem,
      this
    );
    this.setState({ otherPartnerItem: false });
  };

  toggleOtherItemMom = () => {
    this.setState((prevState) => ({
      otherItemMom: !prevState.otherItemMom,
    }));
  };

  otherItemSaveMom = () => {
    let otherMom = document.getElementById("otherItemMom").value;
    const filterOtherItem = this.state.itemselectedMom.filter(
      (item) => item.label !== "Other"
    );
    if (otherMom.length < 1) {
      ToastsStore.warning("NO! Diagonosis has been added");
      return false;
    }
    medicalService.storeDiagonosisData(
      momText,
      otherMom,
      filterOtherItem,
      this
    );
    this.setState({ otherItemMom: false });
  };

  toggleOtherItemDad = () => {
    this.setState((prevState) => ({
      otherItemDad: !prevState.otherItemDad,
    }));
  };

  otherItemSaveDad = () => {
    let otherDad = document.getElementById("otherItemDad").value;
   
    const filterOtherItem = this.state.itemselectedDad.filter(
      (item) => item.label !== "Other"
    );
    if (otherDad.length < 1) {
      ToastsStore.warning("NO! Diagonosis has been added");
      return false;
    }
    medicalService.storeDiagonosisData(
      dadText,
      otherDad,
      filterOtherItem,
      this
    );
    this.setState({ otherItemDad: false });
  };

  toggleDeleteMeItem(deleteID) {
    this.setState((prevState) => ({
      deleteMe: !prevState.deleteMe,
      deleteID: deleteID,
    }));
  }

  toggleDeleteMomItem(deleteID) {
    this.setState((prevState) => ({
      deleteMom: !prevState.deleteMom,
      deleteID: deleteID,
    }));
  }

  toggleDeleteDadItem(deleteID) {
    this.setState((prevState) => ({
      deleteDad: !prevState.deleteDad,
      deleteID: deleteID,
    }));
  }

  toggleDeletePartnerItem(deleteID) {
    this.setState((prevState) => ({
      deletePartner: !prevState.deletePartner,
      deleteID: deleteID,
    }));
  }

  toggleProfilePhoto = () => {
    this.setState((prevState) => ({
      profilePhoto: !prevState.profilePhoto,
    }));
  };

  showAddNewBtnMe = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-medical-me" onClick={this.ShowMeItem}>
          + ADD NEW
        </Button>
      );
    }
  };

  showAddNewBtnMom = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-medical-mom" onClick={this.ShowMomItem}>
          + ADD NEW
        </Button>
      );
    }
  };

  showAddNewBtnDad = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-medical-dad" onClick={this.ShowDadItem}>
          + ADD NEW
        </Button>
      );
    }
  };

  showAddNewBtnPartner = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn-add-medical-partner" onClick={this.ShowPartnerItem}>
          + ADD NEW
        </Button>
      );
    }
  };

  handleItemChangeMe = (itemselectedMe) => {
    let me_othr = 0;
    const checkOther = itemselectedMe.filter((i) => i.label === "Other");
    if (checkOther.length === 1) {
      const filterOtherItem = itemselectedMe.filter(
        (item) => item.label !== "Other"
      );
      let getText = this.toggleOtherItemMe();

      if (getText !== null && getText !== "") {
        medicalService.storeDiagonosisData(
          meText,
          getText,
          filterOtherItem,
          this
        );
      } else {
        this.setState({ meSaveItemBtn: false });
        me_othr = 1;
      }
    } else {
      this.setState({ itemselectedMe });
    }

    if (itemselectedMe.length > 1 && me_othr === 1) {
      this.setState({ meSaveItemBtn: true });
    } else if (itemselectedMe.length > 0 && me_othr === 0) {
      this.setState({ meSaveItemBtn: true });
    } else {
      this.setState({ meSaveItemBtn: false });
    }
  };

  handleItemChangePart = (itemselectedPartner) => {
    let part_othr = 0;
    const checkOther = itemselectedPartner.filter((i) => i.label === "Other");
    if (checkOther.length === 1) {
      const filterOtherItem = itemselectedPartner.filter(
        (item) => item.label !== "Other"
      );
 
      let getText = this.toggleOtherItemPartner();
      if (getText !== null && getText !== "") {
        medicalService.storeDiagonosisData(
          partnerText,
          getText,
          filterOtherItem,
          this
        );
      } else {
        this.setState({ partSaveItemBtn: false });
        part_othr = 1;
      }
    } else {
      this.setState({ itemselectedPartner });
    }

    if (itemselectedPartner.length > 1 && part_othr === 1) {
      this.setState({ partSaveItemBtn: true });
    } else if (itemselectedPartner.length > 0 && part_othr === 0) {
      this.setState({ partSaveItemBtn: true });
    } else {
      this.setState({ partSaveItemBtn: false });
    }
  };

  handleItemChangeMom = (itemselectedMom) => {
    let mom_othr = 0;
    const checkOther = itemselectedMom.filter((i) => i.label === "Other");
    if (checkOther.length === 1) {
      const filterOtherItem = itemselectedMom.filter(
        (item) => item.label !== "Other"
      );
      let getText = this.toggleOtherItemMom();
      if (getText !== null && getText !== "") {
        medicalService.storeDiagonosisData(
          momText,
          getText,
          filterOtherItem,
          this
        );
      } else {
        this.setState({ momSaveItemBtn: false });
        mom_othr = 1;
      }
    } else {
      this.setState({ itemselectedMom });
    }

    if (itemselectedMom.length > 1 && mom_othr === 1) {
      this.setState({ momSaveItemBtn: true });
    } else if (itemselectedMom.length > 0 && mom_othr === 0) {
      this.setState({ momSaveItemBtn: true });
    } else {
      this.setState({ momSaveItemBtn: false });
    }
  };

  handleItemChangeDad = (itemselectedDad) => {
    let dad_othr = 0;
    const checkOther = itemselectedDad.filter((i) => i.label === "Other");
    if (checkOther.length === 1) {
      const filterOtherItem = itemselectedDad.filter(
        (item) => item.label !== "Other"
      );
      let getText = this.toggleOtherItemDad();

      if (getText !== null && getText !== "") {
        medicalService.storeDiagonosisData(
          dadText,
          getText,
          filterOtherItem,
          this
        );
      } else {
        this.setState({ dadSaveItemBtn: false });
        dad_othr = 1;
      }
    } else {
      this.setState({ itemselectedDad });
    }

    if (itemselectedDad.length > 1 && dad_othr === 1) {
      this.setState({ dadSaveItemBtn: true });
    } else if (itemselectedDad.length > 0 && dad_othr === 0) {
      this.setState({ dadSaveItemBtn: true });
    } else {
      this.setState({ dadSaveItemBtn: false });
    }
  };

  handleSelectedItemsMe() {
    if (this.state.itemselectedMe.length < 1) {
      ToastsStore.warning("Sorry! No new diagonosis are stored");
      return false;
    } else if (this.state.itemselectedMe.length > 10) {
      ToastsStore.warning(
        "Sorry! Select 10 items once. Reset the previous selected list"
      );
      return false;
    }

    let userId = this.state.userObj.data.user_id;
    medicalService.storeMedicalData(
      meText,
      userId,
      this.state.itemselectedMe,
      this.state.recordInfoMe,
      this
    );
    this.setState({ showme: false });
  }

  handleSelectedItemsMom() {
    if (this.state.itemselectedMom.length < 1) {
      ToastsStore.warning("Sorry! No new diagonosis are stored");
      return false;
    } else if (this.state.itemselectedMom.length > 10) {
      ToastsStore.warning(
        "Sorry! Select 10 items once. Reset the previous selected list"
      );
      return false;
    }
    let userId = this.state.userObj.data.user_id;
    medicalService.storeMedicalData(
      momText,
      userId,
      this.state.itemselectedMom,
      this.state.recordInfoMom,
      this
    );

    this.setState({ showmom: false });
  }

  handleSelectedItemsDad() {
    if (this.state.itemselectedDad.length < 1) {
      ToastsStore.warning("Sorry! No new diagonosis are stored");
      return false;
    } else if (this.state.itemselectedDad.length > 10) {
      ToastsStore.warning(
        "Sorry! Select 10 items once. Reset the previous selected list"
      );
      return false;
    }
    let userId = this.state.userObj.data.user_id;
    medicalService.storeMedicalData(
      dadText,
      userId,
      this.state.itemselectedDad,
      this.state.recordInfoDad,
      this
    );

    this.setState({ recordInfoDad: this.state.itemselectedDad });
    this.setState({ showdad: false });
  }

  handleSelectedItemsPart() {
    if (this.state.itemselectedPartner.length < 1) {
      ToastsStore.warning("Sorry! No new diagonosis are stored");
      return false;
    } else if (this.state.itemselectedPartner.length > 10) {
      ToastsStore.warning(
        "Sorry! Select 10 items once. Reset the previous selected list"
      );
      return false;
    }
    let userId = this.state.userObj.data.user_id;
    medicalService.storeMedicalData(
      partnerText,
      userId,
      this.state.itemselectedPartner,
      this.state.recordInfoPartner,
      this
    );

    this.setState({
      recordInfoPartner: this.state.itemselectedPartner,
    });
    this.setState({ showpartner: false });
  }

  getFileType = (url) => {
    const extension = url.split('.').pop().toLowerCase();
    if (['jpg', 'jpeg', 'png', 'gif', 'bmp'].includes(extension)) {
      return 'image';
    } else if (extension === 'pdf') {
      return 'pdf';
    }
    return 'unknown';
  }

 
 
 
  render() {
    const { item, item_mom, item_dad, item_part } = this.state;
    const query = new URLSearchParams(this.props.location.search);
    const file_attachment_url =  query.get("file");
    const file_type = this.getFileType(file_attachment_url)
    const full_file_attachment_url = apiUrl.apiUrl+'/'+ query.get("file");
    
    return (
      <main className="home-container" id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full" key="1">

        <Helmet>
        
          <title>ThisHeart-MedicalHistory</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          {/* <div className="medical-contain"> */}
          <div className="">
            
          </div>
          <div className="container-fluid">
            {
              file_type=="image"?
                (<img src={full_file_attachment_url} style={{height:'100vh',width:'100%'}}/>)
              :
                ( <i></i>)
            
            }
            
          </div>
        </div>
 
      </main>
    );
  }
}

export default FileAttachmentViewer;
