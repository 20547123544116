import React, { Component } from "react";
import { Helmet } from "react-helmet";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import { withRouter } from "react-router-dom";
import Header from "../components/Header";
import LeftSidebar from "../components/LeftSidebar";
import apiService from "./../services/api.service";
import { memoriesService } from "../services/memories.service";
import "./styles/homeIndex.css";
import "./styles/featmemoriespage.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/memories.css";
import viewImg from "../pages/images/thisheartweb/image-view.png";
import deleteImg from "../pages/images/thisheartweb/delete-image.png";
import { ToastsStore } from "react-toasts";

const apiUrl = apiService.apiUrl;

class MemoriesImages extends Component {
  constructor(props) {
    super(props);

    this.toggleDeleteImg = this.toggleDeleteImg.bind(this);
    this.deleteImages = this.deleteImages.bind(this);
    this.handleBackClick = this.handleBackClick.bind(this);

    this.state = {
      recordLists: [],
      imageLists: [],
      currentView: "Images",
      userObj: JSON.parse(localStorage.getItem("user")),
      userType: localStorage.getItem("userType"),
      activeTab: "5",
      user_id: "",
      beneficiary_id: "",
      primary_user_id: "",
      currentPage: 1,
      imagesPerPage: 12,
      deleteRecord: false,
      deleteID: null,
      imageOpen: false,
      imgPathModal: "",
      imgTitle:"",
      imgDescription: "",
      user_type: localStorage.getItem("user_type"),
    };
  }

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    this.setState({
      user_id: this.state.userObj.data.user_id,
      beneficiary_id: "",
      primary_user_id: this.state.userObj.data.primary_user_id,
    });

    const query = new URLSearchParams(this.props.location.search);
    const actions = query.get("actions");
    if (actions === "social") {
      this.setState({ activeTab: "5" });
    } else if (actions === "image") {
      this.setState({ activeTab: "1" });
    } else if (actions === "video") {
      this.setState({ activeTab: "2" });
    } else if (actions === "letters") {
      this.setState({ activeTab: "4" });
    } else if (actions === "record") {
      this.setState({ activeTab: "5" });
    }

    // memoriesService.getImageData(userId, this);
    memoriesService.getAllMemoriesData(this);
  }

  isImageUrl = (filename) => {
    return /\.(jpg|jpeg|png|gif)$/i.test(filename);
  };

  setImageViewMethod = (image) => {
  
    this.setState({
      imgPathModal: `${apiUrl}/${image.filename}`,
      imageOpen: true,  
      imgTitle: image.title,
      imgDescription: image.description,
    });
  };

  handleImagePopUp = () => {
    this.setState((prevState) => ({
      imageOpen: !prevState.imageOpen,
    }));
  };

  handleBackClick=()=>{
     
    if (this.props.history.length > 1) {
      this.props.history.push('/memories/U2FsdGVkX18RZvEdBUui3jRae9OaZ4AvLdg7VsGbDkY%3D');
    } else {
        alert('memories')
        this.props.history.push('/memories/U2FsdGVkX18RZvEdBUui3jRae9OaZ4AvLdg7VsGbDkY%3D');
    }

  }

  toggleDeleteImg(deleteID) {
    this.setState((prevState) => ({
      deleteImg: !prevState.deleteImg,
      deleteID: deleteID,
    }));
  }

  setDeleteRecord = (image) => {
    this.setState({
      deleteRecord: true,
      deleteID: image.id,
      imgPathModal: `${apiUrl}/${image.filename}`
    });
  };

  handleDeleteConfirm = () => {
    const { deleteID } = this.state;
    memoriesService.deleteImage(deleteID).then(() => {
      this.setState(prevState => ({
        imageLists: prevState.imageLists.filter(image => image.id !== deleteID),
        deleteRecord: false,
        deleteID: null
      }));
    }).catch(err => {
      console.error("Failed to delete the image", err);
    });
  };

  // deleteImages() {
  //   const tempImgID = this.state.deleteID;
  //   let successDel = memoriesService.deleteImageData(tempImgID);

  //   if (successDel) {
  //     const imageLists = this.state.imageLists.filter(
  //       (img) => img.id !== tempImgID
  //     );
  //     this.setState({
  //       imageLists,
  //     });
  //     this.setState({
  //       deleteImg: false,
  //     });
  //   } else {
  //     ToastsStore.warning("Sorry! Image is not deleted.");
  //   }
  // }
  deleteImages() {
    const tempImgID = this.state.deleteID;
    const successDel = memoriesService.deleteImageData(tempImgID);

    if (successDel) {
      const imageLists = this.state.imageLists.filter(
        (img) => img.id !== tempImgID
      );
      const totalPages = Math.ceil(imageLists.length / this.state.imagesPerPage);
      const newCurrentPage = Math.min(this.state.currentPage, totalPages);

      this.setState({
        imageLists,
        currentPage: newCurrentPage,
        deleteImg: false
      });
    } else {
      ToastsStore.warning("Sorry! Image is not deleted.");
    }
  }

  handleDeleteCancel = () => {
    this.setState({ deleteRecord: false, deleteID: null });
  };

  renderImagesWithPlaceholders = () => {
    const { imageLists, currentPage, imagesPerPage } = this.state;
    if (!imageLists) {
      return null;
    }
    const validImages = imageLists.filter(image => this.isImageUrl(image.filename));
    const indexOfLastImage = currentPage * imagesPerPage;
    const indexOfFirstImage = indexOfLastImage - imagesPerPage;
    const currentImages = validImages.slice(indexOfFirstImage, indexOfLastImage);

    const placeholdersCount = Math.max(0, imagesPerPage - currentImages.length);
    const { user_type } = this.state;

 
    return (
      <div>
        <div className="row">
          {currentImages.map((item, index) => (
       
            <div className="col-6 col-md-3 mb-3" style={{ flex: '0 0 24%', maxWidth: '24%' }} key={index}>
              <div className="image-container-memory">
                <img className="image-container-memory-img" src={`${apiUrl}/${item.filename}`} alt="Memory" />
                <div className="hover-buttons-memory">
                    <div className="hover-buttons-memory-button">
                      <div onClick={() => this.setImageViewMethod(item)} className="p-0 mr-1" style={{display:'grid'}}>
                        <img src={viewImg} alt="View" style={{ width: '24px', height: '24px' }} className="" />
                      </div>
                    {(user_type === "primary" || item.user_id === this.state.user_id) && (
                      <div onClick={this.toggleDeleteImg.bind(this, item.id)} className="p-0 ml-1" style={{ display: 'grid' }}>
                        <img src={deleteImg} alt="Delete" style={{ width: '24px', height: '24px' }} className="" />
                      </div>
                    )}
 

                    </div>
                    {
                      item.title?(<div className="clearfix bg-thisheart img-overlay-thumbnail my-2 py-2 tab-hide sm-hide"  >{item.title}</div>):(<div className="clearfix img-overlay-thumbnail my-2 py-2 tab-hide sm-hide"  ></div>)
                    }
                  
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  // renderPageNumbers = () => {
  //   const { imageLists, imagesPerPage, currentPage } = this.state;
  //   const validImages = imageLists.filter(image => this.isImageUrl(image.filename));
  //   const pageNumbers = [];
  //   for (let i = 1; i <= Math.ceil(validImages.length / imagesPerPage); i++) {
  //     pageNumbers.push(i);
  //   }
  //   return (
  //     <ul className="pagination justify-content-center">
  //       {pageNumbers.map(number => (
  //         <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
  //           <button onClick={() => this.handlePageChange(number)} className="page-link">
  //             {number}
  //           </button>
  //         </li>
  //       ))}
  //     </ul>
  //   );
  // };

  renderPageNumbers = () => {
    const { imageLists, imagesPerPage, currentPage } = this.state;
    const validImages = imageLists.filter(image => this.isImageUrl(image.filename));
    const totalImages = validImages.length;
    const pageNumbers = [];

    // Logic 1: When there are exactly 12 images, no pagination
    if (totalImages <= 12) {
      return null;
    }

    // Logic 2 & 3: Calculate the number of pages needed
    const totalPages = Math.ceil(totalImages / imagesPerPage);
    for (let i = 1; i <= totalPages; i++) {
      pageNumbers.push(i);
    }

    return (
      <ul className="pagination justify-content-center">
        {pageNumbers.map(number => (
          <li key={number} className={`page-item ${currentPage === number ? 'active' : ''}`}>
            <button onClick={() => this.handlePageChange(number)} className="page-link">
              {number}
            </button>
          </li>
        ))}
      </ul>
    );
  };

  handlePageChange = (pageNumber) => {
    this.setState({ currentPage: pageNumber });
  };

  render() {
    return (
      <div
        className="home-container"
        id="main-wrapper"
        data-theme="light"
        data-layout="vertical"
        data-navbarbg="skin6"
        data-sidebartype="full"
        data-sidebar-position="fixed"
        data-header-position="fixed"
        data-boxed-layout="full"
      >
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Images</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />

        <div className="page-wrapper" style={{ display: "block" }}>
          <div className="container-fluid mt-2">
            <div className="container-fluid">
              <div className="row">
                <div className="col-6">
                  <h1>Photos</h1>
                </div>
                <div className="col-6">
                  <div className="d-flex col-lg-11 justify-content-end align-items-center" style={{ paddingBottom: '10px' }}>
                    <button type="button" className="btn add-btn-mem mt-2 mr-3" onClick={this.handleBackClick}>
                      <svg xmlns="http://www.w3.org/2000/svg"width="16" height="16" fill="currentColor" className="bi bi-arrow-left mr-1" viewBox="0 0 16 16">
                        <path fillRule="evenodd" d="M15 8a.5.5 0 0 0-.5-.5H2.707l3.147-3.146a.5.5 0 1 0-.708-.708l-4 4a.5.5 0 0 0 0 .708l4 4a.5.5 0 0 0 .708-.708L2.707 8.5H14.5A.5.5 0 0 0 15 8" />
                      </svg>
                      <span><span className="go-back-text">Go</span> Back </span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            {this.renderImagesWithPlaceholders()}
            <div>
              {this.renderPageNumbers()}
            </div>
          </div>
        </div>

        <Modal isOpen={this.state.imageOpen} toggle={this.handleImagePopUp} className="modal-lg">
         
          <ModalBody style={{fontFamily: "Poppins, sans-serif"}}>
            <div className="font-weight-bold text-left mb-2 h4" >
                <button type="button" onClick={this.handleImagePopUp} className="close" data-dismiss="modal">&times;</button>
            </div>
            <h6 className="card-text">{this.state.imgTitle}</h6>
            <div className="card-text">{this.state.imgDescription}</div>
            <div className="img-effect mt-3">
              <img src={this.state.imgPathModal} className="mediaView" alt="Selected" style={{ width: '100%' }} />
            </div>
          </ModalBody>
        </Modal>

        <div>
          <Modal
            isOpen={this.state.deleteImg}
            toggle={this.toggleDeleteImg}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteImg}>Memories Image</ModalHeader>
            <ModalBody className="form-popup">
              <p> Are you sure, want to Delete this item ? </p>
            </ModalBody>
            <ModalFooter>
 
              <Button
                color="danger"
                type="button"
                onClick={this.deleteImages.bind()}
                style={{ borderRadius: "7px" }}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeleteImg}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
 
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}

export default withRouter(MemoriesImages);