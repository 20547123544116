import React, { Component } from "react";
import { Form } from "reactstrap";
import { ToastsContainer, ToastsStore } from "react-toasts";
import "./styles/freeaccountlogin.css";
import { Helmet } from "react-helmet";
import MainServices from "../services/main.service";
import { userService } from "../services/user.service";
import loaderImg from "./images/loader.gif";

class ApproveReject extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);

    this.state = {
      approveReq: false,
      rejectReq: false,
      loadingFreeAccount:false
    };
  }

  componentDidMount() {
    this._isMounted = true;
    const adminlogin = MainServices.getBStore('adminlogin')
    if(adminlogin !== "adminlogin"){
      const activation_code = this.props.match.params.activation_code
      this.props.history.push(`/freeadminlogin/${activation_code}`);
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  loadingImg = () => {
    return this.state.loadingFreeAccount && (<img className="loader-img" alt="loaderImg" src={loaderImg} />);
  };

  handleSubmit = (reqesteds, btn) =>{
    btn.preventDefault()
    this.setState({loadingFreeAccount:true})
    const activation_codes = this.props.match.params.activation_code
    const data = {
      reqested: reqesteds,
      activation_code: activation_codes
    }
    userService.approvedFreeAccount(this, data)
  }

  render() {
    return (
      <div className="admin-container freeaccountlogin">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart- Admin Approval</title>
          <link rel="login" href="/login" />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="box-approve">
                <h3 className="txt-approve">
                  Aprrove or Reject Free Account Permission <br />
                  {this.loadingImg()}
                </h3>
              
                <Form method="post">
                  <button className=" btn btn-pink-approve"  onClick={this.handleSubmit.bind(this,"approved")}>
                    <span>
                      <i className="fa fa-thumbs-up" aria-hidden="true" />
                    </span>
                    Approve
                  </button>
                  <button className=" btn btn-orange-reject" onClick={this.handleSubmit.bind(this,"rejected")}>
                    <span>
                      <i className="fa fa-thumbs-down" />
                    </span>
                    Reject
                  </button>
                </Form>
               
              </div>
            </div>
          </div>
        </div>
        <ToastsContainer store={ToastsStore}/>
      </div>
    );
  }
}

export default ApproveReject;
