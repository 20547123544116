import $ from 'jquery';

function applyCustomJQuery() {
  $(document).ready(function() {
    // Custom jQuery code
    $('body').on('click','a.sidebartoggler',function() {

      const divMain =  $("body div#main-wrapper");
      const divClass = divMain.hasClass('mini-sidebar');
      setTimeout(function(){
        if(divClass){
          divMain.attr({'class':'na','data-sidebartype':'full'});
        }else{
          divMain.attr({'class':'mini-sidebar','data-sidebartype':'mini-sidebar'});
        }
      }, 200)
      
    });

      // this is for close icon when navigation open in mobile view
      $('body').on('click','a.nav-toggler',function() {
        
        //$('body div#main-wrapper').attr('class','show-sidebar');
       

        const divMain =  $("body div#main-wrapper");
        const divClassShow = divMain.hasClass('show-sidebar');

        setTimeout(function(){
          if(divClassShow){
            divMain.attr({'class':'na','data-sidebartype':'full'});
            $('a.nav-toggler i').attr('class','ti-menu');
          }else{
            divMain.attr({'class':'show-sidebar','data-sidebartype':'full'});
            $('a.nav-toggler i').attr('class','ti-close');
          }
        }, 200)

      });

    $.fn.AdminSettings = function (options) {
      var id = this.attr("id");
      var defaults = {
        Theme: true,
        Layout: "vertical",
        LogoBg: "skin1",
        NavbarBg: "skin6",
        SidebarType: "full",
        SidebarColor: "skin1",
        SidebarPosition: false,
        HeaderPosition: false,
        BoxedLayout: false
      };
      var settings = $.extend({}, defaults, options);
      var methods = {
        init: function () {
          methods.manageTheme();
          methods.manageLayout();
          methods.manageBackground();
          methods.manageSidebarType();
          methods.manageSidebarColor();
          methods.manageSidebarPosition();
          methods.manageBoxedLayout();
        },
        manageTheme: function () {
          var theme = settings.Theme;
          if (settings.Layout === "vertical") {
            theme ? $("body").attr("data-theme", "dark") : $("body").attr("data-theme", "light");
          }
        },
        manageLayout: function () {
          if (settings.Layout === "horizontal") {
            $("#" + id).attr("data-layout", "horizontal");
          } else if (settings.Layout === "vertical") {
            $("#" + id).attr("data-layout", "vertical");
            $(".scroll-sidebar").perfectScrollbar({});
          }
        },
        manageBackground: function () {
          $("#" + id + " .topbar .navbar-header").attr("data-logobg", settings.LogoBg || "skin1");
          $("#" + id + " .topbar .navbar-collapse").attr("data-navbarbg", settings.NavbarBg || "skin1");
          $("#" + id).attr("data-navbarbg", settings.NavbarBg || "skin1");
        },
        manageSidebarType: function () {
          var updateSidebarType = function () {
            var width = window.innerWidth || this.screen.width;
            if (width < 1170) {
              $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
            } else {
              $("#main-wrapper").attr("data-sidebartype", "full");
            }
          };

          if (settings.SidebarType === "full") {
            $("#" + id).attr("data-sidebartype", "full");
            $(window).ready(updateSidebarType);
            $(window).on("resize", updateSidebarType);
            $(".sidebartoggler").on("click", function () {
              $("#main-wrapper").toggleClass("mini-sidebar");
            });
          }
          // Add additional SidebarType cases here
        },
        manageSidebarColor: function () {
          $("#" + id + " .left-sidebar").attr("data-sidebarbg", settings.SidebarColor || "skin1");
        },
        manageSidebarPosition: function () {
          if (settings.Layout === "vertical") {
            settings.SidebarPosition ? $("#" + id).attr("data-sidebar-position", "fixed") : $("#" + id).attr("data-sidebar-position", "absolute");
            settings.HeaderPosition ? $("#" + id).attr("data-header-position", "fixed") : $("#" + id).attr("data-header-position", "relative");
          }
        },
        manageBoxedLayout: function () {
          if (settings.Layout === "vertical" || settings.Layout === "horizontal") {
            settings.BoxedLayout ? $("#" + id).attr("data-boxed-layout", "boxed") : $("#" + id).attr("data-boxed-layout", "full");
          }
        }
      };

      methods.init();
    };

    $(function () {
      // Chat and other initialization code
      $("#chat .message-center a").on("click", function () {
        var userName = $(this).find(".mail-contnet h5").text();
        var userImg = $(this).find(".user-img img").attr("src");
        var userId = $(this).attr("data-user-id");
        var userStatus = $(this).find(".profile-status").attr("data-status");

        if ($(this).hasClass("active")) {
          $(this).toggleClass("active");
          $(".chat-windows #user-chat" + userId).hide();
        } else {
          $(this).toggleClass("active");
          if ($(".chat-windows #user-chat" + userId).length) {
            $(".chat-windows #user-chat" + userId).removeClass("mini-chat").show();
          } else {
            var receiveMsg = msg_receive("I watched the storm, so beautiful yet terrific.");
            var chatHtml = `
              <div class='user-chat' id='user-chat${userId}' data-user-id='${userId}'>
                <div class='chat-head'>
                  <img src='${userImg}' data-user-id='${userId}'><span class='status ${userStatus}'></span>
                  <span class='name'>${userName}</span>
                  <span class='opts'><i class='ti-close closeit' data-user-id='${userId}'></i><i class='ti-minus mini-chat' data-user-id='${userId}'></i></span>
                </div>
                <div class='chat-body'>
                  <ul class='chat-list'>${receiveMsg}${msg_sent("That is very deep indeed!")}</ul>
                </div>
                <div class='chat-footer'>
                  <input type='text' data-user-id='${userId}' placeholder='Type & Enter' class='form-control'>
                </div>
              </div>`;
            $(".chat-windows").append(chatHtml);
          }
        }
      });

      $(document).on("click", ".chat-windows .user-chat .chat-head .closeit", function () {
        var userId = $(this).attr("data-user-id");
        $(".chat-windows #user-chat" + userId).hide();
        $("#chat .message-center .user-info#chat_user_" + userId).removeClass("active");
      });

      $(document).on("click", ".chat-windows .user-chat .chat-head img, .chat-windows .user-chat .chat-head .mini-chat", function () {
        var userId = $(this).attr("data-user-id");
        $(".chat-windows #user-chat" + userId).toggleClass("mini-chat");
      });

      $(document).on("keypress", ".chat-windows .user-chat .chat-footer input", function (e) {
        if (e.keyCode === 13) {
          var userId = $(this).attr("data-user-id");
          var message = $(this).val();
          var sentMsg = msg_sent(message);
          $(".chat-windows #user-chat" + userId + " .chat-body .chat-list").append(sentMsg);
          $(this).val("");
          $(this).focus();
          $(".chat-windows #user-chat" + userId + " .chat-body").perfectScrollbar({ suppressScrollX: true });
        }
      });

      $(".page-wrapper").on("click", function () {
        $(".chat-windows").addClass("hide-chat").removeClass("show-chat");
      });

      $(".service-panel-toggle").on("click", function () {
        $(".chat-windows").addClass("show-chat").removeClass("hide-chat");
      });
    });
  });
}

export default applyCustomJQuery;

function msg_receive(content) {
  var time = new Date();
  return `
    <li class='msg_receive'>
      <div class='chat-content'>
        <div class='box bg-light-info'>${content}</div>
      </div>
      <div class='chat-time'>${time.getHours()}:${time.getMinutes()}</div>
    </li>`;
}

function msg_sent(content) {
  var time = new Date();
  return `
    <li class='odd msg_sent'>
      <div class='chat-content'>
        <div class='box bg-light-info'>${content}</div>
      </div>
      <div class='chat-time'>${time.getHours()}:${time.getMinutes()}</div>
    </li>`;
}
