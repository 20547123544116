import React, { Component } from "react";
import "font-awesome/css/font-awesome.min.css";
import "./styles/maindashboard.css";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";
import LeftSidebar from "./../components/LeftSidebar";
// import RightSidebar from "./../components/RightSidebar";
import apiService from "./../services/api.service";
import { Col, Row, Button, FormGroup, Input } from "reactstrap";
import "./styles/accounts.css";
import "./styles/dashboardStyle.min.css";
import { encrypt_url } from "./../helpers/encrypt-url";
import { memoriesService } from "../services/memories.service";
import Dashboard from "../components/Dashboard";

class MainDashboard extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");
    this.toggle = this.toggle.bind(this);
    this.state = {
      userObj: JSON.parse(user),
      deleteID: 0,
      userType: localStorage.getItem("user_type"),
      searchText: "",
      encryptedString: encrypt_url(),
      allMemories: "",
      allMemoriesVideos: "",
      allImageVideos: "",
      insurance_count: "",
      financial_count: "",
      legal_count: "",
    };
  }

  componentDidMount() {
    console.log('localStorage.getItem("user_type")', localStorage.getItem("user_type"))
    const user_type = localStorage.getItem("user_type");
    if(user_type=="contributor"){
      this.props.history.push(`/dashboard-contributors/${this.state.encryptedString}`);
    }

    let userId = 0;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
      this.props.history.goBack();
    } else {
      userId = this.state.userObj.data.user_id;
    }
    memoriesService.getContentDashboard(this);
    return userId
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  changeString = (e) => {
    let srchTxt = e.target.value;
    this.setState({
      searchText: srchTxt,
    });
  };

  handleEnter = (e) => {
    if (e.keyCode === 13) {
      this.props.history.push({
        pathname: "/search",
        search: `?query=${this.state.searchText}`,
      });
    }
  };

  searchHandle = (e) => {
    console.log("string: ......", this.state.searchText);
    this.props.history.push({
      pathname: "/search",
      search: `?query=${this.state.searchText}`,
    });
  };


  handleInsurance = () => {
    this.props.history.push({
      pathname: `/accounts/${this.state.encryptedString}`,
      search: "account-type=insurance",
    });
  };

  handleFinancial = () => {
    this.props.history.push({
      pathname: `/accounts/${this.state.encryptedString}`,
      search: "account-type=financial",
    });
  };

  handleLegal = () => {
    this.props.history.push({
      pathname: `/accounts/${this.state.encryptedString}`,
      search: "account-type=legal",
    });
  };

  render() {

    return (
      // <div>
      <div id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart- Dashboard</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <Dashboard {...this.props} />
      </div>


      // {/* <RightSidebar {...this.props} /> */}
      // </div>
    );
  }
}

export default MainDashboard;
