import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./styles/errorpage.css";
import Header from "../components/HeaderError";
import { encrypt_url } from "../helpers/encrypt-url";
import notFound_web from "../pages/images/errorpage-web.png";
import notFound_mobile from "../pages/images/errorpage-mobile.png";
import notFound_tab from "../pages/images/errorpage-tab.png";

class ErrorPage extends Component {
  constructor(props) {
    super(props);
 
    let user = localStorage.getItem("user");
    let userObj = JSON.parse(user);
    this.fileInputRef = React.createRef();
    this.fileUploadRef = React.createRef();
    this.state = {
      userObj: userObj,
      showPlan: false,
      showPayment: false,
      loading: false,
      userName: "",
      email: "",
      additional_email: "",
      currentPass: "",
      newPass: "",
      confirmPass: "",
      userType: localStorage.getItem("user_type"),
      profile_image: "",
      package_info: "",
      encryptedString: encrypt_url(),
    };
  }

  render() {
    return (
      <div className="error-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-Error404</title>
          <link rel="errorpage" href="/errorpage" />
        </Helmet>
        <Header {...this.props} profile_image={this.state.profile_image} />
        <div className="container h-100 mt-5 pt-2">
          <div className="row align-items-center h-100 px-5 mx-5 web-show">
      
            <div className="col-md-5 mx-auto ">
              <img
                src={notFound_web}
                alt="error"
                className="img-fluid mx-auto d-block"
                style={{ width: "800px", height: "600" }}
              />
              
            </div>
            <div className="col-md-5 mx-auto text-right  pr-5 mr-5">
              <h1 className="justify-content-center align-self-center">ERROR!</h1>
              <h4 className="justify-content-center align-self-center">We can't find that page</h4>
              <p style={{ textAlign: "right" }} className="mt-5">
                <Link to={`/dashboard/${this.state.encryptedString}`} className="btn bg-thisheart">Back to Home</Link>
              </p>
            </div>
          </div>

          <div className="row align-items-center h-100 px-5 mx-5 mobile-show ">
        
            <div className="col-md-5 mx-auto ">
              <img
                src={notFound_web}
                alt="error"
                className="img-fluid mx-auto d-block"
                style={{ width: "800px", height: "600" }}
              />
              
            </div>
            <div className="col-md-5 mx-auto text-right  pr-5 mr-5">
              <h1 className="justify-content-center align-self-center">ERROR!</h1>
              <h4 className="justify-content-center align-self-center">We can't find that page</h4>
              <p style={{ textAlign: "right" }} className="mt-5">
                <Link to={`/dashboard/${this.state.encryptedString}`} className="btn bg-thisheart">Back to Home</Link>
              </p>
            </div>
          </div>

          <div className="row align-items-center h-100 px-5 mx-5 tab-show">
            <div className="col-md-5 mx-auto ">
              <img
                src={notFound_web}
                alt="error"
                className="img-fluid mx-auto d-block"
                style={{ width: "800px", height: "600" }}
              />
              
            </div>
            <div className="col-md-5 mx-auto text-right  pr-5 mr-5">
              <h1 className="justify-content-center align-self-center">ERROR!</h1>
              <h4 className="justify-content-center align-self-center">We can't find that page</h4>
              <p style={{ textAlign: "right" }} className="mt-5">
                <Link to={`/dashboard/${this.state.encryptedString}`} className="btn bg-thisheart">Back to Home</Link>
              </p>
            </div>
          </div>

        </div>

      </div>
    );
  }
}

export default ErrorPage;
