import React, { Component } from "react";

import "./styles/homeIndex.css";
import "animate.css/animate.min.css";
import ScrollAnimation from "react-animate-on-scroll";
import "bootstrap/dist/css/bootstrap.min.css";
// import logoLight from "../pages/images/thisheartweb/logolight.png";
import logoLight from "../pages/images/thisheart_group.svg";
// import logoLight from "../pages/images/thisheartweb/nlogo.png";
import memoriesGif from "../pages/images/thisheartweb/memories.gif";
import accountGif from "../pages/images/thisheartweb/accounts.gif";
import healthGif from "../pages/images/thisheartweb/health.gif";

import memoriesFeat from "../pages/images/thisheartweb/feature-memories.jpeg";
import accountsFeat from "../pages/images/thisheartweb/feature-accounts.jpeg";
import healthFeat from "../pages/images/thisheartweb/feature-health.jpeg";
import heroSvg from "../pages/images/banner-hero.png";
import axios from "axios";
import apiService from "./../services/api.service";
import IndexFooter from "./IndexFooter";
import { Helmet } from "react-helmet";

const apiUrl = apiService.apiUrl;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      newsLetter: "",
      successNewsLetter: "",
      errorNewsLetter: "",
    };
  }
  componentDidMount() {
    this.demoAsyncCall().then(() => this.setState({ loading: false }));
    //document.getElementById('pricing').scrollIntoView()
  }

  demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 3000));
  };

  newsLetterChange = (e) => {
    this.setState({
      newsLetter: e.target.value,
    });
  };

  submitNewsletter = (e) => {
    e.preventDefault();
    let newsEmail = this.state.newsLetter;
    axios.get(`${apiUrl}/api/web/newsletter/` + newsEmail).then((response) => {
      if (response.data.status === "success") {
        this.setState({
          errorNewsLetter: response.data.message,
        });
      } else {
        this.setState({
          errorNewsLetter: response.data.message,
        });
      }
    });
  };

  render() {
    // const { loading } = this.state;

    // if (loading) {
    //   return <img src={loaderImage} alt="loaderimg" className="preload" />;
    // }
    return (
      <div id="landing-home">
        <Helmet>
          <meta charSet="utf-8" />
          <meta property="og:type" content="article" />
          <meta
            property="og:description"
            content="A nice place to explore new and old things"
          />
          <title>A good stuff to explore | ThisHeart </title>
          <meta
            name="description"
            content="From the memories you’ve shared, to every day accounts; Thisheart lets you securely store all of your stuff in one beautiful pleace "
          />
          <link rel="canonical" href="https://thisheart.co/" />
        </Helmet>
        <div id="page-top" data-spy="scroll" data-target=".navbar">
          <div id="header" className="fixed-top ">
            <div className="container-fluid">
              <nav className="navbar navbar-expand-md navbar-light main-nav ">
                <a className="navbar-brand" href="/">
                  <img src={logoLight} alt="" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-toggle="collapse"
                  data-target="#navbarNavDropdown"
                  aria-controls="navbarNavDropdown"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon" />
                </button>
                <div
                  id="navbarNavDropdown"
                  className="navbar-collapse collapse"
                >
                  <ul className="navbar-nav mr-auto" />
                  <ul className="navbar-nav list-collapse">
                    <li>
                      <a href="#offer">
                        How it works
                        <span className="sr-only" />
                      </a>
                    </li>
                    <li>
                      <a href="#pricing">
                        How much?
                        <span className="sr-only" />
                      </a>
                    </li>
                    <li>
                      <a href="/login">
                        Log in
                        <span className="sr-only" />
                      </a>
                    </li>
                    <li>
                      <a href="/register">
                        Start for $0
                        <span className="sr-only" />
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>

        <div id="intro-this">
          <div className="container">
            <div className="row">
              <div className="intro-image col-md-7">
                <img src={heroSvg} alt="bannerphoto" className="img-fluid" />
              </div>
              <div className="intro-info col-md-5">
                <h1>
                  Leave behind <br />
                  the stuff that
                  <br />
                  matters most
                </h1>
                <div className="sub-p">
                  <p>
                    From the memories you’ve shared, to every day accounts.
                    ThisHeart lets you securely store all of your stuff in one
                    beautiful place where only the right people can access it.
                  </p>
                  <a className="color-text" href="#pricing">
                    Sign up today. The first 30 days are free.{" "}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="offer" className="container ft-centered">
          <div className="row section">
            <header className="sec-heading">
              <h2 className="head-txt">How it works</h2>
              <span className="subheading">
                THISHEART LETS YOU LEAVE BEHIND THE MOMENTS AND INFORMATION THAT
                YOUR LOVED ONES WILL NEED WHEN YOU’RE GONE. LET US HELP YOU
                LEAVE BEHIND THE LEGACY YOU DESERVE IN THIS BEAUTIFUL, SECURE,
                AND EASY TO USE APPLICATION. IT’S EASY AND THE FIRST 30 DAYS ARE
                ON US. CONTACT DETAILS FOR DOCTORS, LETTERS & NOTES TO YOUR FAMILY, 
                IMPORTANT ADDRESS, ACCOUNT DETAILS FOR UTILITIES
              </span>
            </header>

            <div className="col-md-4 col-sm-12 col-xs-12 mb-sm-50 ft-item ft-basic">
              <img src={memoriesGif} alt="memories" height="28%" width="28%" />
              <h5>Memories</h5>
              <p>
                From family photos and videos to recipes and audio recordings of
                your favorite song or story, ThisHeart is the perfect home for
                it all.
              </p>
            </div>

            <div className="col-md-4 col-sm-12 col-xs-12 mb-sm-50 ft-item ft-basic">
              <img src={accountGif} alt="accounts" height="27%" width="27%" />
              <h5>Accounts</h5>
              <p>
                Mortgage payments, credit cards, utility bills. Put them all in
                a single place so your loved ones can find them easily and have
                one less thing to worry about.
              </p>
            </div>

            <div className="col-md-4 col-sm-12 col-xs-12 mb-sm-50 ft-item ft-basic">
              <img src={healthGif} alt="health" height="28%" width="28%" />
              <h5>Health</h5>
              <p>
                Our medical history is important to us, and to them. Leave your
                medical history behind so they have a head start on their
                health.
              </p>
            </div>
          </div>
        </div>

        <div id="about">
          <div className="container">
            <div className="about-container">
              <div className="col-lg-6 content order-lg-1 order-2" />
              <div className="row about-extra">
                <div className="col-lg-6  order-1 order-lg-2  fadeInRight">
                  <ScrollAnimation animateIn="fadeInRight" style={{ animationDuration: "3s" }}>
                    <img src={memoriesFeat} alt="memoriesfeat" className="img-fluid" />
                  </ScrollAnimation>
                </div>

                <div className="col-lg-6  pt-4 pt-lg-0 order-2 order-lg-1 feature-cont-mem">
                  <h3>Memories</h3>
                  <h5 className="h-mem">
                    All of your memories in one secure and beautiful place
                  </h5>
                  <p>
                    ThisHeart lets you save all of your pictures, videos, audio
                    recordings, recipes, and more in one secure place where all
                    of the people you leave behind can access them easily and
                    privately. Let ThisHeart help you leave behind the memories
                    and legacy you’ve created your whole life.
                  </p>
                  <a href="/submemories" className="btn-learn">
                    Learn more
                  </a>
                </div>
              </div>
              <div className="clearfix"></div>
              <div className="row about-extra">
                <div className="col-lg-6">
                  <ScrollAnimation animateIn="fadeInLeft" style={{ animationDuration: "3s" }} className="h-100">
                    <img src={accountsFeat} className="img-fluid" alt="" />{" "}
                  </ScrollAnimation>
                </div>
                <div className="col-lg-6  pt-5 pt-lg-0 feature-cont-acnt">
                  <h3>Accounts</h3>
                  <h5 className="h-acnt">
                    Certain things will have to carry on when you’re gone
                  </h5>
                  <p>
                    From the mortgage payment to the utility bills - it all
                    needs to carry on when you’re gone. For the ones we love,
                    it’s important that we make account information easy to find
                    and easy to access. ThisHeart lets you easily leave behind
                    the account data that’s most important to them. All in one
                    simple and secure place.
                  </p>
                  <a href="/subaccount" className="btn-learn">
                    Learn more
                  </a>
                </div>
              </div>
              <div className="row about-extra">
                <div className="col-lg-6 order-1 order-lg-2">
                  <ScrollAnimation
                    animateIn="fadeInRight"
                    style={{ animationDuration: "3s" }}
                  >
                    <img src={healthFeat} className="img-fluid" alt="" />
                  </ScrollAnimation>
                </div>

                <div className="col-lg-6  pt-4 pt-lg-0 order-2 order-lg-1 feature-cont-health">
                  <h3>Health</h3>
                  <h5 className="h-health">
                    Your medical history all in a single, beautiful place
                  </h5>
                  <p>
                    The medical history of our parents, our partners, and
                    ourselves is an important part of our legacy. Leave behind
                    medical information that could help save or prolong the life
                    of your family members. ThisHeart’s data security and data
                    privacy will keep it all secure and will only ever share it
                    with the people you tell us to.
                  </p>
                  <a href="/subhealth" className="btn-learn">
                    Learn more
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        

        {/* start pricing area  */}
        <div id="pricing" className="pricing-area area-padding">
          <div className="container">
            <div className="price-title-head">
              <h2 className="text-center">Start leaving things behind today</h2>
              <span>The first 30 days are free </span>
            </div>
            <div className="row" style={{ display: 'flex', justifyContent: 'space-around' }}>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                <div className="pri_table_list" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <h3 className="mt-5 pt-3">STARTER</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">9.99</span>
                    <span className="type">\mo</span>
                  </div>
                  <p className="title-desc">TEST THE WATERS</p>
                  <hr />
                  <ol>
                    <li>10Gb of data storage</li>
                    <li>Store 10 accounts</li>
                    <li>Access to 2 beneficiaries</li>
                    <li>Email user support </li>
                  </ol>
                  <button type="submit" className="btn mb-4" style={{ background: "#e1c720" }}>
                    <a href="/register">START FOR FREE</a>
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                <div className="pri_table_list" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <div className="banner-pri">
                    <span className="star">
                      <i className="fa fa-heart" />
                    </span>
                  </div>
                  <h3 className="mt-5 pt-3">ACCESS</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">19.99</span>
                    <span className="type">\mo</span>
                  </div>
                  <p className="title-desc">ALL ACCESS</p>
                  <hr />
                  <ol>
                    <li>Unlimited data storage</li>
                    <li>Unlimited account storage</li>
                    <li>Unlimited beneficiary access</li>
                    <li>Email and chat support</li>
                  </ol>
                  <button type="submit" className="btn mb-4" style={{ background: "#e1c720" }}>
                    <a href="/register">START FOR FREE</a>
                  </button>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
                <div className="pri_table_list" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
                  <h3 className="mt-5 pt-3">FAMILY</h3>
                  <div className="price">
                    <span className="currency">$</span>
                    <span className="value">39.99</span>
                    <span className="type">\mo</span>
                  </div>
                  <p className="title-desc">
                    AN ACCOUNT FOR YOU AND 3 OF YOUR FAMILY MEMBERS
                  </p>
                  <hr />
                  <ol>
                    <li>All of the access plan features</li>
                    <li>For up to 3 of your family members.</li>
                    <li>That's 4 total accounts including yours</li>
                  </ol>
                  <button type="submit" className="btn mb-4" style={{ background: "#e1c720" }}>
                    <a href="/register">START FOR FREE</a>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End pricing table area */}
        <IndexFooter />
      </div>
    );
  }
}
export default Home;
