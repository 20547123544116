import React, { Component } from "react";
import Header from "./../components/Header";
import "./styles/hashtag.css";
import { Helmet } from "react-helmet";
import { Button } from "reactstrap";

class HashTagGenerate extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");

    this.state = {
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
    };
  }

  render() {
    return (
      <div className="container-fluid hash-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Hashtag Generate</title>
        </Helmet>
        <Header {...this.props} />
        <div className="text-fetch">
          <h2>SOCIAL FETCHING</h2>
          <p>
            Lorem ipsum dolor amet snackwave farm-to-table ramps letterpress pok
            pok roof party keffiyeh ennui irony salvia normcore man bun.
          </p>
          <div className="generate-tag">
            <Button className="tag-btn">Generate new hashtag</Button>
          </div>
          <div>
            <b>Your custom hashtag:</b>
          </div>
          <div>
            #thisheart-9jsa234aff
            <span>
              <Button className="copy-tag">copy tag</Button>
            </span>
          </div>
        </div>
      </div>
    );
  }
}
export default HashTagGenerate;
