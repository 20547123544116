import React, { Component } from "react";
import { Link } from "react-router-dom";
import loaderImg from "./images/loader.gif";
import "./styles/beneficiaries.css";
import "../components/asterisk.css";
import { userService } from "../services/user.service";
import { Helmet } from "react-helmet";
import { beneficiariesService } from "./../services/beneficiaries.service";
import InfiniteScroll from "react-infinite-scroll-component";
// import zeroState from "../pages/images/zerostate.png";
import zeroState from "../pages/images/benifizero.png";
import Header from "./../components/Header";
import { encrypt_url } from "../helpers/encrypt-url";
import lineOfColours from "./images/line_of_colours.png";
import btnPlus from "./images/btn-plus.svg";
import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  ModalFooter,
  Modal,
  ModalBody,
  ModalHeader,
  Popover,
  PopoverBody,
  PopoverHeader,
} from "reactstrap";
import LeftSidebar from "../components/LeftSidebar";

class Beneficiaries extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.toggle = this.toggle.bind(this);
    this.handleLogout = this.handleLogout.bind(this);
    this.toggleEditBenefe = this.toggleEditBenefe.bind(this);

    this.toggleProgressWindow = this.toggleProgressWindow.bind(this);

    this.toggleDeleteBenefe = this.toggleDeleteBenefe.bind(this);
    this.BenefeciaryPopEdit = this.BenefeciaryPopEdit.bind(this);
    this.deleteBenefeList = this.deleteBenefeList.bind(this);

    this.ResetBenefeciaryCode = this.ResetBenefeciaryCode.bind(this);
    this.SendNewBenefeciaryCode = this.SendNewBenefeciaryCode.bind(this);
    this.handleOTPSettings = this.handleOTPSettings.bind(this);

    this.state = {
      deleteID: 0,
      dropdownOpen: false,
      dataLoading: false,
      first_name: "",
      last_name: "",
      mail_address: "",
      mail_address2: "",
      city: "",
      state: "",
      last_4_beneficiary: "",
      zip: "",
      email: "",
      BenefeciaryList: [],
      fields: "",
      errors: {},
      btnActive: false,
      updateIndex: { in: "" },
      beneficiaryCodeHeader: "Reset Window",
      modalProgressWindow: false,
      codeSentDone: false,
      waitMessage: "",
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
      errorMSG: "",
      hasMoreBenefi: true,
      loadingBenefiCounter: 5,
      helpover: false,

      resetId: 0,

      encryptedString: encrypt_url(),
    };
  }

  componentDidMount() {
    let userId = this.state.userObj.data.user_id;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    beneficiariesService.getBeneficiaryData(userId, this);
  }

  togglePopOver = () => {
    this.setState({
      helpOver: !this.state.helpOver,
    });
  };

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  handleLogout() {
    if (this.state.dropdownOpen) {
      userService.logout(this.props);
    }
  }

  toggleEditBenefe() {
    this.setState((prevState) => ({
      editbenefe: !prevState.editbenefe,
      btnActive: false,
    }));
  }

  toggleProgressWindow() {
    this.setState((prevState) => ({
      modalProgressWindow: !prevState.modalProgressWindow,
    }));
  }

  toggleDeleteBenefe(deleteID) {
    console.log("delete id:  ", deleteID);
    this.setState((prevState) => ({
      deletebenefe: !prevState.deletebenefe,
      deleteID: deleteID,
    }));
  }

  resetCodeSend = (resetId) => {
    console.log("item id reset message:", resetId);
    this.setState((prevState) => ({
      resetCode: !prevState.resetCode,
      resetId: resetId,
    }));
  };

  newCodeSend = (resetId) => {
    console.log("item id new code message:", resetId);
    this.setState((prevState) => ({
      newCode: !prevState.newCode,
      resetId: resetId,
    }));
  };

  toggleProfilePhoto = () => {
    this.setState((prevState) => ({
      profilePhoto: !prevState.profilePhoto,
    }));
  };

  validateFname = () => {
    let first_name = this.state.first_name;
    let errors = "";
    let formIsValid = true;
    if (first_name.length === 0) {
      formIsValid = false;
      errors = "*Cannot Be Empty";
    }
    this.setState({
      errors: {
        ...this.state.errors,
        first_name: errors,
      },
    });
    return formIsValid;
  };

  validateLname = () => {
    let last_name = this.state.last_name;
    let errors = "";
    let formIsValid = true;
    if (last_name.length === 0) {
      formIsValid = false;
      errors = "*Cannot Be Empty";
    }
    this.setState({
      errors: {
        ...this.state.errors,
        last_name: errors,
      },
    });
    return formIsValid;
  };
  validateMaddress1 = () => {
    let mail_address = this.state.mail_address;
    let errors = "";
    let formIsValid = true;
    if (mail_address.length === 0) {
      formIsValid = false;
      errors = "*Cannot Be Empty";
    }
    this.setState({
      errors: {
        ...this.state.errors,
        mail_address: errors,
      },
    });
    return formIsValid;
  };

  validateCity = () => {
    let city = this.state.city;
    let errors = "";
    let formIsValid = true;
    if (city.length === 0) {
      formIsValid = false;
      errors = "*Cannot Be Empty";
    }
    this.setState({
      errors: {
        ...this.state.errors,
        city: errors,
      },
    });
    return formIsValid;
  };

  validateCityState = () => {
    let state = this.state.state;
    let errors = "";
    let formIsValid = true;
    if (state.length === 0) {
      formIsValid = false;
      errors = "*Cannot Be Empty";
    }
    this.setState({
      errors: {
        ...this.state.errors,
        state: errors,
      },
    });
    return formIsValid;
  };

  validateBeneficiary = () => {
    let last_4_beneficiary = this.state.last_4_beneficiary;
    let errors = "";
    let formIsValid = true;

    if (typeof last_4_beneficiary !== "undefined") {
      if (!last_4_beneficiary.match(/^[0-9]{4}$/)) {
        formIsValid = false;
        errors = "*Please enter 4 numeric number only.";
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        last_4_beneficiary: errors,
      },
    });
    return formIsValid;
  };

  validateZip = () => {
    let zip = this.state.zip;
    let errors = "";
    let formIsValid = true;

    if (typeof zip !== "undefined") {
      if (!zip.match(/^[0-9]{5}$/)) {
        formIsValid = false;
        errors = "*Please enter valid zip no.";
      } else if (zip.length !== 5) {
        formIsValid = false;
        errors = "*Please enter valid zip no.";
      }
    }
    this.setState({
      errors: {
        ...this.state.errors,
        zip: errors,
      },
    });
    return formIsValid;
  };

  disableBeneficiaryLink = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Link
          className="link-style-beneficiaries"
          to={`/beneficiaries/${this.state.encryptedString}`}
        >
          Beneficiaries
        </Link>
      );
    }
  };

  showAddNewBtn = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Link to={`/addbenefeciary/${this.state.encryptedString}`}>
          <Button className="btn-add-beneficiary bg-thisheart"><h5 className="mt-1" style={{ height: '15px' }}><img src={btnPlus} /> Add Beneficiary</h5></Button>
        </Link>
      );
    }
  };

  loadBenefiContent = () => {
    console.log("Event fired count:", this.state.loadingBenefiCounter);
    setTimeout(() => {
      if (this.state.loadingBenefiCounter < this.state.BenefeciaryList.length) {
        let benefiCounter = this.state.loadingBenefiCounter + 2;
        console.log("Fetch more benefiCounter:", benefiCounter);
        this.setState({ loadingBenefiCounter: benefiCounter });
      }
      if (
        this.state.loadingBenefiCounter >= this.state.BenefeciaryList.length
      ) {
        this.setState({ hasMoreBenefi: false });
      }
    }, 1000);
  };

  onDemandBenefiList = () => {
    return (
      <div className="container-fluid" style={{ marginLeft: '-5px' }}>
        <InfiniteScroll
          dataLength={this.state.loadingBenefiCounter}
          next={this.loadBenefiContent.bind(this)}
          hasMore={this.state.hasMoreBenefi}
        >
          {this.showBeneficiaryList()}
        </InfiniteScroll>
      </div>
    );
  };

  showBeneficiaryList = () => {
    let BenefeciaryList = [];

    let tempBenefiList = [];
    let benefiCounter = 0;

    this.state.BenefeciaryList.map((item) => {
      if (benefiCounter <= this.state.loadingBenefiCounter) {
        tempBenefiList.push(item);
      }
      benefiCounter = benefiCounter + 1;
    });

    if (tempBenefiList.length !== 0) {
      if (this.state.userType === "bn") {
        BenefeciaryList = (
          <div className="listshow container-fluid">
            <div className="row line-bottom">
              <div className="col-md-1">Name</div>
              <div className="col-md-3">Address</div>
              <div className="col-md-4">Email</div>
              <div className="col-md-4"></div>
            </div>
            <div className="row sep-line">
              {tempBenefiList.map((blist, i) => (
                <React.Fragment key={i}>
                  <div className="row border-bottom pt-3 pb-3">
                    <div className="col-xs-1 col-sm-1 list-itemtable">
                      {blist.first_name + "  " + blist.last_name}
                    </div>

                    <div className="col-xs-3 col-sm-3 list-itemtable">
                      {blist.mail_address +
                        (blist.mail_address ? ", " : " ") +
                        blist.mail_address2 +
                        (blist.mail_address2 ? ", " : " ") +
                        blist.city +
                        (blist.city ? ", " : " ") +
                        blist.state +
                        (blist.state ? ", " : " ") +
                        blist.zip}
                    </div>
                    <div className="col-xs-4 col-sm-4 list-itemtable">
                      {blist.email}
                    </div>
                    <div className="col-xs-4 col-sm-4 "></div>
                    <hr />
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        BenefeciaryList = (
          <div className="listshow container-fluid">
            <div className="row line-bottom">
              <div className="col-md-2">Name</div>
              <div className="col-md-3">Address</div>
              <div className="col-md-3">Email</div>
              <div className="col-md-4"></div>
            </div>
            <div className=" sep-line">
              {tempBenefiList.map((blist, i) => (
                <React.Fragment key={i}>
                  <div className="row border-bottom pt-3 pb-3 beneficiaries-list">
                    <div className="col-xs-1 col-sm-1 list-itemtable bninfo-pad">
                      {blist.first_name + "  " + blist.last_name}
                    </div>

                    <div className="col-xs-3 col-sm-3 list-itemtable bninfo-pad">
                      {blist.mail_address +
                        (blist.mail_address ? ", " : " ") +
                        blist.mail_address2 +
                        (blist.mail_address2 ? ", " : " ") +
                        blist.city +
                        (blist.city ? ", " : " ") +
                        blist.state +
                        (blist.state ? ", " : " ") +
                        blist.zip}
                    </div>
                    <div className="col-xs-4 col-sm-4 list-itemtable bninfo-pad">
                      {blist.email}
                    </div>
                    <div className="col-xs-4 col-sm-4 btn-action">
                      <div className="row d-flex">
                        <span onClick={this.resetCodeSend.bind(this, blist.id)} className="btn-danger pt-1 pl-2 pb-1 pr-2 mr-2" style={{ borderRadius: '5px', textWrap: 'nowrap', cursor: 'pointer' }}>Reset</span>
                        <span onClick={this.newCodeSend.bind(this, blist.id)} className="btn-dark  pt-1 pl-2 pb-1 pr-2 mr-2" style={{ borderRadius: '5px', textWrap: 'nowrap', cursor: 'pointer' }}>New Code</span>
                        <span onClick={this.BenefeciaryPopEdit.bind(this, blist, i)} className="bg-thisheart  pt-1 pl-3 pb-1 pr-3 mr-2" style={{ borderRadius: '5px', cursor: 'pointer' }}>Edit</span>
                        <span onClick={this.toggleDeleteBenefe.bind(this, blist.id)} className="btn-secondary  pt-1 pl-2 pb-1 pr-2" style={{ borderRadius: '5px', cursor: 'pointer' }}>Delete</span>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    } else {
      if (this.state.dataLoading) {
        BenefeciaryList = (
          <p className="empty-msg-mem">Loading Beneficiary Data...</p>
        );
      } else {
        BenefeciaryList = (
          <div className="container">
            <div className="row ">
              <img
                className="img-fluid mx-auto d-block"
                src={zeroState}
                alt="love-pat"
              />
            </div>
            <div className="row ">
              <div className="emptylist">
                <Link to={`/addbenefeciary/${this.state.encryptedString}`}>
                  Add <span>new </span>
                  Beneficiary
                </Link>
              </div>
            </div>
          </div>
        );
      }
    }

    return BenefeciaryList;
  };

  ResetBenefeciaryCode(e, i) {
    // let itemId = e.id;
    let itemId = this.state.resetId;
    this.setState({ codeSentDone: false });
    this.setState({ modalProgressWindow: true });
    this.setState({ beneficiaryCodeHeader: "Reset Code" });
    this.setState({
      waitMessage: "Reset beneficiary code in progress, please wait...",
      resetCode: false,
    });

    beneficiariesService.resetBeneficiaryCode(itemId, this);
  }

  SendNewBenefeciaryCode(e, i) {
    //let itemId = e.id;
    let itemId = this.state.resetId;
    this.setState({ codeSentDone: false });
    this.setState({ modalProgressWindow: true });
    this.setState({ beneficiaryCodeHeader: "Send New Code" });
    this.setState({
      waitMessage: "Send new beneficiary code in progress, please wait...",
      newCode: false,
    });

    beneficiariesService.sendNewBeneficiaryCode(itemId, this);
  }

  BenefeciaryPopEdit(e, i) {
    let first_name = this.state.first_name;
    let last_name = this.state.last_name;
    let mail_address = this.state.mail_address;
    let mail_address2 = this.state.mail_address2;
    let city = this.state.city;
    let last_4_beneficiary = this.state.last_4_beneficiary;
    let state = this.state.state;
    let zip = this.state.zip;
    let email = this.state.email;

    first_name = e.first_name;
    last_name = e.last_name;
    mail_address = e.mail_address;
    mail_address2 = e.mail_address2;
    city = e.city;
    last_4_beneficiary = e.last_4_beneficiary;
    state = e.state;
    zip = e.zip;
    email = e.email;

    this.setState({ first_name });
    this.setState({ last_name });
    this.setState({ mail_address });
    this.setState({ mail_address2 });
    this.setState({ city });
    this.setState({ last_4_beneficiary });
    this.setState({ state });
    this.setState({ zip });
    this.setState({ email });
    this.setState({ updateIndex: { i } });

    this.toggleEditBenefe();
  }

  onChangeFirstName = (e) => {
    this.setState(
      {
        first_name: e.target.value,
        btnActive: true,
      },
      () => {
        this.validateFname();
      }
    );
  };

  onChangeLastName = (e) => {
    this.setState(
      {
        last_name: e.target.value,
        btnActive: true,
      },
      () => {
        this.validateLname();
      }
    );
  };

  onChangeAddressOne = (e) => {
    this.setState(
      {
        mail_address: e.target.value,
        btnActive: true,
      },
      () => {
        this.validateMaddress1();
      }
    );
  };

  onChangeAddressTwo = (e) => {
    this.setState({
      mail_address2: e.target.value,
      btnActive: true,
    });
  };

  onChangeCity = (e) => {
    this.setState(
      {
        city: e.target.value,
        btnActive: true,
      },
      () => {
        this.validateCity();
      }
    );
  };

  onChangeBenefit = (e) => {
    this.setState(
      { last_4_beneficiary: e.target.value, btnActive: true },
      () => {
        this.validateBeneficiary();
      }
    );
  };

  onChangeStateCity = (e) => {
    this.setState(
      {
        state: e.target.value,
        btnActive: true,
      },
      () => {
        this.validateCityState();
      }
    );
  };

  onChangeZip = (e) => {
    this.setState(
      {
        zip: e.target.value,
        btnActive: true,
      },
      () => {
        this.validateZip();
      }
    );
  };

  onChangeEmail = (e) => {
    this.setState({
      email: e.target.value,
      btnActive: true,
    });
  };

  saveChangesList = (e) => {
    e.preventDefault();
    let item_index = this.state.updateIndex;

    let errors = this.state.errors;
    if (Object.keys(errors).length > 0) {
      for (let error in errors) {
        if (errors[error]) {
          console.log("error outpur: ", errors[error]);
          return false;
        }
      } //end for
    }

    let BenefeciaryList = this.state.BenefeciaryList;
    let itemId = BenefeciaryList[item_index.i];
    let first_name = document.getElementById("firstname").value;
    let last_name = document.getElementById("lastname").value;
    let mail_address = document.getElementById("mail_address").value;
    let mail_address2 = document.getElementById("mail_address2").value;
    let city = document.getElementById("city").value;
    let state = document.getElementById("state").value;
    let last_4_beneficiary = document.getElementById("last_4_beneficiary")
      .value;
    let zip = document.getElementById("zip").value;
    let email = document.getElementById("email").value;

    BenefeciaryList[item_index.i] = {
      id: itemId.id,
      first_name: first_name,
      last_name: last_name,
      mail_address: mail_address,
      mail_address2: mail_address2,
      city: city,
      state: state,
      last_4_beneficiary: last_4_beneficiary,
      zip: zip,
      email: email,
    };

    this.setState({ editbenefe: false });
    console.log("edit id:", itemId.id);
    let userId = this.state.userObj.data.user_id;

    beneficiariesService.updateBeneficiaryData(
      userId,
      itemId.id,
      first_name,
      last_name,
      mail_address,
      mail_address2,
      city,
      state,
      last_4_beneficiary,
      zip,
      email
    );
    this.setState({ btnActive: false });
  };

  handleOTPSettings() {
    this.props.history.push("/resettwofaaskpass");
  }

  deleteBenefeList() {
    const tempId = this.state.deleteID;
    let successDel = beneficiariesService.deleteBeneficiaryData(tempId);
    if (successDel) {
      const BenefeciaryList = this.state.BenefeciaryList.filter(
        (listitem) => listitem.id !== tempId
      );
      this.setState({ BenefeciaryList });
      this.setState({ deletebenefe: false });
    } else {
      window.alert("Delete failed");
    }
  }

  render() {
    const blist = this.state.blist;
    return (
      // <div className="benefi-container">
      <div className="home-container" id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart-Beneficiaries</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>
          <div className="container-fluid">
            <Row>
              <Col sm="8" className="p-4 ben-alignment">
                <div className="enable-2fa-header">
                  <h1 className="account-header mr-3">BENEFICIARIES</h1>
                  {/* <img className="d-flex align-items-center sm-hide" src={lineOfColours} alt="line of colours" /> */}
                </div>

                <p className="mt-2">
                  Here you’ll add your beneficiaries. Your beneficiaries are the
                  people who will have access to your info when you go.
                </p>
              </Col>
              <Col sm="4" className="link-column" />
            </Row>
          </div>

          <div className="beneficiary-tab">
            <div className="row">
              <div className="col-sm-12 col-xs-12">{this.showAddNewBtn()}</div>
            </div>
            <div className="col-md-12 pl-2"><hr style={{ marginLeft: '7px' }} /></div>
            <div className="col-md-12">{this.onDemandBenefiList()}</div>

            {/* *************************** Edit item for Benefeciary start  ****************************/}
            <div>
              <Modal
                isOpen={this.state.editbenefe}
                toggle={this.toggleEditBenefe}
                className={this.props.className + ' modal-lg'}
              >
                <ModalHeader toggle={this.toggleEditBenefe}>
                  Edit Beneficiaries
                </ModalHeader>
                <ModalBody className="form-popup">
                  <div className="formmodaledit">
                    <Form method="post" onSubmit={this.saveChangesList}>
                      <Row form>
                        <Col md={6}>
                          <FormGroup className="required">
                            <Label className="view-label-benef control-label">
                              First Name
                            </Label>
                            <Input
                              className="controlform"
                              type="text"
                              name="first_name"
                              id="firstname"
                              required
                              value={this.state.first_name}
                              onChange={this.onChangeFirstName}
                            />
                            <div className="errormessage">
                              {this.state.errors.first_name}
                            </div>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="required">
                            <Label className="view-label-benef control-label">
                              Last Name
                            </Label>
                            <Input
                              className="controlform"
                              type="text"
                              name="last_name"
                              id="lastname"
                              required
                              value={this.state.last_name}
                              onChange={this.onChangeLastName}
                            />
                            <div className="errormessage">
                              {this.state.errors.last_name}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup className="required">
                            <Label className="view-label-benef control-label">
                              Mailing Address
                            </Label>
                            <Input
                              className="controlform"
                              type="text"
                              name="mAddress"
                              id="mail_address"
                              required
                              value={this.state.mail_address}
                              onChange={this.onChangeAddressOne}
                            />
                            <div className="errormessage">
                              {this.state.errors.mail_address}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6}>
                          <FormGroup>
                            <Label className="view-label-benef">
                              Mailing Address 2
                            </Label>
                            <Input
                              className="controlform"
                              type="text"
                              name="mAddresstwo"
                              id="mail_address2"
                              value={this.state.mail_address2}
                              onChange={this.onChangeAddressTwo}
                            />
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={6}>
                          <FormGroup className="required">
                            <Label className="view-label-benef control-label">
                              City
                            </Label>
                            <Input
                              className="controlform"
                              type="text"
                              name="city"
                              id="city"
                              required
                              maxLength="15"
                              value={this.state.city}
                              onChange={this.onChangeCity}
                            />
                            <div className="errormessage">
                              {this.state.errors.city}
                            </div>
                          </FormGroup>
                        </Col>

                        <Col md={6}>
                          <FormGroup className="required">
                            <Label className="view-label-benef control-label">
                              Email Address
                            </Label>
                            <Input
                              className="controlform"
                              type="email"
                              name="email"
                              id="email"
                              required
                              value={this.state.email}
                              onChange={this.onChangeEmail}
                              readOnly
                            />
                            <div className="errormessage">
                              {this.state.errors.email}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row form>
                        <Col md={8}>
                          <FormGroup className="required">
                            <Label className="view-label-benef control-label">
                              Last 4 of beneficiary's social
                            </Label>
                            <span id="Popover" style={{ cursor: "pointer" }}>
                              uh,why do you need this?
                            </span>
                            <Popover
                              placement="top"
                              isOpen={this.state.helpOver}
                              target="Popover"
                              toggle={this.togglePopOver}
                            >
                              <PopoverHeader>
                                Do need this?
                                <span
                                  className="dismissPop"
                                  onClick={this.togglePopOver}
                                >
                                  x
                                </span>
                              </PopoverHeader>
                              <PopoverBody>
                                In the USA, a Social Security Number (SSN) is
                                essential for employment, to pay taxes,
                                and to access various government services.
                                It also serves as a universal identification
                                for financial transactions and legal processes.
                              </PopoverBody>
                            </Popover>
                            <Input
                              className="controlform"
                              type="number"
                              name="last_4_beneficiary"
                              id="last_4_beneficiary"
                              required
                              value={this.state.last_4_beneficiary}
                              onChange={this.onChangeBenefit}
                            />
                            <div className="errormessage">
                              {this.state.errors.last_4_beneficiary}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <FormGroup className="required">
                            <Label className="view-label-benef control-label">
                              State
                            </Label>
                            <Input
                              className="controlform"
                              type="text"
                              name="state"
                              id="state"
                              required
                              value={this.state.state}
                              onChange={this.onChangeStateCity}
                            />
                            <div className="errormessage">
                              {this.state.errors.state}
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={4}>
                          <FormGroup className="required">
                            <Label className="view-label-benef control-label">
                              Zip
                            </Label>
                            <Input
                              className="controlform"
                              type="number"
                              name="zip"
                              id="zip"
                              required
                              value={this.state.zip}
                              onChange={this.onChangeZip}
                            />
                            <div className="errormessage">
                              {this.state.errors.zip}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Button
                        type="submit"
                        disabled={this.state.btnActive === false}
                        style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                      >
                        Save Changes
                      </Button>
                    </Form>
                  </div>
                </ModalBody>
              </Modal>
            </div>
            {/**************************** Edit item for Benefeciary End ****************************/}

            <div>
              <Modal
                isOpen={this.state.modalProgressWindow}
                className={this.props.className}
              >
                <ModalHeader onClick={this.toggleProgressWindow}>
                  {this.state.beneficiaryCodeHeader}
                </ModalHeader>

                <ModalBody>
                  <div>
                    <p className="empty-msg-mem">{this.state.waitMessage}</p>
                    {!this.state.codeSentDone && (
                      <img
                        className="loader-img"
                        alt="loaderImg"
                        src={loaderImg}
                      />
                    )}
                    {this.state.errorMSG}
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="danger" onClick={this.toggleProgressWindow}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>

            {/* /****************************** Delete Custom Pop Up Start ****************************/}

            <div>
              <Modal
                isOpen={this.state.deletebenefe}
                toggle={this.toggleDeleteBenefe}
                className={this.props.className}
              >
                <ModalHeader toggle={this.toggleDeleteBenefe}>
                  Delete Beneficiary
                </ModalHeader>

                <ModalBody className="form-popup">
                  <p>Are you sure you want to delete this account?</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    type="button"
                    onClick={this.deleteBenefeList.bind(this, blist)}
                  >
                    Delete
                  </Button>
                  <Button
                    type="button"
                    onClick={this.toggleDeleteBenefe}
                    style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>

            {/* /****************************** Delete Custom Pop Up End ****************************/}
            {/********************  Reset and New code modal confirm  **********************************/}
            <div>
              <Modal
                isOpen={this.state.resetCode}
                toggle={this.resetCodeSend}
                className={this.props.className}
              >
                <ModalHeader toggle={this.resetCodeSend}>
                  Reset Code
                </ModalHeader>

                <ModalBody className="form-popup">
                  <p>Are you sure you want to generate a reset code?</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    type="button"
                    onClick={this.ResetBenefeciaryCode.bind(this, blist)}
                  >
                    Confirm
                  </Button>
                  <Button
                    type="button"
                    onClick={this.resetCodeSend}
                    style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>

            <div>
              <Modal
                isOpen={this.state.newCode}
                toggle={this.newCodeSend}
                className={this.props.className}
              >
                <ModalHeader toggle={this.newCodeSend}>New Code</ModalHeader>

                <ModalBody className="form-popup">
                  <p>Are you sure you want to generate a new code?</p>
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="danger"
                    type="button"
                    onClick={this.SendNewBenefeciaryCode.bind(this, blist)}
                  >
                    Confirm
                  </Button>
                  <Button
                    type="button"
                    onClick={this.newCodeSend}
                    style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                  >
                    Cancel
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
            {/* Reset and New code modal confirm end */}
          </div>
        </div>
      </div>
    );
  }
}

export default Beneficiaries;
