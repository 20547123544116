import React, { Component } from "react";

import "./styles/homeIndex.css";
import "animate.css/animate.min.css";

// import loaderImage from "../pages/images/thisheartweb/health.gif";
// import "./styles/homepage.css";
import "bootstrap/dist/css/bootstrap.min.css";

import axios from "axios";
import apiService from "./../services/api.service";
import CookieConsent from "react-cookie-consent";

import { Modal, ModalBody, Button } from "reactstrap";

const apiUrl = apiService.apiUrl;

class IndexFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      newsLetter: "",
      successNewsLetter: "",
      errorNewsLetter: "",
    };
  }
  componentDidMount() {
    this.demoAsyncCall().then(() => this.setState({ loading: false }));
  }

  demoAsyncCall = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 3000));
  };
  validEmailFormat = (e) => {
    let emailFormat = e.target.value;
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(emailFormat)) {
      console.log("emailFormat error");
      return false;
    } else {
      console.log("emailFormat correct");
      return true;
    }
  };

  newsLetterChange = (e) => {
    this.setState({
      newsLetter: e.target.value,
    });
    if (this.validEmailFormat(e)) {
      this.setState({
        errorNewsLetter: "",
      });
    } else {
      this.setState({
        errorNewsLetter: "Please input valid email!",
      });
    }
  };

  submitNewsletter = (e) => {
    e.preventDefault();
    let newsEmail = this.state.newsLetter;
    if (this.state.errorNewsLetter.length > 0) {
      return false;
    }
    axios.get(`${apiUrl}/api/web/newsletter/` + newsEmail).then((response) => {
      if (response.data.status === "success") {
        this.setState({
          errorNewsLetter: response.data.message,
        });
      } else {
        this.setState({
          errorNewsLetter: response.data.message,
        });
      }
    });
  };

  render() {
    // const { loading } = this.state;

    // if (loading) {
    //   return <img src={loaderImage} alt="loaderimg" className="preload" />; // render null when app is not ready
    // }
    return (
      <div id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-4 footer-info footer-contact footer-links">
                <h5>About Us</h5>

                <ul>
                  <li>
                    <a href="/career"> Careers</a>
                  </li>
                  <li>
                    <a href="/security">Security</a>
                  </li>

                  <li>
                    <a href="/contact">Contact Us</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-4 footer-contact footer-links">
                <h5>Resources</h5>
                <ul>
                  <li>
                    <a href="/pricingplan">Pricing Your Plan</a>
                  </li>
                  {/* <li>
                    <a href="/aboutus">About us</a>
                  </li> */}

                  <li>
                    <a href="/privacypolicy">Privacy policy</a>
                  </li>
                </ul>
                {/* <div className="social-links">
                  <a href="/#" className="twitter">
                    <i className="fa fa-twitter" />
                  </a>
                  <a href="/#" className="facebook">
                    <i className="fa fa-facebook" />
                  </a>
                  <a href="/#" className="instagram">
                    <i className="fa fa-instagram" />
                  </a>

                  <a href="/#" className="linkedin">
                    <i className="fa fa-linkedin" />
                  </a>
                </div> */}
              </div>

              <div className="col-lg-4 col-md-4 footer-newsletter">
                <h5>Our Newsletter</h5>
                <p>
                  Sign up today to receive updates about ThisHeart and our
                  mission to change the way families talk about and prepare for
                  the inevitability of death.
                </p>
                <form action="" method="post" onSubmit={this.submitNewsletter}>
                  <div className="form-group">
                    <input
                      type="email"
                      name="w-newssletter"
                      placeholder="Join our newsletter"
                      onChange={this.newsLetterChange}
                      required
                    />
                    <button type="submit">
                      <i className="fa fa-paper-plane" />
                    </button>
                    <span className="text-warning">
                      {this.state.errorNewsLetter}
                    </span>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            <div className="row">
              <div className="col-sm-6 col-lg-6">
                <small>
                  &copy; 2020 THISHEART LLC.
                  {/* MADE IN ATLANTA &amp; DHAKA */}
                </small>
              </div>

              <div className="col-sm-6 col-lg-6">
                <small>
                  <a href="#page-top" className="pull-right to-the-top">
                    To the top
                    <i className="fa fa-angle-up" />
                  </a>
                </small>
              </div>
            </div>
          </div>

          <Modal isOpen={this.state.modalCookie} className="modal-lg">
            <ModalBody className="form-popup">
              <strong
                className="float-right modal-dismissible"
                style={{ cursor: "pointer" }}
                onClick={this.modalCookie}
              >
                <a href="#" >&times;</a>
              </strong>
              <strong>Cookie Notice</strong>
              <p>
                <small>
                  We use cookies to offer you a great browsing experience,
                  personalize content, and serve targeted advertisements. If you
                  continue to use this site, you consent to our use of cookies.
                </small>
              </p>
              <Button
                color="secondary"
                className="float-right"
                size="sm"
                onClick={this.modalCookie}
              >
                GOT IT
              </Button>
            </ModalBody>
          </Modal>

          <CookieConsent
            location="bottom"
            buttonText="GOT IT!!"
            cookieName="myAwesomeCookieName2"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
          >
            We use cookies to offer you a great browsing experience, personalize
            content, and serve targeted advertisements. If you continue to use
            this site, you consent to our use of cookies.{" "}
          </CookieConsent>
        </div>
      </div>
    );
  }
}
export default IndexFooter;
