import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Label, FormGroup, Input, Row, Col } from "reactstrap";

import "./styles/benefiboarding.css";
import "../components/asterisk.css";

import { Helmet } from "react-helmet";
import { beneficiariesService } from "./../services/beneficiaries.service";
import loaderImg from "./images/loader.gif";
import { encrypt_url } from "../helpers/encrypt-url";
import { ToastsStore, ToastsContainer } from "react-toasts";
import step5 from "./images/Step5.png";
import step5pd from "./images/Step5pd.png";


class BenefiOnBoarding extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");

    this.state = {
      addMore: false,
      userType: localStorage.getItem("user_type"),
      userObj: JSON.parse(user),
      loading: false,
      email: "",
      first_name: "",
      last_name: "",
      last_4_social: "",
      mail_address: "",
      errorEmail: "",
      errorFirstName: "",
      errorLastName: "",
      errorLast4Social: "",
      errorAddress: "",
      encryptedString: encrypt_url(),
    };
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  handleChange = (e) => {
    let { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  pageRedirectPrevious = () => {
    this.props.history.push("/onboardlife_status");
  };

  pageRedirectNext = () => {
    this.props.history.push("/onboardhealth");
    return 0;
  };

  onSaveBeneficiary = () => {
    let {
      email,
      first_name,
      last_name,
      last_4_social,
      mail_address,
    } = this.state;
    let errors = false;
    this.setState({ loading: true });
    if (!email) {
      this.setState({
        errorEmail: "Email is required",
      });
      errors = true;
    } else {
      this.setState({
        errorEmail: "",
      });
    }

    if (!first_name) {
      this.setState({
        errorFirstName: "First name is required",
      });
      errors = true;
    } else {
      this.setState({
        errorFirstName: "",
      });
    }

    if (!last_name) {
      this.setState({
        errorLastName: "Last name is required",
      });
      errors = true;
    } else {
      this.setState({
        errorLastName: "",
      });
    }

    if (!last_4_social || last_4_social.length < 4) {
      this.setState({
        errorLast4Social: "Last 4 digits of social is required",
      });
      errors = true;
    } else {
      this.setState({
        errorLast4Social: "",
      });
    }

    if (!mail_address) {
      this.setState({
        errorAddress: "Address is required",
      });
      errors = true;
    } else {
      this.setState({
        errorAddress: "",
      });
    }

    if (errors) {
      this.setState({ loading: false });
      return false;
    }

    let data = {
      email: this.state.email,
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      last_4_social: this.state.last_4_social,
      mail_address: this.state.mail_address,
    };
    beneficiariesService.saveBeneficiaryOnBoard(this, data).then(
      (response) => {
        this.setState({ loading: false });
        console.log("Address value received:", mail_address);
        if (response.data.status === "success") {
          //this.props.history.push("/onboardhealth");
          ToastsStore.success(
            "Beneficiary information has been saved successfully!"
          );
        }
      },
      (error) => {
        this.setState({ loading: false });
        console.log(error);
        ToastsStore.error("Sorry, Beneficiary information hasn't been saved!");
      }
    );
  };

  goToAddMoreBeneficiary = (e) => {
    e.preventDefault();
    localStorage.setItem("twofa", true);
    this.props.history.push(`/addbenefeciary/${this.state.encryptedString}`);
  };

  gotoSkip = (e) => {
    e.preventDefault();
    localStorage.setItem("twofa", true);
    this.props.history.push(`/accounts/${this.state.encryptedString}`);
  };

  render() {
    return (
      <div className="container-fluid benefi-container mt-4">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - OnBoarding Beneficiaries</title>
        </Helmet>
        <div className="row container m-auto">
          <div className="col-lg-6 col-md-2 pr-0">
            <img className="img-fluid d-none d-lg-block" src={step5} alt="benefi" />
            <img className="img-fluid d-md-block d-lg-none d-none" src={step5pd} alt="benefi" />
          </div>
          <div className="col-lg-6 col-12 col-md-10">
            <div className="">
              <h4 className="d-block d-lg-none d-md-none">05</h4>

              <h4>
                SELECT BENEFICIARIES
              </h4>
              <p>
                Tell us who should see all of your stuff when you’re gone.
                Let’s start with just a couple names for now and we’ll add
                more details later.
              </p>
              <Form className="">
                <Row>
                  <Col lg="6" md="12" sm="12">
                    <FormGroup className="required mb-1">
                      <Label className="lbl-item control-label text-start">
                        First Name
                      </Label>
                      <Input
                        type="text"
                        name="first_name"
                        id="first_name"
                        placeholder="First Name"
                        onChange={this.handleChange}
                      />
                      {this.state.errorFirstName && (
                        <div className="alert alert-warning">
                          {this.state.errorFirstName}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="required mb-1">
                      <Label className="lbl-item control-label">
                        Last Name
                      </Label>
                      <Input
                        type="text"
                        name="last_name"
                        id="last_name"
                        placeholder="Last Name"
                        onChange={this.handleChange}
                      />
                      {this.state.errorLastName && (
                        <div className="alert alert-warning">
                          {this.state.errorLastName}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col lg="6">
                    <FormGroup className="required mb-1">
                      <Label className="lbl-item control-label">Email</Label>
                      <Input
                        type="email"
                        name="email"
                        id="email"
                        placeholder="Email"
                        onChange={this.handleChange}
                      />
                      {this.state.errorEmail && (
                        <div className="alert alert-warning">
                          {this.state.errorEmail}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6">
                    <FormGroup className="required mb-1">
                      <Label className="lbl-item control-label">
                        Last 4 Social
                      </Label>
                      <Input
                        type="text"
                        name="last_4_social"
                        id="last_4_social"
                        maxLength="4"
                        placeholder="Last 4 digit"
                        onChange={this.handleChange}
                      />
                      {this.state.errorLast4Social && (
                        <div className="alert alert-warning">
                          {this.state.errorLast4Social}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    {" "}
                    <FormGroup className="required">
                      <Label className="lbl-item control-label">
                        Address
                      </Label>
                      <Input
                        type="text"
                        name="mail_address"
                        id="mail_address"
                        placeholder="Address"
                        onChange={this.handleChange}
                        required
                      />
                      {this.state.errorAddress && (
                        <div className="alert alert-warning">
                          {this.state.errorAddress}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col lg="6"></Col>
                </Row>

                {/* <span className="add-more">Add more +</span> */}
                <span className="add-more">
                  <Button className="btn-ben" onClick={this.onSaveBeneficiary}>
                    Save
                  </Button>

                  <Button
                    style={{ borderRadius: "7px" }}
                    className="btn btn-light btn-link pull-right text-dark"
                    onClick={this.goToAddMoreBeneficiary}
                  >
                    Add more +
                  </Button>
                </span>
              </Form>
              <div id="image-res">
                <div className="prev-next-benefi">
                  {this.state.loading && (
                    <img
                      className="loader-img"
                      alt="loaderImg"
                      src={loaderImg}
                    />
                  )}
                  <div className="row">
                    <div className="col-9">
                      <Button
                        style={{ borderRadius: "7px", padding: "10px 20px", color: "#000", fontWeight: "500" }}
                        onClick={this.pageRedirectPrevious}
                      >
                        <i class="fa fa-solid fa-arrow-left mr-1"></i> Prev
                      </Button>

                      <Button
                        style={{ borderRadius: "7px", padding: "10px 20px", color: "#000", fontWeight: "500" }}
                        className="ml-3"
                        onClick={this.pageRedirectNext}
                      >
                        Next <i class="fa fa-solid fa-arrow-right mr-1"></i>
                      </Button>
                    </div>

                    <div className="col-3 d-flex justify-content-end align-items-center">
                      <Link className="btn btn-secondary text-dark" style={{ borderRadius: "7px" }} to={`/dashboard/${this.state.encryptedString}`}>
                        {localStorage.setItem("twofa", true)}
                        Skip
                      </Link>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastsContainer store={ToastsStore} />
      </div>
    );
  }
}

export default BenefiOnBoarding;
