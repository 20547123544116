import React, { Component } from "react";
import { Link } from "react-router-dom";
import lineOfColours from "./images/line_of_colours.png";
import {
  FaDollarSign,
  FaBalanceScale,
  FaHeart,
  FaSearch,
  FaEyeSlash,
  FaEye,
} from "react-icons/fa";
import "font-awesome/css/font-awesome.min.css";
import "../components/asterisk.css";
import { Helmet } from "react-helmet";
import InfiniteScroll from "react-infinite-scroll-component";
// import zeroState from "../pages/images/zerostate.png";
import zeroState from "../pages/images/accountzero.jpg";
import insurance from "../pages/images/insurance-icon.png";
import iconView from "../pages/images/icon-view.svg";
import iconEdit from "../pages/images/icon-edit.svg";
import iconDelete from "../pages/images/icon-delete.svg";

import Header from "./../components/Header";

import {
  Col,
  Row,
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from "reactstrap";
import "./styles/accounts.css";
import { accountsService } from "./../services/accounts.service";
import { ToastsStore } from "react-toasts";
import LeftSidebar from "../components/LeftSidebar";

class Accounts extends Component {
  constructor(props) {
    super(props);

    let user = localStorage.getItem("user");
    this.toggle = this.toggle.bind(this);
    this.Btnhandler = this.Btnhandler.bind(this);
    this.formDetails = this.formDetails.bind(this);
    this.showAccountList = this.showAccountList.bind(this);
    this.handleAddform = this.handleAddform.bind(this);
    this.toggleViewAccount = this.toggleViewAccount.bind(this);
    this.viewAccount = this.viewAccount.bind(this);
    this.toggleEditAccount = this.toggleEditAccount.bind(this);
    this.editAccount = this.editAccount.bind(this);
    this.toggleDeleteAccount = this.toggleDeleteAccount.bind(this);
    this.deleteAccount = this.deleteAccount.bind(this);
    this.accountTypeFilter = this.accountTypeFilter.bind(this);
    this.eyeIcon = this.eyeIcon.bind(this);

    this.state = {
      cancelBtn: false,
      fmBtnStatus: false,
      dropdownOpen: false,
      dataLoading: false,
      errors: [],
      passValue: "password",
      userValue: "password",
      formEyeValue: "password",
      viewPass: "password",
      accName: "",
      desc: "",
      accountType: "",
      selectedAccountType: "all",
      accNumber: "",
      accUrl: "",
      accEditUrl: "",
      username: "",
      password: "",
      accountListTempFiltered: [],
      accountListFiltered: [],
      accountList: [],
      uploadBtnSts: "false",
      updateIndex: 0,
      editId: 0,
      userObj: JSON.parse(user),
      deleteID: 0,
      userType: localStorage.getItem("user_type"),
      hasMoreAccount: true,
      loadingAccountCounter: 10,
      errorAccountNumber: "",
      errorAddUrl: "",
      errorEditUrl: "",
      searchAccountType: "",
      activeColorFinancial: "",
      activeColorLegal: "",
      activeColorSocial: "",
      activeColorInsurance: "",
      activeColorAll: "#15bbb3",
      activeSearh: "",
    };
  }

  componentDidMount() {
    let userId = 0;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }
    accountsService.getAccountData(userId, this);

    if (!this.state.searchAccountType) {
      const query = new URLSearchParams(this.props.location.search);
      const account_type = query.get("account-type");

      if (account_type) {
        this.setState({
          searchAccountType: account_type,
        });
      } else {
        document.getElementById("checkAllItem").checked = true;
      }
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  toggle() {
    this.setState((prevState) => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));
  }

  toggleViewAccount() {
    this.setState((prevState) => ({
      viewAccount: !prevState.viewAccount,
    }));
  }

  toggleEditAccount() {
    this.setState((prevState) => ({
      editAccount: !prevState.editAccount,
      uploadBtnSts: "false",
    }));
  }

  toggleProfilePhoto = () => {
    this.setState((prevState) => ({
      profilePhoto: !prevState.profilePhoto,
    }));
  };

  toggleDeleteAccount(deleteID) {
    console.log("delete on click id:  ", deleteID);
    this.setState((prevState) => ({
      deleteAccount: !prevState.deleteAccount,
      deleteID: deleteID,
    }));
  }

  /********************************** Validation Field of Edit form  Start ******************************* */

  validateAccName = () => {
    let accName = this.state.accName;
    let errors = [];
    let formIsValid = true;
    if (accName.length === 0) {
      formIsValid = false;
      errors["accName"] = "*Cannot Be Empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  validateAccNumber = () => {
    let accNumber = this.state.accNumber;
    let errors = [];
    let formIsValid = true;
    if (accNumber.length === 0) {
      formIsValid = false;
      errors["accNumber"] = "*Account number cannot be empty";
    }
    this.setState({ errors: errors, errorAccountNumber: errors["accNumber"] });
    return formIsValid;
  };

  validateDesc = () => {
    let desc = this.state.desc;
    let errors = [];
    let formIsValid = true;
    if (desc.length === 0) {
      formIsValid = false;
      errors["desc"] = "*Cannot Be Empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  validateAccType = () => {
    let accountType = this.state.accountType;
    let errors = [];
    let formIsValid = true;
    if (accountType.length === 0) {
      formIsValid = false;
      errors["accountType"] = "*Please choose a type";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  validateAccUrl = (e) => {
    let accUrl = this.state.accUrl;
    let errors = [];
    let formIsValid = true;
    if (accUrl.length === 0) {
      formIsValid = false;
      errors["accUrl"] = "*Cannot Be Empty";
    }
    this.setState({ errors: errors });
    console.log("error on valide url: ", accUrl);
    return formIsValid;
  };

  validateAddUrl = (e) => {
    let url = e.target.value;
    var re = /^(?:http[s]?:\/\/){0,1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/;
    if (!re.test(url)) {
      console.log("url error");
      return false;
    } else {
      console.log("url correct");
      return true;
    }
  };

  validateUsername = () => {
    let username = this.state.username;
    let errors = [];
    let formIsValid = true;
    if (username.length === 0) {
      formIsValid = false;
      errors["username"] = "*Cannot Be Empty";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  validatePassword = () => {
    let password = this.state.password;
    let errors = [];
    let formIsValid = true;
    if (password.length === 0) {
      formIsValid = false;
      errors["password"] = "*Cannot Be Emptyssss";
    }
    this.setState({ errors: errors });
    return formIsValid;
  };

  /********************************** Validation Field of Edit form  End ******************************* */

  /********************************** EyeIcon set for selected field Start ******************************* */

  eyeIcon = (eyetype) => {
    //console.log("Eye icon clicked:", eyetype);

    switch (eyetype) {
      case "editpassword":
        if (this.state.passValue === "password") {
          this.setState({ passValue: "text" });
        } else {
          this.setState({ passValue: "password" });
        }
        break;

      case "formPassword":
        if (this.state.formEyeValue === "password") {
          this.setState({ formEyeValue: "text" });
        } else {
          this.setState({ formEyeValue: "password" });
        }
        break;

      case "viewPass":
        if (this.state.viewPass === "password") {
          this.setState({ viewPass: "text" });
        } else {
          this.setState({ viewPass: "password" });
        }
        break;
      default:
    }
  };

  /************************************  EyeIcon set for selected field End *********************************/

  /********************************** Show all kind of List and Account Type Start ******************************* */

  cancelHandler = () => {
    this.setState({ cancelBtn: true });
    this.setState({ fmBtnStatus: false });
  };

  Btnhandler() {
    this.setState({
      fmBtnStatus: true,
    });
  }

  showAddNewBtn = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Button className="btn border-0 btn-warning btn-block" style={{ background: "#f6db20" }} onClick={this.Btnhandler}>
          <span className="text-dark">+ Add Account</span>
        </Button>
      );
    }
  };

  formDetails() {
    if (this.state.fmBtnStatus === true) {
      return (
        <div className="container-fluid">
          <Form
            method="post"
            onSubmit={this.handleAddform}
            className="frm-show"
          >
            <Row>
              <Col md={6}>
                <FormGroup className="required">
                  <Label className="control-label">Account Type</Label>
                  <Input
                    type="select"
                    name="accountType"
                    id="accountType"
                    required
                  >
                    <option value="">Please choose an item</option>
                    <option value="financial">Financial</option>
                    <option value="legal"> Legal</option>
                    <option value="social">Social</option>
                    <option value="insurance">Insurance</option>
                  </Input>
                </FormGroup>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={12}>
                    <FormGroup className="required">
                      <Label className="control-label">Account Name</Label>
                      <Input type="text" name="accName" id="accName" placeholder="Enter account name" required />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row form>
              <Col md={6}>
                <FormGroup className="required">
                  <Label className="control-label">Account Number</Label>
                  <Input
                    type="text"
                    name="accNumber"
                    id="accNumber"
                    placeholder="Account Number"
                    onChange={this.onChangeAccountNumber}
                    required
                  //pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                  />
                  {this.state.errorAccountNumber && (
                    <div className="text-danger">
                      {this.state.errorAccountNumber}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label className="control-label">Account URL</Label>
                  <Input
                    type="text"
                    name="accUrl"
                    id="accUrl"
                    placeholder="https://examples.com"
                    onChange={this.onChangeAddUrl}
                  //pattern="^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$"
                  />
                  {this.state.errorAddUrl && (
                    <div className="text-danger">
                      {this.state.errorAddUrl}
                    </div>
                  )}
                </FormGroup>
              </Col>

            </Row>

            <Row form>
              <Col md={6}>
                <FormGroup>
                  <Label className="control-label">Account Username</Label>
                  <Input
                    type="text"
                    name="username"
                    id="username"
                    placeholder="Enter account username"
                  />
                </FormGroup>
              </Col>

              <Col md={6}>
                <FormGroup>
                  <Label className="control-label">Account Password</Label>
                  <Input
                    type={this.state.formEyeValue}
                    name="password"
                    id="password"
                    placeholder="Enter account password"
                    style={{ paddingRight: "22px" }}
                  />
                  <span
                    className="field-icon"
                    onClick={this.eyeIcon.bind(this, "formPassword")}
                  >
                    {this.state.formEyeValue === "password" ? (
                      <FaEyeSlash />
                    ) : (
                      <FaEye />
                    )}
                  </span>
                </FormGroup>
              </Col>
            </Row>
            <Row form>
              <Col md={12}>
                <FormGroup className="required">
                  <Label className="control-label">Account Description</Label>
                  <Input
                    type="textarea"
                    name="text"
                    id="textdesc"
                    rows="2"
                    cols="30"
                    placeholder="Enter account description"
                    required
                  />
                </FormGroup>
              </Col>
            </Row>

            <Row className="mb-4">
              <Col md={12} className="btnformlist d-flex justify-content-end">
                <Button
                  className="acntbtn"
                  type="reset"
                  onClick={this.cancelHandler.bind(this, "cancel")}
                >
                  Cancel
                </Button>
                <Button className="savebtnacnt" style={{ bordeRadius: "5px" }} type="submit">
                  Save
                </Button>
              </Col>
            </Row>
          </Form>
        </div >
      );
    }
  }

  handleAddform = (e) => {
    e.preventDefault();
    const accName = document.getElementById("accName").value;
    const accountType = document.getElementById("accountType").value;
    const accUrl = document.getElementById("accUrl").value;
    const accNumber = document.getElementById("accNumber").value;
    const accDescription = document.getElementById("textdesc").value;
    const accUserName = document.getElementById("username").value;
    const accPassword = document.getElementById("password").value;

    let userId = this.state.userObj.data.user_id;

    if (accDescription.trim() === "") {
      ToastsStore.warning("Sorry, Description data is missing");
      return false;
    }
    if (this.state.errorAddUrl) {
      return false;
    }
    accountsService.storeAccountData(
      userId,
      accName,
      accountType,
      accNumber,
      accUrl,
      accDescription,
      accUserName,
      accPassword,
      this.state.accountList,
      this
    );

    this.setState({ fmBtnStatus: false });
    this.loadAccountContent();
  };

  showSearchSortOpt = () => {
    let srch_srt = (
      <div className="">
        <div className="row search-container">
          {/* need to verify with bn user of the below div */}

          {this.state && this.state.userType !== "bn" ? (
            <div className="col-sm-3 col-md-4 col-lg-3">
              {this.showAddNewBtn()}
            </div>
          ) : null}

          <div className="col-sm-6 col-md-4 col-lg-4">
            <div className="searchBar ml-2">
              <Form onSubmit={this.handleSearchAccount}>
                <FormGroup>
                  <span
                    className="search-icon"
                    onClick={this.handleSearchAccount}
                  >
                    <FaSearch />
                  </span>
                  <Input
                    className="control-box"
                    type="search"
                    name="search"
                    id="srchBox"
                    placeholder="Search accounts by name or description"
                    onChange={this.handleSearchAccount}
                  />
                </FormGroup>
              </Form>
            </div>
          </div>
          <div className="col-sm-3 col-md-4 col-lg-3 ">
            <div className="text-right">
              <span className="">Sort By</span>
              <select
                id="sortItem"
                name="sortItem"
                className="sortType"
                onChange={this.accountSortByFormat.bind(this)}
              >
                <option value="name">Name</option>
                <option value="accType">Account Type</option>
              </select>
            </div>
          </div>
        </div>

      </div>
    );
    // if (this.state.fmBtnStatus === false) {
    //   return srch_srt;
    // }
    return srch_srt;
  };

  activeLinkColorFinancial = (item, target) => {
    this.setState({
      activeColorFinancial: "",
      activeColorLegal: "",
      activeColorSocial: "",
      activeColorInsurance: "",
      activeColorAll: "",
    });
    this.setState({
      [item]: "#15bbb3",
    });
  };

  accTypeList = () => {
    let acctypeList = [];
    acctypeList = (
      <ul className="list-accnt-type">
        <FormGroup check>
          <Label
            check
            className="list-item-all "
            style={{ color: this.state.activeColorAll }}
            onClick={this.activeLinkColorFinancial.bind(this, "activeColorAll")}
          >
            <Input
              type="checkbox"
              id="checkAllItem"
              checked={this.state.selectedAccountType === "all"}
              onChange={this.accountTypeFilter.bind(this, "all")}
            />
            <span> All</span>
          </Label>
        </FormGroup>
        <li
          onClick={this.activeLinkColorFinancial.bind(
            this,
            "activeColorFinancial"
          )}
        >
          <a
            href="#financial"
            onClick={this.accountTypeFilter.bind(this, "financial")}
            alt="accounts"
            style={{ color: this.state.activeColorFinancial }}
          >
            <FaDollarSign />
            <span className="list-item">Financial</span>
          </a>
        </li>
        <li
          onClick={this.activeLinkColorFinancial.bind(this, "activeColorLegal")}
        >
          <a
            href="#legal"
            onClick={this.accountTypeFilter.bind(this, "legal")}
            alt="accounts"
            style={{ color: this.state.activeColorLegal }}
          >
            <FaBalanceScale />
            <span className="list-item">Legal</span>
          </a>
        </li>
        <li
          onClick={this.activeLinkColorFinancial.bind(
            this,
            "activeColorSocial"
          )}
        >
          <a
            href="#social"
            onClick={this.accountTypeFilter.bind(this, "social")}
            alt="accounts"
            style={{ color: this.state.activeColorSocial }}
          >
            <FaHeart />
            <span className="list-item">Social</span>
          </a>
        </li>
        <li
          onClick={this.activeLinkColorFinancial.bind(
            this,
            "activeColorInsurance"
          )}
        >
          <a
            href="#insurance"
            onClick={this.accountTypeFilter.bind(this, "insurance")}
            alt="accounts"
            style={{ color: this.state.activeColorInsurance }}
          >
            <img src={insurance} alt="insurance" />
            <span className="list-item">Insurance </span>
          </a>
        </li>
      </ul>
    );

    // if (this.state.fmBtnStatus === false) {
    //   return acctypeList;
    // }
    return acctypeList;
  };

  iconTypeAccount = (typeSelect) => {
    if (typeSelect === "financial") {
      return <FaDollarSign />;
    } else if (typeSelect === "legal") {
      return <FaBalanceScale />;
    } else if (typeSelect === "social") {
      return <FaHeart />;
    } else if (typeSelect === "insurance") {
      return <img src={insurance} alt="insurance" />;
    }
  };

  loadAccountContent = () => {
    setTimeout(() => {
      if (
        this.state.loadingAccountCounter < this.state.accountListFiltered.length
      ) {
        let accountCounter = this.state.loadingAccountCounter + 2;
        this.setState({ loadingAccountCounter: accountCounter });
      }
      if (
        this.state.loadingAccountCounter >=
        this.state.accountListFiltered.length
      ) {
        this.setState({ hasMoreAccount: false });
      }
    }, 1000);
  };

  onDemandAccountList = () => {
    if (this.state.fmBtnStatus === true) {
      return this.formDetails();
    } else {
      return (
        // if (this.state.userType === "bn") {
        <div className=" ">
          <div className="row mt-5 border-bottom">
            <div className="col-4 font-weight-bold pl-3"><strong>Accounts</strong></div>
            <div className="col-4 font-weight-bold"><strong>Description</strong></div>
            <div className="col-3"></div>
          </div>
          <InfiniteScroll
            dataLength={this.state.loadingAccountCounter}
            next={this.loadAccountContent.bind(this)}
            hasMore={this.state.hasMoreAccount}
            style={{ height: "400px", width: '100%', overflow: 'unset' }}
            className="demand-item ml-3"
          >
            {this.showAccountList()}
          </InfiniteScroll>
        </div>
      );
    }
  };

  showAccountList = () => {

    const user_id = this.state.userObj.data.user_id
    const user_type = this.state.userType

    let itemList = [];
    let tempAccountList = [];
    let accountCounter = 0;

    this.state.accountListFiltered.map((item) => {
      if (accountCounter <= this.state.loadingAccountCounter) {
        tempAccountList.push(item);
      }
      accountCounter = accountCounter + 1;
    });

    if (this.state.fmBtnStatus === false) {
      if (this.state.userType === "bn") {
        itemList = (
          <div className="listshow">
            <div className="row"> </div>
            <div className="row">
              {this.state.activeSearh === "" && tempAccountList.length < 1 ? (
                <div className="container">
                  <div className="row ">
                    <img
                      className="img-fluid mx-auto d-block"
                      src={zeroState}
                      alt="love-pat"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.activeSearh === "active" &&
                tempAccountList.length < 1 ? (
                <div className="container">
                  <div className="row ">
                    <h2 className="text-center"> No accounts found </h2>
                    <p className="text-center">
                      Sorry, we can't find the type of account you are looking
                      for. Try widening your search to see what’s available.
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}
              {tempAccountList.map((itemList, i) => (
                <React.Fragment key={i} className="container row d-flex flex-row">
                  <div className="col-4">
                    {this.iconTypeAccount(itemList.accountType)}{" "}
                    {itemList.accName}
                  </div>

                  <div className="col-5">
                    {itemList.desc}
                  </div>
                  <div className="col-2 viewBtn text-center mb-1" onClick={this.viewAccount.bind(this, itemList)}  >
                    View
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      } else {
        itemList = (
          <div className=" ">
            <div className="row"></div>
            <div className=" ">

              {this.state.activeSearh === "" && tempAccountList.length < 1 ? (
                <div className="container">
                  <div className="row ">
                    <img
                      className="img-fluid mx-auto d-block"
                      src={zeroState}
                      alt="love-pat"
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              {this.state.activeSearh === "active" &&
                tempAccountList.length < 1 ? (
                <div className="container">
                  <div className="row ">
                    <h2 className="text-center"> No accounts found </h2>
                    <p className="text-center">
                      Sorry, we can't find the type of account you are looking
                      for. Try widening your search to see what’s available.
                    </p>
                  </div>
                </div>
              ) : (
                ""
              )}

              {tempAccountList.map((itemList, i) => (
                <React.Fragment key={i}>
                  <div className="d-flex border-bottom account-list " >
                    <div className="col-4  mt-1"> {this.iconTypeAccount(itemList.accountType)}{" "} {itemList.accName} </div>
                    <div className="col-4 mt-1 pl-0 mid-div"> {itemList.desc} </div>
                    <div className=" col-3 mt-1 d-inline-flex d-xs-none last-div">
                      <div className="col-4 col-lg-4 viewBtn text-center" onClick={this.viewAccount.bind(this, itemList)}>
                        <img src={iconView} />
                      </div>


                      {
                        (this.state && itemList.user_id == user_id && user_type=="contributor"  ) && (<div className="col-4 col-lg-4 editBtn text-center" onClick={this.editAccount.bind(this, itemList, i)}>
                        <img src={iconEdit} />
                      </div>)
                      }

                      {
                         
                         (this.state &&   user_type=="primary"  ) && (<div className="col-4 col-lg-4 editBtn text-center" onClick={this.editAccount.bind(this, itemList, i)}>
                         <img src={iconEdit} />
                       </div>)
                       }

                      {
                        (this.state && itemList.user_id == user_id && user_type=="contributor"  ) && (<div className="col-4 col-lg-4 deleteBtn text-center" onClick={this.toggleDeleteAccount.bind(this, itemList.id)}>
                        <img src={iconDelete} />
                      </div>)
                      }

                      {
                         
                         (this.state &&   user_type=="primary"  ) && (<div className="col-4 col-lg-4 deleteBtn text-center" onClick={this.toggleDeleteAccount.bind(this, itemList.id)}>
                         <img src={iconDelete} />
                       </div>)
                       }
                      
                    </div>
                  </div>
                </React.Fragment>
              ))}
            </div>
          </div>
        );
      }
    }
    if (this.state.dataLoading) {
      itemList = <p className="empty-msg-acnt">Loading Accounts Data...</p>;
    }
    return itemList;
  };

  accountTypeFilter(selectedType) {
    this.setState({ selectedAccountType: selectedType });

    if (selectedType === "all") {
      this.setState({ accountListFiltered: this.state.accountList });
      this.setState({ accountListTempFiltered: this.state.accountList });
      document.getElementById("checkAllItem").checked = true;
    } else {
      let accountListTemp = this.state.accountList.filter(
        (item) => item.accountType === selectedType
      );
      this.setState({ accountListFiltered: accountListTemp });
      this.setState({ accountListTempFiltered: accountListTemp });
      document.getElementById("checkAllItem").checked = false;
    }
  }

  accountSortByFormat = () => {
    let sortValue = document.getElementById("sortItem").value;
    let listOfAccounts = this.state.accountListFiltered;

    if (sortValue === "name") {
      listOfAccounts.sort((a, b) => {
        return a.accName > b.accName ? 1 : a.accName === b.accName ? 1 : -1;
      });
      this.setState({ accountListFiltered: listOfAccounts });
      console.log("Name Type click:", listOfAccounts);
    } else if (sortValue === "accType") {
      listOfAccounts.sort((a, b) => {
        return a.accountType > b.accountType
          ? 1
          : a.accountType === b.accountType
            ? 1
            : -1;
      });
      this.setState({ accountListFiltered: listOfAccounts });
    }
  };

  handleSearchAccount = (e) => {
    e.preventDefault();
    let searchText = document.getElementById("srchBox").value;
    let listAccount = [];

    if (searchText !== "") {
      listAccount = this.state.accountListTempFiltered.filter(
        (itemType) =>
          itemType.accName.toLowerCase().includes(searchText.toLowerCase()) ||
          itemType.desc.toLowerCase().includes(searchText.toLowerCase())
      );
      this.setState({
        accountListFiltered: listAccount,
        activeSearh: "active",
      });
    } else {
      this.setState({
        accountListFiltered: this.state.accountListTempFiltered,
        activeSearh: "",
      });
    }
  };

  /********************************** Show all kind of List and Account Type end ******************************* */

  viewAccount(e) {
    let accountType = this.state.accountType;
    let accName = this.state.accName;
    let accNumber = this.state.accNumber;
    let accUrl = this.state.accUrl;
    let desc = this.state.desc;
    let username = this.state.username;
    let password = this.state.password;

    accountType = e.accountType;
    accName = e.accName;
    accNumber = e.accNumber;
    accUrl = e.accUrl;
    desc = e.desc;
    password = e.password;
    username = e.username;
    this.setState({ accountType });
    this.setState({ accName });
    this.setState({ accNumber });
    this.setState({ accUrl });
    this.setState({ desc });
    this.setState({ username });
    this.setState({ password });
    this.toggleViewAccount();
  }

  /********************************** Edit for Account List Item ******************************* */

  editAccount(e, i) {
    let accName = this.state.accName;
    let accNumber = this.state.accNumber;
    let desc = this.state.desc;
    let accountType = this.state.accountType;
    let accEditUrl = this.state.accEditUrl;
    let username = this.state.username;
    let password = this.state.password;

    accName = e.accName;
    accNumber = e.accNumber;
    desc = e.desc;
    accountType = e.accountType;
    accEditUrl = e.accUrl;
    username = e.username;
    password = e.password;

    this.setState({ accName });
    this.setState({ accNumber });
    this.setState({ desc });
    this.setState({ accountType });
    this.setState({ accEditUrl });
    this.setState({ username });
    this.setState({ password });

    this.setState({ editId: e.id });
    this.setState({ updateIndex: i });

    this.toggleEditAccount();
  }

  onChangeName = (e) => {
    this.setState(
      {
        accName: e.target.value,
        uploadBtnSts: "true",
      },
      () => {
        this.validateAccName();
      }
    );
  };

  onChangeDesc = (e) => {
    this.setState(
      {
        desc: e.target.value,
        uploadBtnSts: "true",
      },
      () => {
        this.validateDesc();
      }
    );
  };

  onChangeAccountType = (e) => {
    this.setState(
      {
        accountType: e.target.value,
        uploadBtnSts: "true",
      },
      () => {
        this.validateAccType();
      }
    );
  };

  onChangeUrl = (e) => {
    this.setState({
      accEditUrl: e.target.value,
    });

    if (!e.target.value) {
      this.setState({
        uploadBtnSts: "true",
        errorEditUrl: "",
      });
      return false;
    }
    if (this.validateAddUrl(e)) {
      this.setState({
        uploadBtnSts: "true",
        errorEditUrl: "",
      });
    } else {
      this.setState({
        errorEditUrl: "Please input valid url!",
      });
    }
  };

  onChangeAccountNumber = (e) => {
    this.setState(
      {
        accNumber: e.target.value,
        uploadBtnSts: "true",
      },
      () => {
        this.validateAccNumber();
      }
    );
  };

  onChangeAddUrl = (e) => {
    if (this.validateAddUrl(e)) {
      this.setState({
        accUrl: e.target.value,
        uploadBtnSts: "true",
        errorAddUrl: "",
      });
    } else {
      this.setState({
        errorAddUrl: "Please input valid url!",
      });
    }
    if (e.target.value === "") {
      this.setState({
        errorAddUrl: "",
      });
    }
  };

  onChangeUsername = (e) => {
    this.setState(
      {
        username: e.target.value,
        uploadBtnSts: "true",
      },
      () => {
        //this.validateUsername();
      }
    );
  };

  onChangePassword = (e) => {
    this.setState(
      {
        password: e.target.value,
        uploadBtnSts: "true",
      },
      () => {
        //this.validatePassword();
      }
    );
  };

  saveChangesList = (e) => {
    e.preventDefault();
    let selectedItemId = this.state.editId;
    let accountList = this.state.accountList;
    let item_index = 0;
    let temp_index = 0;

    accountList.map((item) => {
      if (item.id === selectedItemId) {
        item_index = temp_index;
      }
      temp_index = temp_index + 1;
    });

    let accName = document.getElementById("accName").value;
    let accNumber = document.getElementById("accNumber").value;
    let accDescription = document.getElementById("textdesc").value;
    let accountType = document.getElementById("accountType").value;
    let accUrl = document.getElementById("accEditUrl").value;
    let accUserName = document.getElementById("username").value;
    let accPassword = document.getElementById("password").value;

    if (accDescription.trim() === "") {
      ToastsStore.warning("Sorry, Description data is missing");
      return false;
    }

    if (this.state.errorEditUrl) {
      return false;
    }
    accountList[item_index] = {
      id: selectedItemId,
      accName: accName,
      accNumber: accNumber,
      desc: accDescription,
      accountType: accountType,
      accUrl: accUrl,
      username: accUserName,
      password: accPassword,
    };

    let userId = 0;
    if (this.state.userType === "bn") {
      userId = this.state.userObj.data.primary_user_id;
    } else {
      userId = this.state.userObj.data.user_id;
    }

    this.setState({ editAccount: false });
    this.setState({ uploadBtnSts: "true" });
    this.accountTypeFilter(this.state.selectedAccountType);

    accountsService.updateAccountData(
      userId,
      selectedItemId,
      accName,
      accNumber,
      accountType,
      accUrl,
      accDescription,
      accUserName,
      accPassword,
      this
    );

    //this.setState({ accountListFiltered: accountList });
  };

  /********************************** Edit for Account List Item ******************************* */

  deleteAccount() {
    const selItemID = this.state.deleteID;
    console.log("button clicked delete on item:>>>", selItemID);

    const accountListLocal = this.state.accountList.filter(
      (itemlist) => itemlist.id !== selItemID
    );

    accountsService.deleteAccountData(selItemID);

    this.setState({ accountList: accountListLocal });
    this.setState({ accountListFiltered: accountListLocal });
    this.setState({ deleteAccount: false });
  }

  disableBeneficiaryLink = () => {
    if (this.state.userType === "bn") {
    } else {
      return (
        <Link className="link-style-beneficiaries" to="/beneficiaries">
          Beneficiaries
        </Link>
      );
    }
  };

  render() {
    const itemList = this.state.itemList;

    return (

      <div className="home-container" id="main-wrapper" data-theme="light" data-layout="vertical" data-navbarbg="skin6" data-sidebartype="full" data-sidebar-position="fixed" data-header-position="fixed" data-boxed-layout="full">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Accounts</title>
        </Helmet>
        <Header {...this.props} />
        <LeftSidebar {...this.props} />
        <div className="page-wrapper" style={{ display: 'block' }}>

          <div className="container-fluid pl-2">
            <Row>
              <Col sm="8" className="p-4">
                {" "}
                <div className="enable-2fa-header">
                  <h1 className="account-header mr-3">ACCOUNTS</h1>
                  {/* <img className="d-flex align-items-center sm-hide" src={lineOfColours} alt="line of colours" /> */}
                </div>
                <p style={{ fontSize: "15px" }}>
                  Save your account information here. Accounts like utility bills,
                  mortgage accounts, life insurance, social media, and other
                  accounts that you will need to be managed later.
                </p>
              </Col>
            </Row>
          </div>

          <div className="container-fluid pl-2">

            {this.showSearchSortOpt()}
            <div className="row">
              <div className="col-sm-4 col-md-3 pl-0 web-hide ">{this.accTypeList()}</div>
            </div>
            <div className="row">
              <div className="col-sm-4 col-md-3 col-lg-2 pl-0 sm-hide tab-hide">{this.accTypeList()}</div>
              <div className="col-sm-12 col-md-12 col-lg-10">

                {this.onDemandAccountList()}

              </div>
            </div>

          </div>
        </div>
        {/* /****************************** View Button Start ****************************/}
        <div>
          <Modal
            isOpen={this.state.viewAccount}
            toggle={this.toggleViewAccount}
            className={this.props.className + ' modal-lg'}
          >
            <ModalHeader toggle={this.toggleViewAccount}>
              View Details of Account
            </ModalHeader>

            <ModalBody className="form-field-view">
              <Form id="view_form" method="post" className="form-field-detail">
                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label">Account Type</Label>
                      <Input value={this.state.accountType} readOnly />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label">Account Name</Label>
                      <Input value={this.state.accName} readOnly />
                    </FormGroup>
                  </Col>

                </Row>
                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label">Account Number</Label>
                      <Input value={this.state.accNumber} readOnly />
                    </FormGroup>
                  </Col>

                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label">Account URL</Label>
                      <Input value={this.state.accUrl} readOnly />
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  <Col sm={12}>
                    <FormGroup>
                      <Label className="view-label">Account Description</Label>

                      <Input
                        type="textarea"
                        rows="3"
                        cols="10"
                        value={this.state.desc}
                        readOnly
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label">Account Username</Label>
                      <Input value={this.state.username} readOnly />
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label">Account Password</Label>{" "}
                      <br />
                      <Input
                        value={this.state.password}
                        readOnly
                        type={this.state.viewPass}
                      />
                      <span
                        className="field-icon"
                        onClick={this.eyeIcon.bind(this, "viewPass")}
                      >
                        {this.state.viewPass === "password" ? (
                          <FaEyeSlash />
                        ) : (
                          <FaEye />
                        )}
                      </span>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  type="button"
                  onClick={this.toggleViewAccount}
                  style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                >
                  Close
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>

        {/* /****************************** View Button End ****************************/}

        {/* *************************** Edit item for Accounts List start  ****************************/}
        <div className="form-popup">
          <Modal
            isOpen={this.state.editAccount}
            toggle={this.toggleEditAccount}
            className={this.props.className + ' modal-lg'}
          >
            <ModalHeader toggle={this.toggleEditAccount}>
              Edit Account
            </ModalHeader>

            <ModalBody className="form-detail-edit">
              <Form
                id="edit_form"
                method="post"
                className="form-field-edit"
                onSubmit={this.saveChangesList}
              >
                <Row>
                  <Col sm={6}>
                    <FormGroup className="required">
                      <Label className="view-label control-label">
                        Account Type
                      </Label>
                      <Input
                        type="select"
                        name="accountType"
                        id="accountType"
                        required
                        value={this.state.accountType}
                        onChange={this.onChangeAccountType}
                      >
                        <option value="">Please choose an item</option>
                        <option value="financial">Financial</option>
                        <option value="legal">Legal</option>
                        <option value="social">Social</option>
                        <option value="insurance">Insurance</option>
                      </Input>
                      <div className="errormessage">
                        {this.state.errors.accountType}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col sm={6}>
                    <FormGroup className="required">
                      <Label className="view-label control-label">
                        Account Name
                      </Label>
                      <Input
                        type="text"
                        name="accName"
                        id="accName"
                        required
                        value={this.state.accName}
                        onChange={this.onChangeName}
                      />
                      <div className="errormessage">
                        {this.state.errors.accName}
                      </div>
                    </FormGroup>
                  </Col>

                </Row>
                <Row form>

                  <Col sm={6}>
                    <FormGroup className="required">
                      <Label className="view-label control-label">
                        Account Number
                      </Label>
                      <Input
                        type="text"
                        name="accNumber"
                        id="accNumber"
                        required
                        value={this.state.accNumber}
                        onChange={this.onChangeAccountNumber}
                      />
                      <div className="errormessage">
                        {this.state.errors.accNumber}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label control-label">
                        Account URL
                      </Label>
                      <Input
                        type="text"
                        name="accUrl"
                        id="accEditUrl"
                        placeholder="example.com"
                        value={this.state.accEditUrl}
                        onChange={this.onChangeUrl}
                      />
                      <div className="errormessage">
                        {this.state.errorEditUrl}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>


                <Row form>
                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label control-label">
                        Account Username
                      </Label>
                      <Input
                        type="text"
                        name="username"
                        id="username"
                        placeholder="Associated acc..."
                        value={this.state.username}
                        onChange={this.onChangeUsername}
                      />
                      <div className="errormessage">
                        {this.state.errors.username}
                      </div>
                    </FormGroup>
                  </Col>

                  <Col sm={6}>
                    <FormGroup>
                      <Label className="view-label control-label">
                        Account Password
                      </Label>
                      <Input
                        type={this.state.passValue}
                        name="password"
                        id="password"
                        placeholder="Associated pass..."
                        value={this.state.password}
                        onChange={this.onChangePassword}
                      />
                      <span
                        className="field-icon"
                        onClick={this.eyeIcon.bind(this, "editpassword")}
                      >
                        {this.state.passValue === "password" ? (
                          <FaEyeSlash />
                        ) : (
                          <FaEye />
                        )}
                      </span>
                      <div className="errormessage">
                        {this.state.errors.password}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  <Col sm={12}>
                    <FormGroup className="required">
                      <Label className="view-label control-label">
                        Account Description
                      </Label>
                      <Input
                        type="textarea"
                        name="text"
                        id="textdesc"
                        required
                        rows={5}
                        cols={10}
                        value={this.state.desc}
                        onChange={this.onChangeDesc}
                      />
                      <div className="errormessage">
                        {this.state.errors.desc}
                      </div>
                    </FormGroup>
                  </Col>
                </Row>
                <Button
                  style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
                  type="submit"
                  disabled={this.state.uploadBtnSts === "false"}
                >
                  Save Changes
                </Button>
              </Form>
            </ModalBody>
          </Modal>
        </div>

        {/**************************** Edit item for Accounts List End ****************************/}

        {/* /****************************** Delete Custom Pop Up Start ****************************/}
        <div>
          <Modal
            isOpen={this.state.deleteAccount}
            toggle={this.toggleDeleteAccount}
            className={this.props.className}
          >
            <ModalHeader toggle={this.toggleDeleteAccount}>
              Account Delete
            </ModalHeader>

            <ModalBody className="form-popup">
              <p>Are you sure, want to Delete this Account?</p>
            </ModalBody>
            <ModalFooter>
              <Button
                color="danger"
                type="button"
                onClick={this.deleteAccount.bind(this, itemList)}
                style={{ borderRadius: "7px" }}
              >
                Delete
              </Button>
              <Button
                type="button"
                onClick={this.toggleDeleteAccount}
                style={{ float: "right", backgroundColor: "#000", color: "#f6dc20", borderRadius: "7px" }}
              >
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
        {/* /****************************** Delete Custom Pop Up End ****************************/}
      </div>
    );
  }
}

export default Accounts;
