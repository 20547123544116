import { authHeader } from "../helpers/auth-header";
import axios from "axios";
import apiService from "./api.service";

const OTPAuthService = {
  generateOTP,
  resetGenerateOTP,
  isExistsOTP,
  verifyOTP,
  checkPasswordOTP,
  disableOTP
};

const apiUrl = apiService.apiUrl;

function isExistsOTP(self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  const data = {};
  return axios.post(`${apiUrl}/api/isExistsOTP`, data, header);
}

function generateOTP(otp_method, mobile, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  let data = {
    otp_method: otp_method,
    mobile: mobile,
    step: 1
  };
  return axios.post(`${apiUrl}/api/generateOTP`, data, header);
}

function resetGenerateOTP(otp_method, mobile, self) {
 
	let header = {
		headers: {
			'Content-Type': 'application/json',
			Authorization: authHeader()
		}
	};
	let data = {
		otp_method: otp_method,
		mobile: mobile,
        step: 1
	};
	return axios
		.post(`${apiUrl}/api/resetGenerateOTP`,data, header);
 
}

function checkPasswordOTP(pass_word, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  let data = {
    pass_word: pass_word
  };
  return axios.post(`${apiUrl}/api/checkPasswordOTP`, data, header);
}

function verifyOTP(auth_code, otp_method, self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  let data = {
    otp_code: auth_code,
    otp_method: otp_method
  };
  return axios.post(`${apiUrl}/api/verifyCode`, data, header);
}

function disableOTP(self) {
  let header = {
    headers: {
      "Content-Type": "application/json",
      Authorization: authHeader()
    }
  };
  let data = {};
  return axios.post(`${apiUrl}/api/disableOTP`, data, header);
}

export default OTPAuthService;
