import React, { Component } from "react";
import lineOfColours from "./images/line_of_colours.png";
import {
  Media,
  Col,
  Row,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import loaderImg from "./images/loader.gif";
import "./styles/googleauthQR.css";
import OTPAuthService from "./../services/otpauth.service";
import { encrypt_url } from "./../helpers/encrypt-url";
import { Helmet } from "react-helmet";
import Header from "./../components/Header";

class VerifyGoogleAuthQRcode extends Component {
  constructor(props) {
    super(props);
    let user = localStorage.getItem("user");

    this.state = {
      authenticationcode: "",
      validauthcode: "1234567890",
      submitted: false,
      loading: false,
      regFailed: false,
      errorMessage: "",
      statusCode: "",
      encryptedString: encrypt_url(),
      userObj: JSON.parse(user),
      userType: localStorage.getItem("user_type"),
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    const qr_data = this.props.location.state;
    console.log(qr_data);
  }

  componentDidMount() {
    localStorage.removeItem('twofa')
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    this.setState({ submitted: false });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({
      submitted: true,
      regFailed: false,
      statusCode: "",
      errorMessage: "",
    });

    const { authenticationcode } = this.state;

    if (authenticationcode) {
      this.setState({ loading: true });

      let otp_method = "googleauth";
      console.log("qr code: ", authenticationcode);
      OTPAuthService.verifyOTP(authenticationcode, otp_method, this).then(
        (response) => {
          console.log(response.data);
          let responseData = response.data;
          if (responseData.status === "success") {
            localStorage.setItem("twofa", true);
            if (this.state.userType === "bn") {
              this.props.history.push(
                `/beneficiarylanding/${this.state.encryptedString}`
              );
            } else {
              // this.props.history.push("/accounts");
              this.props.history.push(
                `/dashboard/${this.state.encryptedString}`
              );
            }
          } else {
            this.setState({
              errorStatus: responseData.status,
              errorMessage: responseData.message,
              loading: false,
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }

  authenticateValidation() {
    if (this.state.submitted && !this.state.authenticationcode) {
      return <div className="help-block">Authentication code is required</div>;
    } else if (this.state.submitted) {
      return <div className="help-block">{this.state.errorMessage}</div>;
    }
    return <div className="help-block" />;
  }

  render() {
    return (
      <div className="register-container">
        <Helmet>
          <meta charSet="utf-8" />
          <title>ThisHeart - Verify Google Authentication</title>
        </Helmet>
        <Header {...this.props} />
        <Row className="mt-5">
          <Col sm={{ offset: 2, size: 6 }} className="m-auto px-4">
            <div className="enable-2fa-header">
              <h3>Two Factor Authentication Verify</h3>
              {/* <img src={lineOfColours} alt="line of colours" /> */}
            </div>
            <p>Two-factor authentication (2FA): Secure Your ThisHeart Account Because
              stronger passwords alone aren't enough to protect your account from password
              breaches. Two-factor authentication protects against phishing, social engineering,
              and password brute force. attacks and secures your account from attackers exploiting
              weak or stolen credentials.</p>
            <h5 style={{color:"#bda61b"}}>
              Secret Key is Generated, Please Verify the Secret Code to Enable
              Two Factor
            </h5>
            <p className="fact-list-verify mb-0">
              <strong>
                1. Scan this barcode with your Google Authenticator Application
              </strong>
            </p>
            <div className="item-container">
              <Media
                src={this.props.location.state?this.props.location.state.qr_url:''}
                object
                alt="QRCodeScan"
                className="scan-image"
              />
            </div>
            <p className="fact-list-verify mt-0">
              <strong>
                2. Please check your email and verify the OTP. 
              </strong>
            </p>
            <Form className="register-form" onSubmit={this.handleSubmit}>
              <FormGroup>
                <Label>Authenticate Code</Label>
                <Input
                  type="text"
                  className="input-qr w-50 ml-0"
                  name="authenticationcode"
                  id="authenticationcode"
                  onChange={this.handleChange}
                />
              </FormGroup>
              {this.authenticateValidation()}

              <div>
                {this.state.loading && (
                  <img className="loader-img" alt="loaderImg" src={loaderImg} />
                )}
                <Button className="btn btn-secondary mt-2">Submit 2FA</Button>
              </div>
            </Form>
          </Col>
        </Row>
        {/* <Footer {...this.props} /> */}
      </div>
    );
  }
}

export default VerifyGoogleAuthQRcode;
