exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".UploadItem_wrapperItem__33pRS {\n  display: grid;\n  grid-template-columns: calc(100% - 50px) 50px;\n  padding: 16px;\n  box-shadow: 0px 1px 2px lightgrey;\n}\n\n.UploadItem_leftSide__2rlwG {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin-right: 8px;\n}\n\n.UploadItem_progressBar__13Opw {\n  width: 100%;\n  height: 15px;\n  background-color: lightgray;\n  margin-bottom: 8px;\n  border-radius: 20px;\n}\n\n.UploadItem_progressBar__13Opw > div {\n  height: 15px;\n  background-color: lightgreen;\n  border-radius: 20px;\n}\n\n.UploadItem_leftSide__2rlwG > label {\n  width: 100%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n  text-align: left;\n}\n\n.UploadItem_percentage__Konmj {\n  margin-left: 12px;\n}\n", ""]);

// exports
exports.locals = {
	"wrapperItem": "UploadItem_wrapperItem__33pRS",
	"leftSide": "UploadItem_leftSide__2rlwG",
	"progressBar": "UploadItem_progressBar__13Opw",
	"percentage": "UploadItem_percentage__Konmj"
};