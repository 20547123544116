import React from 'react';
import { Route, Redirect } from 'react-router-dom';


export const TrialRoute = ({ component: Component, ...rest }) => (
	<Route
		{...rest}
		render={(props) =>
			localStorage.getItem('user') && localStorage.getItem('trial_process') ? (
				<Component {...props} />
			) : (
				<Redirect to={{ pathname: '/login', state: { from: props.location } }} />
			)
		}
	/>
);

